import React, { useState, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import Colors from './Colors';
import TypographyNormal from './TypographyNormal';
import ButtonBlue from './ButtonBlue';
import axios from "axios";
import { API_URL } from '../Components/Constants';
import TypographySubHeading from './TypographySubHeading';
import TypographyError from '../Components/TypographyError';



function OTPInput (props)  {

  const {phoneNumber, countryCode, setIsOtpSuccess} = props

  const [otp, setOtp] = useState(['', '', '', '']);
  const [otpError, setOtpError] = useState("");
  const [firstClickTime, setFirstClickTime] = useState(null);
  const [clickCount, setClickCount] = useState(0);

  const handleChange = (event, index) => {
    const value = event.target.value;
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < 3) {
        const nextSibling = document.getElementById(`otp-input-${index + 1}`);
        if (nextSibling) {
          nextSibling.focus();
        }
      }
    }
  };


  const handleKeyDown = (event, index) => {
    const { key } = event;

    if (key === 'Backspace') {
      if (otp[index] === '') {
        const prevSibling = document.getElementById(`otp-input-${index - 1}`);
        if (prevSibling) {
          prevSibling.focus();
        }
      } else {
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
      }
    }

    if (key === 'ArrowLeft' && index > 0) {
      const prevSibling = document.getElementById(`otp-input-${index - 1}`);
      if (prevSibling) {
        prevSibling.focus();
      }
    }

    if (key === 'ArrowRight' && index < 3) {
      const nextSibling = document.getElementById(`otp-input-${index + 1}`);
      if (nextSibling) {
        nextSibling.focus();
      }
    }
  };

  const handlePaste = (event) => {
    const pastedData = event.clipboardData.getData('Text').trim();
    if (/^\d{4}$/.test(pastedData)) { 
      const newOtp = pastedData.split('');
      setOtp(newOtp);
      const lastInputField = document.getElementById(`otp-input-3`);
      if (lastInputField) {
        lastInputField.focus();
      }
    }
  };

 

  const handleOTPVerifyButtonClick = async () => {
    if (otp.some((digit) => digit.length === 0)) {
        setOtpError('All OTP fields must be filled.');
    } else {
        setOtpError('');
        try {
            const response = await axios.post(`${API_URL}/otp_verification`, {
                countryCode: countryCode,
                phone: phoneNumber,
                otp: otp,
            });

            console.log("Verify response", response);

            if (response.data.success === true) {
                console.log("OTP verification success"); 
                setIsOtpSuccess(true)                 
               
            } else {
                setOtpError(response.data.message);
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setOtpError('Invalid verification code. Please try again.');
            } else {
                setOtpError('An unexpected error occurred. Please try again later.');
            }
            console.error('Error:', error.message);
        }
    }
};



const handleReSendOtpClick = async () => {
   
    setOtp(['', '', '', ''])
    setOtpError("")
    const now = new Date();

    console.log(firstClickTime)
    if (firstClickTime === null) {
      setFirstClickTime(now);
    }       
    console.log(firstClickTime)
    const diffMinutes = (now - firstClickTime) / (1000 * 60);
    console.log("First Click Time:", firstClickTime);
    console.log("Now:", now);
    console.log("Difference in minutes:", diffMinutes);
   
    if (diffMinutes < 1) {
        console.log("diffMinutes")
      setClickCount(prevCount => prevCount + 1);
    } else {  
        console.log("diffMinutes1")           
      setClickCount(1);
      setFirstClickTime(now);
    }
   console.log(clickCount)
    if (clickCount === 2) {
        setOtpError('Warning: You have only two attempts left. Exceeding this limit may block OTP requests for 24 hours.');      
        return; 
    }
    else if (clickCount === 4) {
        setOtpError('You have clicked too many times. Please try again later.');      
        return; 
    }
    else {
        try {
            const response = await axios.post(`${API_URL}/otp_creation`,
                {
                    countryCode: countryCode,
                    phone: phoneNumber,
                });

            console.log("response", response);

            if (response.data.success === true) {
              console.log("OTP verification success");
             // setIsOtpSuccess(true)     
            }
            else {

              setOtpError(response.data.message);
                // setEmailGoogleSignUpError("The email address you provided is invalid. Please check and try again.")
                console.log("an error occured");

            }
        }
        catch (error) {
            // setConfirmQuoteSend(true)
            console.error("Request failed:", error);
            if (error.response) {
                console.error("Server responded with status code:", error.response.status);
                console.error("Response data:", error.response.data);
            } else if (error.request) {
                console.error("No response received:", error.request);
            } else {
                console.error("Error setting up request:", error.message);
            }
        }
    }

}

useEffect(() => {
  const allEmpty = otp.every((digit) => digit === '');
  if (allEmpty) {
      const firstInput = document.getElementById('otp-input-0');
      if (firstInput) {
          firstInput.focus();
      }
  }
}, [otp]);


  return (
    <Box 
      sx={{ display: 'flex',flexDirection:'column' ,gap: {xs:  '8px', md: '16px' }, justifyContent:'center', alignItems:'center' }} >
        <TypographySubHeading sx={{fontSize:{xs:  '14px', md: '18px' }, fontWeight:600}}>OTP Verification</TypographySubHeading>
        <TypographyNormal sx={{color: '#626262', fontSize:{xs:  '12px', md: '16px' },}}> We have sent a one-time password to your mobile number.</TypographyNormal>
        <TypographyNormal sx={{color: Colors.black_90, fontSize:{xs:  '12px', md: '16px' },}}> Enter your OTP here</TypographyNormal>
    <Box 
      sx={{ display: 'flex', gap: '8px', justifyContent:'center', alignItems:'center' }} 
      onPaste={handlePaste}  
    >
      {otp.map((value, index) => (
        <TextField
          key={index}
          id={`otp-input-${index}`}
         autoComplete="one-time-code"
          value={value}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          inputProps={{
            maxLength: 1,
            style: { textAlign: 'center' }
          }}
          sx={{  width: '48px', height:'56px' ,borderRadius:'4px', background: Colors.grey_30 ,
           '& .MuiOutlinedInput-root': {
            border: '1px solid', borderColor: Colors.grey_30,
            '& fieldset': {
                border: '1px solid', borderColor: Colors.grey_30
            },
            '& .MuiInputBase-input::placeholder': {
                fontFamily: 'Roboto', fontSize:'14px', fontWeight: 400, color: Colors.black_70,                                   
            },
           '&.Mui-focused fieldset': {
            border: '1px solid', borderColor: Colors.black_80
          },

        } , 
          
           }} 
        />
      ))}
    </Box>
    <TypographyNormal >
    Don't receive the OTP?{' '}
    <TypographyNormal onClick={handleReSendOtpClick}
      component="span"
      sx={{ color: Colors.blue_6, display: 'inline', marginLeft: '8px', cursor:'pointer' }}
    >
      Resend OTP
    </TypographyNormal>
  </TypographyNormal>

  {otpError && <TypographyError>{otpError}</TypographyError>}
  <ButtonBlue sx={{ width: '100%',gap:'4px',marginTop: {xs:  '8px', md: '16px' },  }} onClick={handleOTPVerifyButtonClick}>Verify </ButtonBlue>
  </Box>
  );
};

export default OTPInput;
