import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button } from "@mui/material";
import Header from '../Components/Header';
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading  from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Footer from "../Components/Footer";
import { useParams } from 'react-router-dom';
import axios from "axios";
import { API_URL } from '../Components/Constants';
import ProfileComponent from '../Components/ProfileComponent';

function SpProfile(){

    const { spCode } = useParams();
    const [selectedSpId, setSelectedSpId] = useState(null);

    useEffect(() => {           
        const fetchData = async () => {            
            try { 
                const response = await axios.post(`${API_URL}/select_sp_details_with_spcode`, {                    
                    spCode: spCode
                }); 
                console.log(response.data.result.service_provider_id)
                setSelectedSpId(response.data.result.service_provider_id)
                localStorage.removeItem("serviceId")
                localStorage.setItem("SelectedSP", response.data.result.service_provider_id)
            } catch (error) {
                console.error('Error fetching data:', error);
                
            }
        };
    
        fetchData();
    }, [spCode]);
    
    return(
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>

        <Header></Header>

        <Box sx={{
            width: '100%', height: { xs: '95%', md: '97.5%' },justifyContent: 'center', alignItems: 'center', display:  'flex', 
            flexDirection: 'column',gap:'24px', paddingTop:{ xs: '5%', md: '2.5%' }}}>
              <Box sx={{
            width: '100%', height: { xs: '100%', md: '100%' },justifyContent: 'center', alignItems: 'center', display:  'flex', 
            flexDirection: 'column',gap:'24px',  maxWidth:{ xs: '90%', md: '70%' }}}>
             
             {selectedSpId && <ProfileComponent selectedSpId={selectedSpId}></ProfileComponent>}  
       
        </Box>
        </Box>



        <Footer ></Footer>
        </Grid>
    )
}
export default SpProfile;