
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button, IconButton , InputAdornment, Breadcrumbs} from "@mui/material";
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading  from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Colors from '../Components/Colors';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import popupbg from '../Images/popup.png'
import whitelogo from '../Images/whitelogo.png'
import TextfieldNormal from '../Components/TextfieldNormal';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonBlue from '../Components/ButtonBlue';
import TypographyError from '../Components/TypographyError';
import UserTermPopUp from '../Pages/UserTermPopUp';
import axios from "axios";
import { API_URL } from '../Components/Constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import ButtonWhite from '../Components/ButtonWhite';
import uploadCamera from '../Images/uploadCamera.png'
import { IMG_URL } from '../Components/Constants';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';




const colors = ['#503259', '#4C50AE', '#FF7D6A', '#B07CC6', '#20C925'];

function stringToColor(string) {
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  const colorIndex = Math.abs(hash) % colors.length;
  return colors[colorIndex];
}

function stringAvatar(name = '') {   
    const initial = name.charAt(0).toUpperCase();
    
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: initial || '', 
    };
  }
  


function SpSidebar(props){

    const {currentPage, isEdit} = props    
return(
    
<Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>   

       <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', 
       justifyContent: 'center', height: '100%', alignItems:'center',  }}>

            <Box sx={{display: 'flex', flexDirection: 'column', width: '100%',paddingBottom:'2%', 
            justifyContent: 'flex-start', height: '96%', paddingTop:'0%', alignItems:'center', gap:{ xs: '16px', md: '24px' }}}>

                    <Box sx={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'left', 
                          width:'100%',gap:'12px', }}>
                         <Breadcrumbs 
                            separator={<span style={{ paddingLeft: '8px', fontSize: '32px', justifyContent:'center', alignItems: 'center', color: Colors.default }}>›</span>} 
                            aria-label="breadcrumb" >
                            <TypographySubHeading sx={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: { xs: '16px', md: '20px' }, justifyContent: 'center' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', 
                                    height: '100%' }}  onClick={() => window.location.href = '/sp-landing'} >
                                <ArrowBackIcon  sx={{ color: Colors.black_90, fontSize: '18px', '&:hover': { fontSize: '22px' } }} />
                                </Box>
                                Complete your profile
                            </TypographySubHeading> 
                            </Breadcrumbs>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection:{ md: 'column ', xs: 'row' } , width: '100%', 
                justifyContent: 'center', height: '100%', alignItems:'center',gap:{ md: '24px', xs: '12px' }}}>
                    <Box onClick={isEdit ? () => window.location.href = `/service-provider-service?edit=${isEdit}` : null} 
                     sx={{display:'flex',flexDirection:'row', justifyContent:'center',alignItems:'center', padding:{ md: '8px 16px', xs: '6px 8px' }, alignContent:'center', 
                          width:{ md: 'calc(100% - 32px)', xs: 'calc(100% - 16px)' },height:'100%',gap:{ md: '12px', xs: '4px' }, border:'1px solid',background: currentPage === 'Services'? '#ECF4FB' : Colors.white_80,
                          borderColor: currentPage === 'Services'? Colors.primary_blue : Colors.grey_30, borderRadius:'4px', cursor: isEdit ? 'pointer': null}}>
                            <BusinessCenterOutlinedIcon sx={{fontSize: { md: '18px', xs: '14px' }, color: currentPage === 'Services'? Colors.primary_blue : Colors.black_80 }}></BusinessCenterOutlinedIcon>
                            <TypographyNormal sx={{fontSize: { md: '18px', xs: '14px' }, textAlign: { md: 'left', xs: 'center' }, width:{ md: '100%', xs: 'fit-content' }, 
                            color: currentPage === 'Services'? Colors.primary_blue : Colors.black_80 }}>
                            Services</TypographyNormal>
                    </Box>
                    <Box onClick={isEdit ? () => window.location.href = `/service-provider-location?edit=${isEdit}` : null} 
                    sx={{display:'flex',flexDirection:'row', justifyContent:'center',alignItems:{ md: 'left', xs: 'center' }, padding:{ md: '8px 16px', xs: '6px 8px' },    
                          width:{ md: 'calc(100% - 32px)', xs: 'calc(100% - 16px)' },height:'100%',gap:{ md: '12px', xs: '4px' }, border:'1px solid',background: currentPage === 'Location'? '#ECF4FB' : Colors.white_80,  
                          borderColor: currentPage === 'Location'? Colors.primary_blue : Colors.grey_30, borderRadius:'4px',cursor: isEdit ? 'pointer': null}}>
                            <RoomOutlinedIcon sx={{fontSize: { md: '18px', xs: '14px' }, color: currentPage === 'Location'? Colors.primary_blue : Colors.black_80 }}></RoomOutlinedIcon>
                            <TypographyNormal sx={{fontSize: { md: '18px', xs: '14px' },textAlign: { md: 'left', xs: 'center' }, width:{ md: '100%', xs: 'fit-content' }, 
                            color: currentPage === 'Location'? Colors.primary_blue : Colors.black_80  }}>Location</TypographyNormal>
                    </Box>
                    <Box onClick={isEdit ? () => window.location.href = `/service-provider-profile?edit=${isEdit}` : null} 
                     sx={{display:'flex',flexDirection:'row', justifyContent:'center',alignItems:{ md: 'left', xs: 'center' }, padding:{ md: '8px 16px', xs: '6px 8px' },   
                          width:{ md: 'calc(100% - 32px)', xs: 'calc(100% - 16px)' },height:'100%',gap:{ md: '12px', xs: '4px' }, border:'1px solid',background: currentPage === 'Profile'? '#ECF4FB' : Colors.white_80, 
                          borderColor: currentPage === 'Profile'? Colors.primary_blue : Colors.grey_30, borderRadius:'4px',cursor: isEdit ? 'pointer': null}}>
                           <AccountCircleOutlinedIcon sx={{fontSize: { md: '18px', xs: '14px' }, color: currentPage === 'Profile'? Colors.primary_blue : Colors.black_80 }}></AccountCircleOutlinedIcon>
                            <TypographyNormal sx={{fontSize: { md: '18px', xs: '14px' },textAlign: { md: 'left', xs: 'center' }, width:{ md: '100%', xs: 'fit-content' }, 
                             color: currentPage === 'Profile'? Colors.primary_blue : Colors.black_80  }}>Profile</TypographyNormal>
                    </Box>
                    
                    </Box>               
                  

            </Box>
      </Box>
                    
          
          
</Grid>
)
}
export default SpSidebar;