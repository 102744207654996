import { Box, Grid, Typography, Avatar, Rating, TextField, IconButton, Tab, Tabs, InputBase, Breadcrumbs, InputAdornment, Link } from "@mui/material"
import React, { useEffect, useState, useCallback, useRef } from 'react';
import Header from "../Components/Header";
import Colors from "../Components/Colors";
import TypographyNormal from "../Components/TypographyNormal";
import TypographyHeading from "../Components/TypographyHeading";
import TypographySubHeading from "../Components/TypographySubHeading";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TypographyError from "../Components/TypographyError";
import Footer from "../Components/Footer";
import ButtonBlue from "../Components/ButtonBlue";
import axios from "axios";
import { API_URL } from '../Components/Constants';
import TextfieldNormal from "../Components/TextfieldNormal";

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IMG_URL } from "../Components/Constants";
import ButtonWhite from "../Components/ButtonWhite";
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { FRONTEND_URL } from '../Components/Constants';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SpMessage from "../Components/SpMessages";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import logo from '../Images/logo.png'
import { useLocation } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';
import NoMsg from '../Images/NoMsg.png'



function SpViewMessage(){ 

  const location = useLocation();
  const messages = location.state?.messages || [];

  const [isMsg, setIsMsg] = useState(false);   
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [search, setSearch] = useState('');
  const [messageDetails, setMessageDetails] = useState([]);
  const [allMessageDetails, setAllMessageDetails] = useState([]);
  const [isReadDelete, setIsReadDelete] = useState(false); 
  const [selectedMessageDetails, setSelectedMessageDetails] = useState([]);
  const [isDeletePopUp, setIsDeletePopUp] = useState(false); 
  const [reply, setReply] = useState('');
  const [messageError, setMessageError] = useState(false);
  const [isMsgClick, setIsMsgClick] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();


  const handleBreadcrumbClick = () => {
    navigate(-1); 
    localStorage.removeItem("isMsgClick")
  };

  const fetchSpData = async () => {  
            
    try { 
        const response = await axios.post(`${API_URL}/select_sp_details_with_tocken`, {                    
            spToken: localStorage.getItem("spToken")
        }); 
         console.log(response.data.result)
        const messages = response.data.result.message_details ? response.data.result.message_details : []; 
        setAllMessageDetails(messages)       
        const sentMessages = messages.filter(message => message.sent_recieve === 'sent');   
        setMessageDetails(sentMessages)
    } catch (error) {
        console.error('Error fetching data:', error);        
    }
    
};

const fetchSpMsgData = async () => {  
  setLoading(true);          
  try { 
      const response = await axios.post(`${API_URL}/select_sp_details_with_tocken`, {                    
          spToken: localStorage.getItem("spToken")
      }); 
       console.log(response.data.result)
      const messages = response.data.result.message_details ? response.data.result.message_details : []; 
      setAllMessageDetails(messages)       
      const sentMessages = messages.filter(message => message.sent_recieve === 'sent');   
      setMessageDetails(sentMessages)
  } catch (error) {
      console.error('Error fetching data:', error);        
  }
  finally {
    setLoading(false); 
}
};

const fetchSpMessageData = async () => {            
  try { 
      const response = await axios.post(`${API_URL}/select_sp_details_with_tocken`, {                    
          spToken: localStorage.getItem("spToken")
      }); 
      
      const messages = response.data.result.message_details ? response.data.result.message_details : []; 
      const sentMessages = messages.filter(message => message.sent_recieve === 'sent'); 
     
      setMessageDetails(sentMessages)
  } catch (error) {
      console.error('Error fetching data:', error);
      
  }
};

useEffect(() => {        
  fetchSpMessageData();
  const intervalId = setInterval(fetchSpMessageData, 5000); 
  return () => clearInterval(intervalId);
}, []);

useEffect(() => {
  const spToken = localStorage.getItem("spToken");
  
  const fetchInterval = setInterval(() => {
      if (spToken) {     
          fetchSpData();       
      }
  }, 500);
  return () => clearInterval(fetchInterval);
}, []);

useEffect(() => {
  const spToken = localStorage.getItem("spToken"); 
 
      if (spToken) {     
          fetchSpMsgData();       
      }
 
}, []);


   const handleIconClick = (index, id) => {
   // console.log(id)
    setIsReadDelete(!isReadDelete)
    setSelectedMessageIndex(index )
    setSelectedMessageId(id)
   };


   const handleMenuItemClick = async(value) => {  

    if (value === "read") {
      try {      
        const response = await axios.post(`${API_URL}/update_read_status`, {           
           spToken: localStorage.getItem("spToken"),
           msgId: selectedMessageId, 
             });
           // console.log ("response",response); 
            if(response.data.success === true)  {
              const updatedMessageDetails = messageDetails.map((message, index) => {
                if (index === selectedMessageIndex) {
                  return { ...message, read: 'read' };
                }
                return message;
              });
              // Update messageDetails state
              setMessageDetails(updatedMessageDetails);
            }
            } catch (error) {
            console.error('Error:', error.message);
            }       
        
     
    }
    if (value === "delete") {
      setIsDeletePopUp(true)
    }
    setIsReadDelete(false);
  }

  
  const handleDeletePopUpCancelButtonClick = () => {
    setIsDeletePopUp(false)
   };

   const handleDeletePopUpOkButtonClick = async() => {
    try {      
      const response = await axios.post(`${API_URL}/delete_message`, {           
        spToken: localStorage.getItem("spToken"),
         msgId: selectedMessageId, 
           });         
          if(response.data.success === true)  {                 
            const updatedMessageDetails = messageDetails.filter((message, index) => index !== selectedMessageIndex); 
            if(Array.isArray(updatedMessageDetails) && updatedMessageDetails.length > 0) {   
            setMessageDetails(updatedMessageDetails);
            setIsDeletePopUp(false)
          }
          else{
            setIsDeletePopUp(false)
            setIsMsg(false)
          }
        }
          } catch (error) {
          console.error('Error:', error.message);
          }       
   };
   
   const handleAllRead = async() => {
    try {      
      const response = await axios.post(`${API_URL}/mark_all_as_read`, {           
        spToken: localStorage.getItem("spToken"),        
           });
         // console.log ("response",response); 
          if(response.data.success === true)  {
            const updatedMessageDetails = messageDetails.map((message, index) => {
              if (index === selectedMessageIndex) {
                return { ...message, read: 'read' };
              }
              return message;
            });
            // Update messageDetails state
            setMessageDetails(updatedMessageDetails);
          }
          } catch (error) {
          console.error('Error:', error.message);
          }     
   };


   const dropdownRef = useRef(null);
   const iconRef = useRef(null);

   const handleClickOutside = (event) => {
       if (
           dropdownRef.current &&
           !dropdownRef.current.contains(event.target) &&
           iconRef.current &&
           !iconRef.current.contains(event.target)
       ) {
           setIsReadDelete(false);
       }
   };

   useEffect(() => {
       if (isReadDelete) {
           document.addEventListener('mousedown', handleClickOutside);
       } else {
           document.removeEventListener('mousedown', handleClickOutside);
       }

       return () => {
           document.removeEventListener('mousedown', handleClickOutside);
       };
   }, [isReadDelete]);



const formatDateTime = (dateTimeString) => {
  const dateUTC = new Date(dateTimeString);

  // Check if dateUTC is valid
  if (isNaN(dateUTC.getTime())) {
    return 'Invalid Date'; // Return a default message for invalid dates
  }

  const timeOptions = {
    timeZone: 'America/Toronto',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const timeFormatter = new Intl.DateTimeFormat('en-CA', timeOptions);
  const formattedTime = timeFormatter.format(dateUTC);

  const dateInToronto = new Date(dateUTC.toLocaleString('en-US', { timeZone: 'America/Toronto' }));
  const day = String(dateInToronto.getDate()).padStart(2, '0');
  const month = String(dateInToronto.getMonth() + 1).padStart(2, '0');
  const year = dateInToronto.getFullYear();

  return `${day}-${month}-${year} ${formattedTime}`;
};

const formatTime = (dateTimeString) => {
  const dateUTC = new Date(dateTimeString);

  // Check if dateUTC is valid
  if (isNaN(dateUTC.getTime())) {
    return 'Invalid Date'; // Return a default message for invalid dates
  }

  const timeOptions = {
    timeZone: 'America/Toronto',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const timeFormatter = new Intl.DateTimeFormat('en-CA', timeOptions);
  const formattedTime = timeFormatter.format(dateUTC).replace('.', '').toUpperCase();

  const dateInToronto = new Date(dateUTC.toLocaleString('en-US', { timeZone: 'America/Toronto' }));
  const day = String(dateInToronto.getDate()).padStart(2, '0');
  const month = String(dateInToronto.getMonth() + 1).padStart(2, '0');
  const year = dateInToronto.getFullYear();

  return `${formattedTime}`;
};

const formatDate = (dateTimeString) => {
  const dateUTC = new Date(dateTimeString);

  // Check if dateUTC is valid
  if (isNaN(dateUTC.getTime())) {
    return 'Invalid Date'; // Return a default message for invalid dates
  }

  const timeOptions = {
    timeZone: 'America/Toronto',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const timeFormatter = new Intl.DateTimeFormat('en-CA', timeOptions);
  const formattedTime = timeFormatter.format(dateUTC);

  const dateInToronto = new Date(dateUTC.toLocaleString('en-US', { timeZone: 'America/Toronto' }));
  const day = String(dateInToronto.getDate()).padStart(2, '0');
  const monthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September",
     "October", "November", "December"];
  const month = monthNames[dateInToronto.getMonth()];
  const year = dateInToronto.getFullYear();

  return `${month} ${day},${year}`;
};

const handleSearchChange = (event) => {
  setSearch(event.target.value);        
};

// Filter messages based on search query
const filterMessages = () => {
  const lowercasedSearch = search.toLowerCase();

  // If search is empty, return all messages
  if (!lowercasedSearch) {
    return messageDetails;
  }

  // Filter messages based on the search value in specific fields
  return messageDetails.filter(message => {
    const { type_of_service, location, country_code, user_phone_number } = message;

    return (
      (type_of_service && type_of_service.toLowerCase().includes(lowercasedSearch)) ||
      (location && location.toLowerCase().includes(lowercasedSearch)) ||
      (country_code && country_code.toLowerCase().includes(lowercasedSearch)) ||
      (user_phone_number && user_phone_number.toLowerCase().includes(lowercasedSearch))
    );
  });
};

const handleMessageChange = (event) => {
    setReply(event.target.value);
    setMessageError(true)
  };


useEffect(() => {
  const fetchMessages = async () => {
    const msgIndex = localStorage.getItem("msgIndex");  
    if (msgIndex && messageDetails.length > 0) {
      const index = parseInt(msgIndex, 10);
      console.log(index);
      console.log(messageDetails);

      // Using the state value for msgPhone
      const selectedPhNumber = localStorage.getItem("msgPhone");;
      console.log(selectedPhNumber);
      setSelectedPhoneNumber(selectedPhNumber);

      // Filter messages from the same phone number
      const messagesFromSameNumber = allMessageDetails.filter(
        (message) => message.user_phone_number === selectedPhNumber
      );

      console.log(messagesFromSameNumber);
      setSelectedMessageDetails(messagesFromSameNumber);

     
    }
  };

  fetchMessages(); // Call the inner async function

}, [ localStorage.getItem("msgIndex") , messageDetails]);

useEffect(() => {
    const isMsg = localStorage.getItem("isMsgClick")
    console.log(isMsg)
    if(isMsg === 'false'){     
      setIsMsgClick(true)
    }
    else{
      setIsMsgClick(false)
    }
}, [localStorage.getItem("isMsgClick"), messageDetails]);



const filteredMessages = filterMessages();
const msgIndex = localStorage.getItem("msgIndex")

const handleSendReply = async() => {       
    try { 
        const response = await axios.post(`${API_URL}/reply_to_user`, { 
            spToken: localStorage.getItem("spToken"),                 
            message: reply,
            phoneNumber: selectedMessageDetails[0].user_phone_number,
            countryCode: selectedMessageDetails[0].country_code
        }); 
         console.log(response.data)
         if (response.data.success === true) {
          setReply("")
      }
        
        
    } catch (error) {
        console.error('Error fetching data:', error);
        
    }
  
};


    return(
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>
            <Header currentPage={"Home"}></Header>
       
       
        <Box sx={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display:{md:'flex', xs: 'none'}  ,
            flexDirection: 'row',gap:'16px', paddingTop:'2%',paddingBottom:'2%', minHeight:'450px'}}>
              {loading ? (
                            <Box sx={{ width: '70%', textAlign: 'center', mt: 2 }}>                            
                             <TypographySubHeading sx={{ mt: 2 }}>
                             Loading...                              
                            </TypographySubHeading>
                            </Box>
                        ) : messageDetails.length > 0 ? (
                 <Box sx={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'stretch', display: 'flex' ,
                      flexDirection: 'row',gap:'16px',maxWidth:{ xs: '90% ', md: '94%' } }}>
                            <Box sx={{width:{ xs: '100% ', md: '30%' },justifyContent: 'flex-start', alignItems: 'center', display: 'flex' ,
                                flexDirection: 'column',border:{ xs: '0px solid #E6E6E6', md: '1px solid  #E6E6E6' }, 
                                borderColor: Colors.grey_30, paddingTop:'2%', paddingBottom:'2%',borderRadius:'4px',maxHeight:'500px',
                                 overflowY:'auto', '&::-webkit-scrollbar': {
                                    width: '12px', height: '8px', borderRadius: '16px',
                                  },
                                  '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: Colors.black_60, borderRadius: '16px', border: '2px solid white',
                                  },
                                  '&::-webkit-scrollbar-track': {
                                    borderRadius: '16px', backgroundColor: 'none', 
                                  }, }}>
                                  <Box sx={{width: '100%',justifyContent: 'center', alignItems: 'center', display: 'flex' ,
                                   flexDirection: 'column',maxWidth:'90%',gap:'12px', }}>
                                    
                                    <SpMessage messageDetails={messageDetails} setMessageDetails={setMessageDetails}
                                    setSelectedMessageDetails={setSelectedMessageDetails} isView = {true}></SpMessage>
                                       

                            </Box>  

                            </Box>  
                            <Box sx={{ width: { xs: '100%', md: '70%' }, justifyContent: 'flex-start', alignItems: 'center', display: 'flex', 
                            flexDirection: 'column', borderRadius: '4px', border: { xs: '0px solid #E6E6E6', md: '1px solid  #E6E6E6' }, 
                            maxHeight: '580px', overflowY: 'auto', paddingLeft: '20px', paddingRight:'20px',
                            '&::-webkit-scrollbar': { width: '12px', height: '8px', borderRadius: '16px' },
                            '&::-webkit-scrollbar-thumb': { backgroundColor: Colors.black_60, borderRadius: '16px', border: '2px solid white' },
                            '&::-webkit-scrollbar-track': { borderRadius: '16px', backgroundColor: 'none', }, 
                        }}>

                            <Box sx={{ flexGrow: 1, width: '100%', paddingTop:'2%' }}>
                              {console.log(selectedMessageDetails)}
                                {selectedMessageDetails.map((message, index) => (
                                   <Box  key={index} sx={{display:'flex', flexDirection:'column', width:'100%'}}>
                                   <Typography
                                   variant="body2"
                                   sx={{ fontSize: '14px', color:  Colors.black_80,
                                     textAlign: 'center' }} >
                                   {formatDate(message.send_time)}  
                               </Typography>
                                   <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: message.sent_recieve === 'receive' ? 'flex-end' : 'flex-start',
                                            padding: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >

                                           
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                maxWidth: '70%',
                                                padding: '12px 16px',
                                                borderRadius: '4px',
                                                backgroundColor: message.sent_recieve === 'receive' ? Colors.primary_blue : Colors.white_80,
                                                color: message.sent_recieve === 'receive' ? Colors.white : Colors.black,
                                            }}
                                        >
                                          { message.sent_recieve === 'sent' &&
                                            <Typography
                                                variant="body2"
                                                sx={{ fontSize: '14px', color: message.sent_recieve === 'receive' ? Colors.white : Colors.black_80 }}
                                            >
                                                Seeking {message.type_of_service} service within the {message.location} area. If you're available, reach out to me at this number + {message.country_code} {message.user_phone_number} 
                                            </Typography>}
                                            { message.sent_recieve === 'receive' &&
                                            <Typography
                                                variant="body2"
                                                sx={{ fontSize: '14px', color: message.sent_recieve === 'receive' ? Colors.white : Colors.black_80 }}
                                            >
                                                {message.type_of_service}  
                                            </Typography>}
                                            { message.sent_recieve === 'reply' &&
                                            <Typography
                                                variant="body2"
                                                sx={{ fontSize: '14px', color: message.sent_recieve === 'receive' ? Colors.white : Colors.black_80 }}
                                            >
                                                {message.type_of_service}  
                                            </Typography>}
                                            <Typography
                                                variant="body2"
                                                sx={{ fontSize: '14px', color: message.sent_recieve === 'receive' ? Colors.white : Colors.black_80,
                                                  textAlign:message.sent_recieve === 'receive' ?'left' : 'right' }} >
                                                {formatTime(message.send_time)}  
                                            </Typography>

                                           
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: '0px',
                                                    left: (message.sent_recieve === 'sent' ||  message.sent_recieve ==='reply') ? '-16px' : 'auto',
                                                    right: message.sent_recieve === 'receive' ? '-16px' : 'auto',
                                                    width: '0',borderRadius:'6px',
                                                    height: '0',
                                                    borderLeft: (message.sent_recieve === 'sent' ||  message.sent_recieve ==='reply') ? '24px solid transparent' : 'none',
                                                    borderRight: message.sent_recieve === 'receive' ? '24px solid transparent' : 'none',
                                                    borderTop: '20px solid',
                                                    borderTopColor: message.sent_recieve === 'receive' ? Colors.primary_blue : Colors.white_80,
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    </Box>
                                ))}
                            </Box>

                        

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px',
                                width:{md: 'calc(100% - 20px)', xs: 'calc(90% - 20px)'},
                            
                            }}
                        >
                            <TextField
                                variant="outlined"
                                value={reply}
                                onChange={handleMessageChange}
                                placeholder="Send message"
                                autoComplete="off"
                                sx={{
                                    width: {md:'100%', xs:'90%'},
                                    height: '48px', // Set the height of the TextField
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '28px',
                                        height: '48px', // Ensure the root also respects the height
                                    },
                                    '& input': {
                                        height: '48px', // Set input height to match
                                        padding: '0 14px', // Adjust padding if needed
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SendIcon
                                                sx={{
                                                    color: Colors.blue_10,
                                                    cursor: 'pointer',
                                                }}
                                                onClick={handleSendReply}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>

                           </Box>
                  </Box>
                   ) : (
                    <Box sx={{width: '100%', height: '100%',justifyContent: 'center', alignItems: 'center', display:  'flex', 
                      flexDirection: 'column',maxWidth:'80%',gap:'12px',  }}>
                           <img src={NoMsg} alt="No messages" style={{ width: '20%', height: '20%' }} />
                          <TypographySubHeading sx={{fontWeight:400,}}> No messages yet!</TypographySubHeading>
                          <TypographyNormal sx={{ textAlign:'center', lineHeight:'24px' }}>
                          It looks a little empty here. Start a new conversation, and all your chats will appear in this space! 
                          </TypographyNormal>
                  </Box>
                   
                  )}
        </Box>

        <Box sx={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: {xs:'flex', md: 'none'} ,
            flexDirection: 'row',gap:'16px', paddingTop:'2%',paddingBottom:'2%', }}>
                 <Box sx={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' ,
                      flexDirection: 'column',gap:'16px',maxWidth:{ xs: '90% ', md: '94%' } }}>
                        {console.log(isMsgClick)}
                           {( !isMsgClick && messageDetails.length > 0) &&
                            <Box sx={{width: '100% ',justifyContent: 'flex-start', alignItems: 'center', display: 'flex' ,
                                flexDirection: 'column',border:{ xs: '0px solid #E6E6E6', md: '1px solid  #E6E6E6' }, 
                                borderColor: Colors.grey_30, paddingTop:'2%', paddingBottom:'2%',borderRadius:'4px',
                                 overflowY:'auto', '&::-webkit-scrollbar': {
                                    width: '12px', height: '8px', borderRadius: '16px',
                                  },
                                  '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: Colors.black_60, borderRadius: '16px', border: '2px solid white',
                                  },
                                  '&::-webkit-scrollbar-track': {
                                    borderRadius: '16px', backgroundColor: 'none', 
                                  }, }}>
                                  <Box sx={{width: '100%',justifyContent: 'center', alignItems: 'center', display: 'flex' ,
                                   flexDirection: 'column',maxWidth:'90%',gap:'12px', }}>
                                    
                                    <SpMessage messageDetails={messageDetails} setMessageDetails={setMessageDetails}
                                    setSelectedMessageDetails={setSelectedMessageDetails} isView = {true}></SpMessage>
                                       

                            </Box>  

                            </Box>  }
                            {( !isMsgClick && messageDetails.length === 0 && !loading) &&
                            <Box sx={{width: '100%', height: '100%',justifyContent: 'center', alignItems: 'center', display:  'flex', 
                      flexDirection: 'column',maxWidth:'80%',gap:'12px',paddingTop:'50%'  }}>
                           <img src={NoMsg} alt="No messages" style={{ width: '40%', height: '40%' }} />
                          <TypographySubHeading sx={{fontWeight:400,}}> No messages yet!</TypographySubHeading>
                          <TypographyNormal sx={{ textAlign:'center', lineHeight:'24px' }}>
                          It looks a little empty here. Start a new conversation, and all your chats will appear in this space! 
                          </TypographyNormal>
                       </Box>}

                            { isMsgClick &&
                            <Box sx={{ width:{md: 'calc(100% - 40px)', xs:'calc(100% - 20px)'}, justifyContent: 'flex-start', alignItems: 'center', display: 'flex', 
                            flexDirection: 'column', borderRadius: '4px', border: { xs: '0px solid red', md: '1px solid  #E6E6E6' }, minHeight:'85vh',
                            overflowY:{md:'auto', xs:'none'}, paddingLeft: '10px', paddingRight:'10px',
                            '&::-webkit-scrollbar': { width: '12px', height: '8px', borderRadius: '16px' },
                            '&::-webkit-scrollbar-thumb': { backgroundColor: Colors.black_60, borderRadius: '16px', border: '2px solid white' },
                            '&::-webkit-scrollbar-track': { borderRadius: '16px', backgroundColor: 'none', }, 
                        }}>
                           <Breadcrumbs sx={{width:'100%', padding:'8px', marginBottom:'12px'}}                           
                            aria-label="breadcrumb" >
                            <TypographySubHeading sx={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '20px', justifyContent: 'left',textAlign:'left' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer',  
                                    height: '100%' }}  onClick={handleBreadcrumbClick} >
                                <ArrowBackIcon  sx={{ color: Colors.black_90, fontSize: '18px', '&:hover': { fontSize: '22px' } }} />
                                </Box>
                               +1 {selectedPhoneNumber}
                            </TypographySubHeading> 
                            </Breadcrumbs>

                            <Box sx={{ flexGrow: 1, width: '100%', }}>
                                {/* {selectedMessageDetails.map((message, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',width:'calc(100% - 20px)',
                                            justifyContent: message.sent_recieve === 'receive' ? 'flex-end' : 'flex-start',
                                            padding: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                maxWidth: {md:'70%', xs:'calc(100% - 32px)'},
                                                padding: '12px 16px',
                                                borderRadius: '4px',
                                                backgroundColor: message.sent_recieve === 'receive' ? Colors.primary_blue : Colors.white_80,
                                                color: message.sent_recieve === 'receive' ? Colors.white : Colors.black,
                                            }}
                                        >
                                          { message.sent_recieve === 'sent' &&
                                            <Typography
                                                variant="body2"
                                                sx={{ fontSize: '14px', color: message.sent_recieve === 'receive' ? Colors.white : Colors.black_80 }}
                                            >
                                                Seeking {message.type_of_service} service within the {message.location} area. If you're available, reach out to me at this number + {message.country_code} {message.user_phone_number} 
                                            </Typography>}
                                            { message.sent_recieve === 'receive' &&
                                            <Typography
                                                variant="body2"
                                                sx={{ fontSize: '14px', color: message.sent_recieve === 'receive' ? Colors.white : Colors.black_80 }}
                                            >
                                                {message.type_of_service}  
                                            </Typography>}
                                            { message.sent_recieve === 'reply' &&
                                            <Typography
                                                variant="body2"
                                                sx={{ fontSize: '14px', color: message.sent_recieve === 'receive' ? Colors.white : Colors.black_80 }}
                                            >
                                                {message.type_of_service}  
                                            </Typography>}

                                           
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: '0px',
                                                    left: (message.sent_recieve === 'sent' ||  message.sent_recieve ==='reply') ? '-16px' : 'auto',
                                                    right: message.sent_recieve === 'receive' ? '-16px' : 'auto',
                                                    width: '0',borderRadius:'6px',
                                                    height: '0',
                                                    borderLeft: (message.sent_recieve === 'sent' ||  message.sent_recieve ==='reply') ? '24px solid transparent' : 'none',
                                                    borderRight: message.sent_recieve === 'receive' ? '24px solid transparent' : 'none',
                                                    borderTop: '20px solid',
                                                    borderTopColor: message.sent_recieve === 'receive' ? Colors.primary_blue : Colors.white_80,
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                ))} */}
                                 {selectedMessageDetails.map((message, index) => (
                                   <Box  key={index} sx={{display:'flex', flexDirection:'column', width:'100%'}}>
                                   <Typography
                                   variant="body2"
                                   sx={{ fontSize: '14px', color:  Colors.black_80,
                                     textAlign: 'center' }} >
                                   {formatDate(message.send_time)}  
                               </Typography>
                                   <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: message.sent_recieve === 'receive' ? 'flex-end' : 'flex-start',
                                            padding: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >

                                           
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                maxWidth: '100%',
                                                padding: '12px 16px',
                                                borderRadius: '4px',
                                                backgroundColor: message.sent_recieve === 'receive' ? Colors.primary_blue : Colors.white_80,
                                                color: message.sent_recieve === 'receive' ? Colors.white : Colors.black,
                                            }}
                                        >
                                          { message.sent_recieve === 'sent' &&
                                            <Typography
                                                variant="body2"
                                                sx={{ fontSize: '14px', color: message.sent_recieve === 'receive' ? Colors.white : Colors.black_80 }}
                                            >
                                                Seeking {message.type_of_service} service within the {message.location} area. If you're available, reach out to me at this number + {message.country_code} {message.user_phone_number} 
                                            </Typography>}
                                            { message.sent_recieve === 'receive' &&
                                            <Typography
                                                variant="body2"
                                                sx={{ fontSize: '14px', color: message.sent_recieve === 'receive' ? Colors.white : Colors.black_80 }}
                                            >
                                                {message.type_of_service}  
                                            </Typography>}
                                            { message.sent_recieve === 'reply' &&
                                            <Typography
                                                variant="body2"
                                                sx={{ fontSize: '14px', color: message.sent_recieve === 'receive' ? Colors.white : Colors.black_80 }}
                                            >
                                                {message.type_of_service}  
                                            </Typography>}
                                            <Typography
                                                variant="body2"
                                                sx={{ fontSize: '14px', color: message.sent_recieve === 'receive' ? Colors.white : Colors.black_80,
                                                  textAlign:message.sent_recieve === 'receive' ?'left' : 'right' }} >
                                                {formatTime(message.send_time)}  
                                            </Typography>

                                           
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: '0px',
                                                    left: (message.sent_recieve === 'sent' ||  message.sent_recieve ==='reply') ? '-16px' : 'auto',
                                                    right: message.sent_recieve === 'receive' ? '-16px' : 'auto',
                                                    width: '0',borderRadius:'6px',
                                                    height: '0',
                                                    borderLeft: (message.sent_recieve === 'sent' ||  message.sent_recieve ==='reply') ? '24px solid transparent' : 'none',
                                                    borderRight: message.sent_recieve === 'receive' ? '24px solid transparent' : 'none',
                                                    borderTop: '20px solid',
                                                    borderTopColor: message.sent_recieve === 'receive' ? Colors.primary_blue : Colors.white_80,
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    </Box>
                                ))}
                            </Box>

                        

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px',
                                width: 'calc(100% - 20px)',
                            
                            }}
                        >
                            <TextField
                                variant="outlined"
                                value={reply}
                                onChange={handleMessageChange}
                                placeholder="Send message"
                                autoComplete="off"
                                sx={{
                                    width: '100%',
                                    height: '48px', // Set the height of the TextField
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '28px',
                                        height: '48px', // Ensure the root also respects the height
                                    },
                                    '& input': {
                                        height: '48px', // Set input height to match
                                        padding: '0 14px', // Adjust padding if needed
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SendIcon
                                                sx={{
                                                    color: Colors.blue_10,
                                                    cursor: 'pointer',
                                                }}
                                                onClick={handleSendReply}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>

                            </Box>}


                                                

                                    </Box>
        </Box>
    {/* <Footer ></Footer> */}
    </Grid>
    )
}
export default SpViewMessage;