import React from 'react';
import { Navigate } from 'react-router-dom';

const SpProtectedRoute = ({ element, ...rest }) => {
  const spToken = localStorage.getItem('spToken');

  return spToken ? (
    element
  ) : (
    <Navigate to="/" replace />
  );
};

export default SpProtectedRoute;
