
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import '../App.css';
import { Link } from 'react-router-dom';
import CopyrightIcon from '@mui/icons-material/Copyright';
import Colors from '../Components/Colors';
import footer from "../Images/footer.svg"; 
import footermob from "../Images/ftmob.svg"; 

function Footer(props) {
  console.log(props)

  const {currentPageFooter} = props   
  
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      
      const menus = [
        { label: "Home", link: "/" },           
        { label: "About us", link: "/about-us" },
        { label: "Contact us", link: "/contact-us" } , 
       
    ];

    if (localStorage.getItem("spToken")) {
         menus[0].link = "/sp-landing";
       } else if (!localStorage.getItem("userToken")) {
         menus[0].link = "/";  
     }

    const menuColumn2 = [
        { label: "Disclaimer", link: "/disclaimer" },
        { label: "Privacy policy", link: "/privacy-policy " },
        { label: "Terms and conditions", link: "/terms-and-conditions" },
        
    ];

   

    const menuColumn4 = [
        { label: "Youtube", link: "https://www.youtube.com/" },
        { label: "Instagram", link: "https://www.instagram.com/" },
        { label: "Facebook", link: "https://www.facebook.com/" },
        
    ];
    
    const pages = ['Home', 'About us', 'Contact us', 'Disclaimer', 'Privacy policy', 'Terms and conditions'];

   
    return (
      <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px'}}>
         <Box sx={{
                width: '100%', height: '100%',justifyContent: 'center', alignItems: 'center', display: { xs: 'none', md: 'flex' }, 
                flexDirection: 'row',
            }}>
        <Box  sx={{display:'flex',flexDirection:'column',width:'100%',backgroundImage: `url(${footer})`, 
             backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '96%', justifyContent: 'center',
             alignItems: 'center',paddingTop:'2%', paddingBottom:'2%'}}>

           <Box sx={{display: 'flex', flexDirection:'row', width: '100%', height: '100%',justifyContent: 'center',
             alignItems: 'center' ,}}>

                <Box sx={{display: { md: 'flex', sm: 'flex', xs:'flex' },flexDirection:{ md:'row', sm:'row', xs:'column' }, 
                     gap:{ md:'12%', sm:'12%', xs:'10%' }, width:'100%', height: '100%'}}>

                    <Box sx={{display: { md: 'flex', sm: 'flex', xs:'flex' },flexDirection:{ md:'row', sm:'row', xs:'column' }, 
                          gap:{ md:'12%', sm:'12%', xs:'10%' }, width:'100%', height: '100%',}}>

                            <Box  sx={{display:'flex',flexDirection:'column',gap:{ md:'4px', sm:'4px', xs:'0px' },padding:0,  
                                  justifyContent: 'center',alignItems: 'flex-start' ,width:'calc(100%/3)', paddingLeft:'10%'}}>
                            {menus.map((menu) => (
                                          <Button
                                              key={menu.label}
                                              component={Link}
                                              to={menu.link}
                                              sx={{
                                                  color:Colors.white, textTransform: "none", fontFamily: "Roboto", fontSize: "12px",
                                                  lineHeight: "20px", fontWeight: currentPageFooter === menu.label ? 700 : 400,
                                                  textAlign:'left', minWidth: '0px !important',
                                                  '& .css-iuwf2y-MuiButtonBase-root-MuiButton-root': {
                                                        minWidth: '0px !important', 
                                                    },
                                                  '&:hover': {
                                                    fontWeight: "700", background:'none'
                                                  }, 
                                                                                 
                                              }}>
                                              {menu.label}
                                          </Button>
                                      ))}
                            </Box>

                            <Box  sx={{display:'flex',flexDirection:'column',gap:{ md:'4px', sm:'4px', xs:'0px' }, 
                                  justifyContent: 'center',alignItems: 'flex-start' ,width:'calc(100%/3)' ,paddingLeft:'10%' }}>
                            {menuColumn2.map((menu) => (
                                          <Button
                                              key={menu.label}
                                              component={Link}
                                              to={menu.link}
                                              sx={{
                                                  color:Colors.white,textTransform: "none", fontFamily: "Roboto", fontSize: "12px", 
                                                  lineHeight: "20px",   fontWeight: currentPageFooter === menu.label ? 700 : 400,'&:hover': {fontWeight: "700",background:'none' },                                  
                                              }}>
                                              {menu.label}
                                          </Button>
                                      ))}
                            </Box> 
                          
                            <Box  sx={{display:'flex',flexDirection:'column',gap:{ md:'4px', sm:'4px', xs:'0px' }, 
                                  justifyContent: 'center', alignItems: 'flex-start', width:'calc(100%/3)' ,paddingLeft:'10%' ,}}>
                            {menuColumn4.map((menu) => (
                                          <Button
                                              key={menu.label}
                                              component={Link}
                                            //  to={menu.link}
                                              sx={{
                                                  color:Colors.white,textTransform: "none", fontFamily: "Roboto", fontSize: "12px",
                                                  lineHeight: "20px",fontWeight: "400", '&:hover': {fontWeight: "700", background:'none'},                                    
                                              }}>
                                              {menu.label}
                                          </Button>
                                      ))}
                            </Box>                
                    </Box>
                </Box>
           
               </Box>

            <Box sx={{display:'flex',flexDirection:'row',gap:'5px', justifyContent: 'center', alignItems: 'center'
                     ,width:'100%',paddingTop:'20px'}}>
                <CopyrightIcon sx={{color:Colors.white,fontSize: '9px',lineHeight: '18px', }}></CopyrightIcon>
                <Typography sx={{fontFamily: "Roboto", fontSize: '8px',  lineHeight: '18px', fontWeight: '400',
                  color:Colors.white,}}>2023-2025</Typography>
                <Typography sx={{fontFamily: "Roboto", fontSize: '8px',  lineHeight: '18px', fontWeight: '400', 
                 color:Colors.white, }}>all rights reserved by</Typography>
               <Link to="https://www.varsha-info.com/"   target="_blank"  rel="noopener noreferrer" 
                  style={{ textDecoration: 'none', color: Colors.white, fontFamily: "Roboto", fontSize: '8px',  
                    lineHeight: '18px', fontWeight: '400', transition: 'text-decoration 0.2s',  textUnderlineOffset: "5px",}}
                  onMouseEnter={(e) => {e.target.style.textDecoration = 'underline'; }}
                  onMouseLeave={(e) => {e.target.style.textDecoration = 'none'; }}>
                  www.varsha-info.com
              </Link>







            </Box>

        </Box> 
        </Box>

        <Box sx={{
                width: '100%', height: '100%',justifyContent: 'center', alignItems: 'center', display: { xs: 'flex', md: 'none' }, 
                flexDirection: 'row',
            }}>
        <Box  sx={{display:'flex',flexDirection:'column',width:'100%',  
        backgroundImage: `url(${footermob})`, backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',backgroundPosition: 'center',  height: '94%', justifyContent: 'center',
             alignItems: 'center',paddingTop:'3%', paddingBottom:'3%', }}>

           <Box sx={{display: 'flex', flexDirection:'row', width: '100%', height: '100%',justifyContent: 'center',
             alignItems: 'center' ,}}>

                <Box sx={{display: { md: 'flex', sm: 'flex', xs:'flex' },flexDirection:{ md:'row', sm:'row', xs:'column' }, 
                     gap:{ md:'12%', sm:'12%', xs:'10%' }, width:'100%', height: '100%'}}>

                    <Box sx={{display: { md: 'flex', sm: 'flex', xs:'flex' },flexDirection:'row', 
                          gap:{ md:'12%', sm:'12%', xs:'5%' }, width:'100%', height: '100%',}}>

                            <Box  sx={{display:'flex',flexDirection:'column',gap:{ md:'8px', sm:'8px', xs:'0px' },padding:0,  
                                  justifyContent: 'center',alignItems: 'flex-start' ,width:'calc(50%)', paddingLeft:'10%'}}>
                            {menus.map((menu) => (
                                          <Button
                                              key={menu.label}
                                              component={Link}
                                              to={menu.link}
                                              sx={{
                                                  color:Colors.white, textTransform: "none", fontFamily: "Roboto", fontSize: "12px",
                                                  lineHeight: "20px", fontWeight: currentPageFooter === menu.label ? 700 : 400,
                                                  textAlign:'left', minWidth: '0px !important',
                                                  '& .css-iuwf2y-MuiButtonBase-root-MuiButton-root': {
                                                        minWidth: '0px !important', 
                                                    },
                                                  '&:hover': {
                                                    fontWeight: "700", background:'none'
                                                  }, 
                                                                                 
                                              }}>
                                              {menu.label}
                                          </Button>
                                      ))}
                                       {menuColumn2.map((menu) => (
                                          <Button
                                              key={menu.label}
                                              component={Link}
                                              to={menu.link}
                                              sx={{
                                                  color:Colors.white,textTransform: "none", fontFamily: "Roboto", fontSize: "12px", 
                                                  lineHeight: "20px", fontWeight: pages === currentPageFooter ? 700 : 400,
                                                  '&:hover': {fontWeight: 700,background:'none' },                                  
                                              }}>
                                              {menu.label}
                                          </Button>
                                      ))}
                            </Box>

                           
                           
                           
                          
                            <Box  sx={{display:'flex',flexDirection:'column',gap:{ md:'8px', sm:'8px', xs:'0px' }, 
                                  justifyContent: 'flex-start', alignItems: 'flex-start', width:'calc(50%)' ,paddingLeft:'10%' ,}}>
                            {menuColumn4.map((menu) => (
                                          <Button
                                              key={menu.label}
                                              component={Link}
                                            //  to={menu.link}
                                              sx={{
                                                  color:Colors.white,textTransform: "none", fontFamily: "Roboto", fontSize: "12px",
                                                  lineHeight: "20px",fontWeight: pages === currentPageFooter ? 700 : 400, '&:hover': {fontWeight: 700, background:'none'},                                    
                                              }}>
                                              {menu.label}
                                          </Button>
                                      ))}
                            </Box>                
                    </Box>
                </Box>
           
               </Box>

            <Box sx={{display:'flex',flexDirection:'row',gap:'5px', justifyContent: 'center', alignItems: 'center'
                     ,width:'100%',paddingTop:'20px'}}>
                <CopyrightIcon sx={{color:Colors.white,fontSize: '9px',lineHeight: '18px', }}></CopyrightIcon>
                <Typography sx={{fontFamily: "Roboto", fontSize: '8px',  lineHeight: '18px', fontWeight: '400',
                  color:Colors.white,}}>2023-2025</Typography>
                <Typography sx={{fontFamily: "Roboto", fontSize: '8px',  lineHeight: '18px', fontWeight: '400', 
                 color:Colors.white, }}>all rights reserved by</Typography>
               <Link to="https://www.varsha-info.com/"   target="_blank"  rel="noopener noreferrer" 
                  style={{ textDecoration: 'none', color: Colors.white, fontFamily: "Roboto", fontSize: '8px',  
                    lineHeight: '18px', fontWeight: '400', transition: 'text-decoration 0.2s',  textUnderlineOffset: "5px",}}
                  onMouseEnter={(e) => {e.target.style.textDecoration = 'underline'; }}
                  onMouseLeave={(e) => {e.target.style.textDecoration = 'none'; }}>
                  www.varsha-info.com
              </Link>







            </Box>

        </Box> 
        </Box>
      </Grid>
          );
    }export default Footer;