
import React, { useEffect, useState, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom'; 
import logo from '../Images/userlogo.svg'
import logomob from '../Images/logomob.svg'
import Colors from './Colors';
import SearchComponent from './SearchComponent';
import axios from "axios";
import { API_URL } from './Constants';
import { Avatar } from '@mui/material';
import TypographyNormal from './TypographyNormal';

const pages = ['Home', 'About us', 'Contact us'];

const colors = ['#503259', '#4C50AE', '#FF7D6A', '#B07CC6', '#20C925'];

function stringToColor(string) {
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  const colorIndex = Math.abs(hash) % colors.length;
  return colors[colorIndex];
}

function stringAvatar(name = '') {
  const initials = name.split(' ').map(word => word[0]).join('');
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: initials.toUpperCase() || '?', 
  };
}

function UserHeader() { 
    
    const dropDownRef = useRef(null);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [selectedPage, setSelectedPage] = React.useState('');
    const [search, setSearch] = useState('');
    const [userDetails, setUserDetails] = useState([]);
    const [isShowDropdown, setIsShowDropdown] = useState(false);

    const handleOpenDrawer = () => {
        setOpenDrawer(true);
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const handleMenuItemClick = (page) => {
        setSelectedPage(page);
    };

    useEffect(() => {
        if (localStorage.getItem("service")) {
          setSearch(localStorage.getItem("service"))
        } 
        else if(localStorage.getItem("Type")) {
          setSearch(localStorage.getItem("Type")) 
        }
        else if(localStorage.getItem("SPName")){
          setSearch(localStorage.getItem("SPName"))   
        }
        else if(localStorage.getItem("SPCode")){
          setSearch(localStorage.getItem("SPCode"))  
        }
    }, []);

    useEffect(() => { 
        if(localStorage.getItem("userTocken")) {         
        const fetchData = async () => {            
            try { 
                const response = await axios.post(`${API_URL}/select_user_details_with_tocken`, {                    
                    userTocken: localStorage.getItem("userTocken")
                }); 
                console.log(response.data.result)
                setUserDetails(response.data.result)
                
            } catch (error) {
                console.error('Error fetching data:', error);
                
            }
        };
        fetchData();
    }
    
        
    }, [localStorage.getItem("userTocken")]);

    const handleAvatarButtonClick = () => {
        setIsShowDropdown(!isShowDropdown)                 
     }

    const handleSignOutClick = () => {
        localStorage.removeItem("userTocken");
        window.location.href = '/';         
    };

    useEffect(() => {
        function handleClickOutside(event) {
          if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsShowDropdown(false); // Hide the box when clicking outside
          }
        }
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [dropDownRef, setIsShowDropdown]);

     

    return (
        <AppBar position="static" sx={{height:{ xs: '68px', md: '74px' } , justifyContent: 'center',width:'100%',gap:'32px',
        boxShadow: 'none', background: Colors.white_80, alignItems:'center',border:'1px solid ', borderColor: Colors.grey_30}}>
            <Container maxWidth={false}  sx={{ height: { xs: '48px', md: '74px' },width:{ xs: '98%', md: '96%' } ,}}>
                <Toolbar disableGutters sx={{ height: '100%',}}>
                <Box sx={{width: '100%' , height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
                flexDirection:'row' }}>                   
                <Box onClick={() => { window.location.href = "/" }} sx={{width: { xs: '36px', md: '36px' } , height: { xs: '36px', md: '36px' }, display: { xs: 'none', md: 'flex' },
                justifyContent: 'center', alignItems: 'center',}}>
                <img src={logo} alt="Logo" style={{maxWidth: '100%', maxHeight: '100%', cursor: 'pointer' }} />
                </Box>
                <Box onClick={() => { window.location.href = "/" }} sx={{width: { xs: '24px', md: '24px' } , height: { xs: '24px', md: '24px' }, display: { xs: 'flex', md: 'none' },
                justifyContent: 'center', alignItems: 'center',}}>
                <img src={logomob} alt="Logo" style={{maxWidth: '100%', maxHeight: '100%', objectFit: 'contain',cursor: 'pointer' }} />
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs:'flex', md:'none' },width:'16%' }}>
                        <IconButton  size="large"  aria-label="open drawer"
                        onClick={handleOpenDrawer} color={Colors.black_80}>
                        <MenuIcon />
                        </IconButton>
                        <Drawer
                            anchor="left"  open={openDrawer}
                            onClose={handleCloseDrawer}
                            sx={{'& .MuiDrawer-paper': {
                                    backgroundColor: Colors.hovering_blue, 
                                },
                                backdropFilter: 'none', }}
                                BackdropProps={{ 
                                    sx: { backgroundColor: 'rgba(0, 0, 0, 0)' }, 
                                }}
                             >
                            <Box sx={{ display: 'flex' }}>
                                <IconButton  aria-label="close drawer"
                                    onClick={handleCloseDrawer}
                                    sx={{ ml: 'auto', color: 'white' }}  >
                                <CloseIcon />
                                </IconButton>
                            </Box>
                            <Box  sx={{ width: 200 }} >                               
                                  {pages.map((page) => (
                                    <Link 
                                        key={page}
                                        to={`/${page.toLowerCase().replace(' ', '-')}`} 
                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                    >
                                        <MenuItem
                                        selected={selectedPage === page}
                                        onClick={() => handleMenuItemClick(page)}
                                        sx={{
                                            color: "white", display: "block", textTransform: "none", background:  Colors.hovering_blue,
                                            fontFamily: "Roboto", fontSize: "16px", lineHeight: "36px", fontWeight: 400,
                                            '&:hover': { color: "white", bgcolor: 'white', fontWeight: "600" }
                                        }}
                                        >
                                        {page}
                                        </MenuItem>
                                    </Link>
                                    ))}
                            </Box>
                           
                    </Drawer>
                       
                </Box>
                <Box sx={{flexGrow: 1, display: { xs: 'none', md: 'flex' }, height: '100%', width:'35%',justifyContent:'center',
                 alignItems:'center',gap:'26px' }}>
                        {pages.map((page) => (
                            <Button key={page}
                                sx={{color: Colors.black_80, display: "block",  textTransform: "none",
                                    fontFamily: "Roboto",fontSize: "16px", lineHeight: "24px", fontWeight: 400,
                                    '&:hover': { color: Colors.black_80,bgcolor:Colors.white_80, fontWeight: 600}}}>
                                <Link to={`/${page.toLowerCase().replace(' ', '-')}`} style={{ textDecoration: 'none', color: 'inherit' }}>{page}</Link>
                            </Button>
                        ))}
                </Box>
                <Box sx={{width:{ xs: '85%', md: '55%' }  , height: { xs: '36px', md: '36px' }, display: 'flex',
                 justifyContent: 'right', maxHeight:'36px', alignItems: 'center', }}>
                 <SearchComponent isHeader={true} searchValue={search}>
                </SearchComponent>
                </Box>
                
                { localStorage.getItem("userTocken") && userDetails.name && <Box sx={{width:{ xs: '75px', md: '10%' }  , height: { xs: '36px', md: '36px' }, display: 'flex', justifyContent: 'right', 
                alignItems: 'center', gap:{ xs: '8px', md: '16px' } }} onClick={handleAvatarButtonClick}>
               <Avatar  {...stringAvatar(userDetails.name[0])} 
                            sx={{ width: '32px', height: '32px', fontSize: '18px', bgcolor:'#4C50AE' }} />
                  {isShowDropdown && ( <Box ref={dropDownRef}   sx={{position: 'absolute', top: '100%', width: '250px',right:'0px',
                                borderRadius: '4px', marginTop: '4px', padding: '10px', backgroundColor: Colors.white_80,
                                zIndex: 1,boxShadow: '0px 4px 4px 0px #00000040', gap:'4px', display:'flex', flexDirection:'column'
                            }}>
                                <Box sx={{ padding: '4px 10px', cursor: 'pointer', borderRadius: '4px', color: Colors.black_90,
                                    '&:hover': { backgroundColor: Colors.primary_blue,  color: Colors.white },
                                    }}                                   
                                    //onClick={() => {handleFilterDrpDownClick('All',0);}}
                                    >
                                    <TypographyNormal sx={{maxWidth: '98%', whiteSpace: 'nowrap',overflow: 'hidden',
                                        textOverflow: 'ellipsis',color: 'inherit'}}>
                                    Profile
                                    </TypographyNormal>
                                </Box>
                                <Box sx={{ padding: '4px 10px', cursor: 'pointer', borderRadius: '4px',color: Colors.black_90,
                                    '&:hover': { backgroundColor: Colors.primary_blue ,  color: Colors.white},
                                    }}                                   
                                    //onClick={() => {handleFilterDrpDownClick('All',0);}}
                                    >
                                    <TypographyNormal sx={{maxWidth: '98%', whiteSpace: 'nowrap',overflow: 'hidden',
                                        textOverflow: 'ellipsis',color: 'inherit'}}>
                                   Search history
                                    </TypographyNormal>
                                </Box>
                                <Box sx={{ padding: '4px 10px', cursor: 'pointer', borderRadius: '4px',color: Colors.black_90,
                                    '&:hover': { backgroundColor: Colors.primary_blue ,  color: Colors.white},
                                    }}                                   
                                    onClick={handleSignOutClick}>
                                    <TypographyNormal sx={{maxWidth: '98%', whiteSpace: 'nowrap',overflow: 'hidden',
                                        textOverflow: 'ellipsis',color: 'inherit'}}>
                                   Sign out
                                    </TypographyNormal>
                                </Box>
                                </Box>)}       
                </Box>}
                </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default UserHeader;