import { Box, Grid, Typography, Avatar, Rating, TextField } from "@mui/material"
import React, { useEffect, useState, useCallback, useRef } from 'react';
import Header from "../Components/Header";
import homebg from "../Images/homebg.svg";
import homebgmob from "../Images/homebgmob.svg";
import DiscSkill from "../Images/DiscSkill.svg";
import Sp from "../Images/sp.svg";
import User from "../Images/user.svg";
import Story from "../Images/Story.svg";
import Colors from "../Components/Colors";
import TypographyNormal from "../Components/TypographyNormal";
import TypographyHeading from "../Components/TypographyHeading";
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyError from "../Components/TypographyError";
import Footer from "../Components/Footer";
import ButtonBlue from "../Components/ButtonBlue";
import axios from "axios";
import { API_URL } from '../Components/Constants';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import SearchComponent from "../Components/SearchComponent";
import TextfieldNormal from "../Components/TextfieldNormal";


const colors = ['#503259', '#4C50AE', '#FF7D6A', '#B07CC6', '#20C925'];

function stringToColor(string) {
    let hash = 0;
    for (let i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    const colorIndex = Math.abs(hash) % colors.length;
    return colors[colorIndex];
}

function stringAvatar(name = '') {
    // Ensure that name is a string and handle cases where name is null or undefined
    const validName = name || '';

    // Split the name and create initials
    const initials = validName
        .split(' ')
        .filter(word => word.length > 0) // Filter out any empty strings
        .map(word => word[0])
        .join('');

    return {
        sx: {
            bgcolor: stringToColor(validName),
        },
        children: initials.toUpperCase() || '?', // Fallback to '?' if no initials are found
    };
}



function Home() {

    const commentRef = useRef(null);
    const [ratingValue, setRatingValue] = useState(null);
    const [ratingValueError, setRatingValueError] = useState('');
    const [ratingName, setRatingName] = useState('');
    const [ratingNameError, setRatingNameError] = useState('');
    const [ratingComment, setRatingComment] = useState('');
    const [ratingCommentError, setRatingCommentError] = useState('');
    const [ratingDetails, setRatingDetails] = useState([]);
    const [currentRatingDetails, setCurrentRatingDetails] = useState([]);
    const [currentRatingIndex, setCurrentRatingIndex] = useState(0);
    const [ratinbBoxExpanded, setRatinbBoxExpanded] = useState(false);
    const [isSuccessMessage, setIsSuccessMessage] = useState(false)
   

    const handleRatingValueChange = (event, newValue) => {
        console.log(newValue)
        if(newValue !== null)
        {
            setRatingValue(newValue);
        }
        setRatingValueError('')       
    };

   
    

    const handleRatingNameChange = (event) => {
        setRatingName(event.target.value);
        setRatingNameError('')
    };

    const handleRatingCommentChange = (event) => {
        setRatingComment(event.target.value);
        setRatingCommentError('')
    };

    const handlePrevClick = () => {
        setCurrentRatingIndex(prevIndex =>
            prevIndex === 0 ? Math.max(ratingDetails.length - 1, 0) : prevIndex - 1
        );
        setExpandedReviews(expandedReviews.map(() => false));
    };
    
    const handleNextClick = () => {
        setCurrentRatingIndex(prevIndex =>
            prevIndex === ratingDetails.length - 1 ? 0 : prevIndex + 1
        );
        setExpandedReviews(expandedReviews.map(() => false));
    };

    const handleRatingSubmit = async () => {

        if (ratingValue === null) {
            setRatingValueError('Please provide a rating.');
        }
        else if (ratingName.trim() === '') {
            setRatingNameError('Please enter your name.');
        }
        else if (ratingComment.trim() === '') {
            setRatingCommentError('Please add your comment.');
        }
        else {
            try {
                const response = await axios.post(`${API_URL}/insert_rating`, {
                    userToken: localStorage.getItem("userToken"),
                    ratingValue: ratingValue,
                    ratingName: ratingName,
                    ratingComment: ratingComment,
                });
                console.log("response", response);
                if (response.data.success === true) {
                    setRatingComment('')
                    setRatingName('')
                    setRatingValue(null)
                    fetchRatings()
                    setIsSuccessMessage(true);
                }
            }
            catch (error) {
                console.error('Error:', error);
            }
        }
    }

    useEffect(() => {
        if (isSuccessMessage) {
          const timer = setTimeout(() => {
            setIsSuccessMessage(false);
          }, 8000); // 30 seconds      
          return () => clearTimeout(timer);
        }
      }, [isSuccessMessage]);

    const fetchRatings = async () => {
        try {
            const response = await axios.get(`${API_URL}/select_ratings`,);
            console.log(response.data.result)
            setRatingDetails(response.data.result)
        } catch (error) {
            console.error('Error fetching service categories:', error);
        }
    };

    useEffect(() => {
        fetchRatings();
    }, []);

    useEffect(() => {
        if (ratingDetails.length > 0) {
            const timer = setTimeout(() => {
                setCurrentRatingDetails(ratingDetails[currentRatingIndex]);
            }, 100);
            return () => clearTimeout(timer);
        }
    }, [currentRatingIndex, ratingDetails]);

    const reviewsToShow = ratingDetails.slice(currentRatingIndex, currentRatingIndex + 3);    
    
    if (reviewsToShow.length < 3) {
        const remainingReviews = ratingDetails.slice(0, 3 - reviewsToShow.length);
        reviewsToShow.push(...remainingReviews);
    }

    const [expandedReviews, setExpandedReviews] = useState(
        Array(reviewsToShow.length).fill(false)
    );
    
    const handleToggleExpand = (index) => {
        setExpandedReviews((prev) => {
            const newState = [...prev];
            newState[index] = !newState[index];
            return newState;
        });
    };
    


    return (
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>

            <Header currentPage={"Home"}></Header>

            <Box sx={{
                width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: { xs: 'none', md: 'flex' },
                flexDirection: 'column',
            }}>
                <Box sx={{
                    width: '100%', height: '596px', backgroundImage: `url(${homebg})`, backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row',
                }}>
                    <Box sx={{
                        width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', maxWidth: '90%',
                        display: 'flex', flexDirection: 'row',
                    }}>
                        <Box sx={{
                            width: '60%', height: '100%', justifyContent: 'center', alignItems: 'right',
                            display: 'flex', flexDirection: 'column', gap: '16px'
                        }}>
                            <Typography sx={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '40px', color: Colors.white, lineHeight: '68px' }}>
                                What services are you looking for?
                            </Typography>
                            <TypographyNormal sx={{ color: Colors.white, fontWeight: 400, fontSize: '16px', lineHeight: '24px' }}>Find the help you need, right in your neighborhood.
                            </TypographyNormal>
                            <SearchComponent isHeader={false}  searchValue={""} ></SearchComponent>
                        </Box>
                        <Box sx={{
                            width: '40%', height: '100%', justifyContent: 'right', alignItems: 'center', maxWidth: '90%',
                            display: 'flex', flexDirection: 'row'
                        }}>
                        </Box>
                    </Box>

                </Box>

                <Box sx={{
                    width: '100%', height: '95%',  justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row',
                    paddingTop: '2.5%', paddingBottom: '2.5%'
                }}>

                    <Box sx={{
                        width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                        flexDirection: 'column', maxWidth: '92.5%', gap: '32px'
                    }}>
                        <TypographyHeading>Our achievements</TypographyHeading>
                        <Box sx={{
                            width: '100%', justifyContent: 'center', alignItems: 'stretch', display: 'flex',
                            flexDirection: 'row', gap: '10%'
                        }}>
                            <Box sx={{
                                width: '100%', flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex',
                                flexDirection: 'column', border: '1px solid', borderColor: Colors.grey_30, boxShadow: '0px 2px 4px 0px #ABBED133',
                                padding: '24px 32px', borderRadius: '4px', gap: '8px'
                            }}>
                                <img src={Sp} alt="achievement" style={{ width: '65px', height: '56px' }} />
                                <TypographySubHeading>150+</TypographySubHeading>
                                <TypographySubHeading>Service  providers</TypographySubHeading>
                                <TypographyNormal sx={{ textAlign: 'center', fontSize: '16px' }}>Over 150 service providers have found work through our platform</TypographyNormal>
                            </Box>

                            <Box sx={{
                                width: '100%', flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex',
                                flexDirection: 'column', border: '1px solid', borderColor: Colors.grey_30, boxShadow: '0px 2px 4px 0px #ABBED133',
                                padding: '24px 32px', borderRadius: '4px', gap: '8px',
                            }}>
                                <img src={User} alt="users" style={{ width: '65px', height: '56px' }} />
                                <TypographySubHeading> 500+</TypographySubHeading>
                                <TypographySubHeading> Users</TypographySubHeading>
                                <TypographyNormal sx={{ textAlign: 'center', fontSize: '16px' }}>Over 500 users have found local handymen in their area through our website</TypographyNormal>
                            </Box>

                            <Box sx={{
                                width: '100%', flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex',
                                flexDirection: 'column', border: '1px solid', borderColor: Colors.grey_30, boxShadow: '0px 2px 4px 0px #ABBED133',
                                padding: '24px 32px', borderRadius: '4px', gap: '8px',
                            }}>
                                <img src={Story} alt="successful story" style={{ width: '65px', height: '56px' }} />
                                <TypographySubHeading>100+</TypographySubHeading>
                                <TypographySubHeading>Successful stories</TypographySubHeading>
                                <TypographyNormal sx={{ textAlign: 'center', fontSize: '16px' }}>100+ successful stories have been reported in the last 6 months</TypographyNormal>
                            </Box>
                        </Box>
                    </Box>

                </Box>

                <Box sx={{
                    width: '100%', height: '95%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row',
                    paddingTop: '2.5%', paddingBottom: '4%', border:'1px solid', borderColor: Colors.grey_30
                }}>
                    <Box sx={{
                        width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                        flexDirection: 'column', maxWidth: '92.5%', gap: '32px'
                    }}>
                        <TypographyHeading>Discover skilled handymen near you in just a few clicks</TypographyHeading>
                        <Box sx={{
                            width: '100%', justifyContent: 'center', alignItems: 'stretch', display: 'flex',
                            flexDirection: 'row', gap: '78px'
                        }}>
                            <Box sx={{
                                width: '40%', height: '100%', justifyContent: 'center', alignItems: 'stretch',
                                display: 'flex', flexDirection: 'row',
                            }}>
                                <img src={DiscSkill} alt="skiled handyman" style={{ maxWidth: '90%', maxHeight: '96%' }} />
                            </Box>
                            <Box sx={{
                                width: '60%', flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex',
                                flexDirection: 'column', gap: '32px'
                            }}>
                                <TypographyNormal sx={{ lineHeight: '28px' }}>
                                    Finding a reliable handyman shouldn't be a hassle. Our website is designed to make your search quick and effortless. Whether you're dealing with home repairs, maintenance, or renovations, our platform connects you with skilled professionals in your area. Simply enter your location, and you'll be presented with a list of handymen who are ready to assist you. With detailed profiles and customer reviews, you can make an informed decision and find the perfect handyman for your needs.
                                </TypographyNormal>
                                <TypographyNormal sx={{ lineHeight: '28px' }}>
                                    With our easy-to-use search features, you can filter results based on your specific requirements. This ensures that you find a handyman who not only meets your immediate needs but also fits within your budget. Plus, our seamless communication tools allow you to directly message potential handymen, ask questions, and get quotes, making the entire process smooth and stress-free. Trust our platform to help you find the best local professionals who can tackle any home project with ease and efficiency.
                                </TypographyNormal>

                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{
                    width: '100%', height: '95%', justifyContent: 'center',
                    alignItems: 'center', display: 'flex', flexDirection: 'row',
                    paddingTop: '2.5%', paddingBottom: '2.5%',marginBottom: localStorage.getItem('userToken') ? 0 : '1.5%'
                }}>
                    <Box sx={{
                        width: '100%', height: '100%', justifyContent: 'center',
                        alignItems: 'center', display: 'flex', flexDirection: 'column', gap: '32px'
                    }}>
                        <TypographyHeading>Our happy clients say about us</TypographyHeading>
                        <Box sx={{
                            width: '100%', display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'center', 
                            maxWidth: '98%',flexDirection:'row' ,
                        }}>
                            <ArrowBackIosIcon
                                sx={{
                                    width: '32px', height: '32px', cursor: 'pointer', fontSize: '32px', color: Colors.black_70,
                                    visibility: ratingDetails.length > 1 && currentRatingIndex > 0 ? 'visible' : 'hidden',
                                    zIndex: 2,
                                }}
                                onClick={handlePrevClick}
                            />

                            <Box sx={{
                                display: 'flex', gap: '32px', flexDirection: 'row', alignItems: 'center',
                                 justifyContent: 'center', width:'100%',
                            }}>
                                {reviewsToShow.map((review, index) => (
                                    <Box key={index} sx={{
                                        width: 'calc(100% / 3 - 32px)', height: expandedReviews[index] ? 'fit-content' : '280px',
                                        border: '1px solid #DCDCDC', borderRadius: '4px', 
                                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)', padding: '32px', display: 'flex', flexDirection: 'column',
                                        justifyContent: 'center', alignItems: 'center', gap: '12px',
                                        paddingTop: '32px', paddingBottom: '32px', overflowY: expandedReviews[index] ? 'auto' : 'hidden',
                                        position: 'relative',
                                    }}>
                                        {console.log(review)}
                                         {review.profile_img ? (
                                                        <Avatar alt="profile"
                                                         src={review.profile_img}
                                                            sx={{ width: '72px', height: '72px' }}/>
                                                            ) : (
                                                            <Avatar alt="profile"
                                                                sx={{
                                                                ...stringAvatar(review.rating_name),
                                                                width: '72px', height:'72px', fontSize: '28px',bgcolor:'#503259'}}>
                                                                {stringAvatar(review.rating_name).children}
                                                            </Avatar>
                                                    )}      
                                        {review.rating_name && <TypographySubHeading sx={{ fontSize: '20px' }}>{review.rating_name}</TypographySubHeading>}
                                        {parseInt(review.rating_value) && (
                                            <Rating
                                                name="readOnly"
                                                readOnly
                                                size="large"
                                                value={parseInt(review.rating_value)}
                                                sx={{ color: '#FFC107' }}
                                                emptyIcon={<StarBorderIcon style={{ color: '#FFC107' }} fontSize="inherit" />}
                                            />
                                        )}
                                        {review.rating_comment && (
                                            <TypographyNormal
                                                sx={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    WebkitLineClamp: expandedReviews[index] ? 'none' : '3',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    textAlign: 'left',
                                                    lineHeight: '24px',
                                                    width: '100%',
                                                }}
                                            >
                                                {review.rating_comment}
                                            </TypographyNormal>
                                        )}
                                        {review.rating_comment && review.rating_comment.length > 200 && (
                                            <TypographyNormal
                                                sx={{
                                                    cursor: 'pointer', color: Colors.blue_10, textAlign: 'right', width: '100%',
                                                    position: expandedReviews[index] ? 'sticky' : 'relative', bottom: 0,
                                                }}
                                                onClick={() => handleToggleExpand(index)}
                                            >
                                                {expandedReviews[index] ? 'Read Less' : 'Read More'}
                                            </TypographyNormal>
                                        )}
                                    </Box>
                                ))}
                            </Box>

                            <ArrowForwardIosIcon
                                sx={{
                                    width: '32px', height: '32px', cursor: 'pointer', fontSize: '32px', color: Colors.black_70,
                                    visibility: ratingDetails.length > 1 && currentRatingIndex < ratingDetails.length - 1 ? 'visible' : 'hidden',
                                    zIndex: 2,
                                }}
                                onClick={handleNextClick}
                            />
                        </Box>
                    </Box>
                </Box>

                {localStorage.getItem('userToken') && (
                    <Box sx={{
                        width: '100%', height: '95%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row',
                        paddingTop: '2.5%', paddingBottom: '2.5%'
                    }}>
                         {!isSuccessMessage &&
                        <Box sx={{
                            width: '100%', height: '95%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                            flexDirection: 'column', maxWidth: '92.5%', gap: '24px', border: '1px solid', borderColor: Colors.black_60,
                            paddingTop: '2.5%', paddingBottom: '2.5%', borderRadius: '4px'
                        }}>
                            <TypographyHeading>Share your valuable feedback with us</TypographyHeading>
                            <Box sx={{
                                width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                                flexDirection: 'row', maxWidth: '70%',
                            }}>
                                <Box sx={{
                                    width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                                    flexDirection: 'column', gap: '16px',
                                }}>
                                    <TypographySubHeading>Rate us</TypographySubHeading>
                                    <Rating name="simple-controlled" size="large"
                                        value={ratingValue}
                                        sx={{
                                            '& .MuiRating-icon': { marginRight: '8px', color: Colors.black_90 },
                                            '& .MuiRating-icon:last-child': { marginRight: 0, },
                                            '& .MuiRating-iconFilled': {
                                                color: '#FFCE31', border: `0px solid ${Colors.black_90}`,
                                                borderRadius: '50%',
                                            },
                                            '& .MuiRating-iconEmpty': {
                                                color: Colors.black_90,
                                            },
                                            '& .MuiRating-icon:last-child': {
                                                marginRight: 0,
                                            },
                                        }}
                                        //onChange={handleRatingValueChange} 
                                        onChange={(event, newValue) => {                                          
                                              handleRatingValueChange(event, newValue);                                           
                                          }}
                                        />
                                    {ratingValueError && <TypographyError>{ratingValueError}</TypographyError>}

                                    <Box sx={{
                                        width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                                        flexDirection: 'column', gap: '16px', paddingTop: '24px'
                                    }}>

                                        <Box sx={{
                                            width: '90%', justifyContent: 'left', alignItems: 'left', display: 'flex',
                                            flexDirection: 'column', gap: '8px', flex: 1,
                                        }}>
                                            <TypographyNormal>Enter your name here</TypographyNormal>
                                            <TextfieldNormal value={ratingName}
                                                sx={{ height: '38px', backgroundColor: Colors.white_80,}}
                                                onChange={handleRatingNameChange} />
                                            {ratingNameError && <TypographyError>{ratingNameError}</TypographyError>}
                                        </Box>

                                        <Box sx={{
                                            width: '90%', justifyContent: 'left', alignItems: 'left', display: 'flex',
                                            flexDirection: 'column', gap: '8px', flex: 1,
                                        }}>
                                            <TypographyNormal>Enter your Comment here</TypographyNormal>
                                            <TextField value={ratingComment} multiline
                                                sx={{
                                                    height: '92px', borderRadius: '4px', backgroundColor: Colors.white_80,                                                   
                                                    '& .MuiInputBase-root': {
                                                        height: '100%', 
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        borderColor: Colors.black_80,
                                                        '&:hover fieldset': {
                                                            borderColor: Colors.black_80,
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: Colors.black_80,
                                                        },
                                                    },
                                                }}
                                                onChange={handleRatingCommentChange} />
                                            {ratingCommentError && <TypographyError>{ratingCommentError}</TypographyError>}
                                        </Box>

                                    </Box>

                                </Box>

                            </Box>
                            <Box sx={{
                                width: '100%', justifyContent: 'right', alignItems: 'center', display: 'flex',
                                flexDirection: 'row', gap: '16px', maxWidth: '63%'
                            }}>
                                <ButtonBlue sx={{ width: '20%' }} onClick={handleRatingSubmit}>Submit</ButtonBlue>
                            </Box>
                        </Box>}

                        {isSuccessMessage &&
                        <Box sx={{
                            width: '100%', height: '95%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                            flexDirection: 'column', maxWidth: '92.5%', gap: '24px', border: '1px solid', borderColor: Colors.black_60,
                            paddingTop: '2.5%', paddingBottom: '2.5%', borderRadius: '4px'
                        }}>                            
                            <Box sx={{
                                width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                                flexDirection: 'row', maxWidth: '70%',
                            }}>
                                <Box sx={{
                                    width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                                    flexDirection: 'column', minHeight:'420px',gap:{md:'24px', xs:'16px'}
                                }}>
                                    <TypographySubHeading sx={{fontSize:'32px', fontWeight:500}}>🎉 Thank you for your feedback!</TypographySubHeading>
                                    <TypographyNormal sx={{fontSize:'24px', lineHeight:'34px', textAlign:'center'}}>We appreciate your time in sharing your thoughts and rating. Your input helps us improve and provide a better experience.</TypographyNormal>

                                   

                                </Box>

                            </Box>
                           
                        </Box>}

                </Box>)}

    </Box>


    <Box sx={{
        width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: { xs: 'flex', md: 'none' },
        flexDirection: 'column', gap: '16px',
    }}>
        <Box sx={{
            width: '100%', height: '562px', backgroundImage: `url(${homebgmob})`, backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',backgroundPosition: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row',
        }}>
            <Box sx={{
                width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', maxWidth: '90%',
                display: 'flex', flexDirection: 'row',
            }}>
                <Box sx={{
                    width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'center', paddingTop: '36%',
                    display: 'flex', flexDirection: 'column', gap: '8px'
                }}>
                    <Typography sx={{
                        fontFamily: 'Roboto', fontWeight: 500, fontSize: '32px', color: Colors.white, lineHeight: '38px',
                        textAlign: 'center'
                    }}>
                        What services are you looking for?
                    </Typography>
                    <TypographyNormal sx={{ color: Colors.white, marginBottom:'6%' }}>Find the help you need, right in your neighborhood.
                    </TypographyNormal>
                    <SearchComponent></SearchComponent>
                </Box>

            </Box>

        </Box>

        <Box sx={{
            width: '100%', height: '95%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row',
            paddingTop: '2.5%', paddingBottom: '2.5%'
        }}>

            <Box sx={{
                width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                flexDirection: 'column', maxWidth: '92.5%', gap: '8px'
            }}>
                <TypographyHeading>Our achievements</TypographyHeading>
                <Box sx={{
                    width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                    flexDirection: 'column', gap: '8px', maxWidth: '77%',
                }}>
                    <Box sx={{
                        width: '100%', flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex',
                        flexDirection: 'column', border: '1px solid', borderColor: Colors.grey_30, boxShadow: '0px 2px 4px 0px #ABBED133',
                        padding: '24px 32px', borderRadius: '4px', gap: '8px'
                    }}>
                        <img src={Sp} alt="sp" style={{ width: '28px', height: '28px' }} />
                        <TypographySubHeading>150+</TypographySubHeading>
                        <TypographySubHeading>Service  providers</TypographySubHeading>
                        <TypographyNormal sx={{ textAlign: 'center' }}>Over 150 service providers have found work through our platform</TypographyNormal>
                    </Box>

                    <Box sx={{
                        width: '100%', flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex',
                        flexDirection: 'column', border: '1px solid', borderColor: Colors.grey_30, boxShadow: '0px 2px 4px 0px #ABBED133',
                        padding: '24px 32px', borderRadius: '4px', gap: '4px',
                    }}>
                        <img src={User} alt="users" style={{ width: '28px', height: '28px' }} />
                        <TypographySubHeading> 500+</TypographySubHeading>
                        <TypographySubHeading> Users</TypographySubHeading>
                        <TypographyNormal sx={{ textAlign: 'center' }}>Over 500 users have found local handymen in their area through our website</TypographyNormal>
                    </Box>

                    <Box sx={{
                        width: '100%', flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex',
                        flexDirection: 'column', border: '1px solid', borderColor: Colors.grey_30, boxShadow: '0px 2px 4px 0px #ABBED133',
                        padding: '24px 32px', borderRadius: '4px', gap: '4px',
                    }}>
                        <img src={Story} alt="success" style={{ width: '28px', height: '28px' }} />
                        <TypographySubHeading>100+</TypographySubHeading>
                        <TypographySubHeading>Successful stories</TypographySubHeading>
                        <TypographyNormal sx={{ textAlign: 'center' }}>100+ successful stories have been reported in the last 6 months</TypographyNormal>
                    </Box>
                </Box>
            </Box>

        </Box>

        <Box sx={{
            width: '100%', height: '95%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row',
            paddingTop: '2.5%', paddingBottom: '2.5%'
        }}>
            <Box sx={{
                width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                flexDirection: 'column', maxWidth: '92.5%', gap: '32px'
            }}>
                <TypographyHeading sx={{ textAlign: 'center' }}>Discover skilled handymen near you in just a few clicks</TypographyHeading>
                <Box sx={{
                    width: '100%', justifyContent: 'center', alignItems: 'stretch', display: 'flex',
                    flexDirection: 'column', gap: '20px', maxWidth: '92.5%',
                }}>
                    <Box sx={{
                        width: '100%', height: '100%', justifyContent: 'center', alignItems: 'stretch',
                        display: 'flex', flexDirection: 'row',
                    }}>
                        <img src={DiscSkill} alt="skill" style={{ maxWidth: '90%', maxHeight: '96%' }} />
                    </Box>
                    <Box sx={{
                        width: '100%', flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex',
                        flexDirection: 'column', gap: '16px'
                    }}>
                        <TypographyNormal>
                            Finding a reliable handyman shouldn't be a hassle. Our website is designed to make your search quick and effortless. Whether you're dealing with home repairs, maintenance, or renovations, our platform connects you with skilled professionals in your area. Simply enter your location, and you'll be presented with a list of handymen who are ready to assist you. With detailed profiles and customer reviews, you can make an informed decision and find the perfect handyman for your needs.
                        </TypographyNormal>
                        <TypographyNormal>
                            With our easy-to-use search features, you can filter results based on your specific requirements. This ensures that you find a handyman who not only meets your immediate needs but also fits within your budget. Plus, our seamless communication tools allow you to directly message potential handymen, ask questions, and get quotes, making the entire process smooth and stress-free. Trust our platform to help you find the best local professionals who can tackle any home project with ease and efficiency.
                        </TypographyNormal>

                    </Box>
                </Box>
            </Box>
        </Box>

        <Box sx={{
            width: '100%', height: '95%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row',
            paddingTop: '2.5%', paddingBottom: '2.5%', marginBottom: localStorage.getItem('userToken') ? 0 : '7%'
        }}>
            <Box sx={{
                width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                flexDirection: 'column', maxWidth: '92.5%', gap: '16px'
            }}>
                <TypographyHeading sx={{ textAlign: 'center' }}>Our happy clients say about us</TypographyHeading>
                <Box sx={{
                    width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                    flexDirection: 'row', gap: '16px',
                }}>
                    <ArrowBackIosIcon
                        sx={{
                            cursor: 'pointer', fontSize: '24px', color: Colors.blue_10,
                            visibility: ratingDetails.length > 1 && currentRatingIndex > 0 ? 'visible' : 'hidden'
                        }}
                        onClick={handlePrevClick} />
                    <Box sx={{
                        width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                        flexDirection: 'column', gap: '8px', border: '1px solid', borderColor: Colors.black_60,
                        boxShadow: '-1px 4px 9px 0px #BDA8A81A, -6px 16px 17px 0px #BDA8A817, -13px 35px 23px 0px #BDA8A80D, -24px 62px 27px 0px #BDA8A803, -37px 97px 29px 0px #BDA8A800',
                        padding: '4% 8%', borderRadius: '4px', maxWidth: '90%', maxHeight: 'fit-content', minHeight: '250px',
                    }}>
                        {currentRatingDetails.rating_name && <Avatar
                            {...stringAvatar(currentRatingDetails.rating_name)}
                            sx={{ width: '72px', height: '72px', fontSize: '28px', bgcolor: currentRatingDetails.rating_name ? stringToColor(currentRatingDetails.rating_name) : '#4C50AE' }}
                        />}
                        {currentRatingDetails.rating_name && <TypographySubHeading>{currentRatingDetails.rating_name}
                        </TypographySubHeading>}
                        {parseInt(currentRatingDetails.rating_value) && <Rating name="readOnly" readOnly size="large"
                            value={parseInt(currentRatingDetails.rating_value)}
                            sx={{ color: '#ffc107' }}
                            emptyIcon={<StarBorderIcon style={{ color: '#ffc107' }} fontSize="inherit" />} />}
                        {currentRatingDetails.rating_comment && <TypographyNormal
                            sx={{
                                display: '-webkit-box', WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 'none', overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>{currentRatingDetails.rating_comment}</TypographyNormal>}

                    </Box>

                    <ArrowForwardIosIcon
                        sx={{
                            cursor: 'pointer', fontSize: '24px', color: Colors.blue_10,
                            visibility: ratingDetails.length > 1 && currentRatingIndex < ratingDetails.length - 1 ? 'visible' : 'hidden'
                        }}
                        onClick={handleNextClick}
                    />
                </Box>
            </Box>
        </Box>

        {localStorage.getItem('userToken') && (
            <Box sx={{
                width: '100%', height: '95%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row',
                paddingTop: '2.5%', paddingBottom: '2.5%', marginBottom:'5%'
            }}>
               { !isSuccessMessage && <Box sx={{
                    width: '100%', height: '95%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                    flexDirection: 'column', maxWidth: '90%', gap: '12px', border: '1px solid', borderColor: Colors.black_60,
                    paddingTop: '2.5%', paddingBottom: '2.5%', borderRadius: '4px'
                }}>
                    <TypographyHeading sx={{ textAlign: 'center' }}>Share your valuable feedback with us</TypographyHeading>
                    <Box sx={{
                        width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                        flexDirection: 'row', maxWidth: '95%',
                    }}>
                        <Box sx={{
                            width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                            flexDirection: 'column', gap: '8px',
                        }}>
                            <TypographySubHeading>Rate us</TypographySubHeading>
                            <Rating name="simple-controlled" size="large"
                                value={ratingValue}
                                sx={{
                                    '& .MuiRating-icon': { marginRight: '8px', },
                                    '& .MuiRating-icon:last-child': { marginRight: 0, },
                                }}
                                onChange={handleRatingValueChange} />
                            {ratingValueError && <TypographyError>{ratingValueError}</TypographyError>}

                            <Box sx={{
                                width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                                flexDirection: 'column', gap: '16px', paddingTop: '24px'
                            }}>

                                <Box sx={{
                                    width: '90%', justifyContent: 'left', alignItems: 'left', display: 'flex',
                                    flexDirection: 'column', gap: '8px', flex: 1,
                                }}>
                                    <TypographyNormal>Enter your name here</TypographyNormal>
                                    <TextField value={ratingName}
                                        sx={{
                                            height: '38px', borderRadius: '4px', backgroundColor: Colors.white_80,
                                            border: '1px solid ', borderColor: Colors.black_60,
                                            '& .MuiInputBase-root': {
                                                height: '100%', backgroundColor: Colors.white_80,
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                borderColor: Colors.black_80,
                                                '&:hover fieldset': {
                                                    borderColor: Colors.black_80,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: Colors.black_80,
                                                },
                                            },
                                        }}
                                        onChange={handleRatingNameChange} />
                                    {ratingNameError && <TypographyError>{ratingNameError}</TypographyError>}
                                </Box>

                                <Box sx={{
                                    width: '90%', justifyContent: 'left', alignItems: 'left', display: 'flex',
                                    flexDirection: 'column', gap: '8px', flex: 1,
                                }}>
                                    <TypographyNormal>Enter your Commend here</TypographyNormal>
                                    <TextField value={ratingComment}
                                        sx={{
                                            height: '92px', borderRadius: '4px', backgroundColor: Colors.white_80,
                                            border: '1px solid ', borderColor: Colors.black_60,
                                            '& .MuiInputBase-root': {
                                                height: '100%', backgroundColor: Colors.white_80,
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                borderColor: Colors.black_80,
                                                '&:hover fieldset': {
                                                    borderColor: Colors.black_80,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: Colors.black_80,
                                                },
                                            },
                                        }}
                                        onChange={handleRatingCommentChange} />
                                    {ratingCommentError && <TypographyError>{ratingCommentError}</TypographyError>}
                                </Box>
                            </Box>
                            <Box sx={{
                                width: '100%', justifyContent: 'right', alignItems: 'center', display: 'flex',
                                flexDirection: 'row', gap: '16px', paddingTop: '8px', maxWidth:'90%'
                            }}>
                                <ButtonBlue sx={{ width: '20%' }} onClick={handleRatingSubmit}>Submit</ButtonBlue>
                            </Box>
                        </Box>
                    </Box>
                </Box>}

                {isSuccessMessage && <Box sx={{
                    width: '100%', height: '95%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                    flexDirection: 'column',gap: '12px', border: '1px solid', borderColor: Colors.black_60,
                    paddingTop: '2.5%', paddingBottom: '2.5%', borderRadius: '4px', minHeight:'390px',  maxWidth: '90%',
                }}>
                     <Box sx={{
                            width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                            flexDirection: 'column', maxWidth: '95%',
                        }}>
                    <TypographySubHeading sx={{fontSize:'16px', fontWeight:500, lineHeight:'34px',}}>🎉 Thank you for your feedback!</TypographySubHeading>
                   <TypographyNormal sx={{fontSize:'12px', lineHeight:'18px', textAlign:'center'}}>We appreciate your time in sharing your thoughts and rating. Your input helps us improve and provide a better experience.</TypographyNormal>
                   </Box>
                   
                </Box>}
            </Box>)}


    </Box>

            <Footer currentPageFooter={"Home"}></Footer>

        </Grid>
    )
} export default Home;