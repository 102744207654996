

import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import Colors from '../Components/Colors';
import TypographyNormal from '../Components/TypographyNormal';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import { API_URL } from '../Components/Constants';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import logo from '../Images/logo.png'
import { useNavigate } from 'react-router-dom';
import { Grid , TextField} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import ButtonWhite from './ButtonWhite';
import ButtonBlue from './ButtonBlue';



function OperationTeamHeader() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isSignOutPopUp, setIsSignOutPopUp] = useState(false);
  const [operationTeamName, setOperationTeamName] = useState("");
  const [searchGroupChar, setSearchGroupChar] = useState('');

  const handleSignOutMenuItemClick = (value) => {
    if (value === "signout") {
      setIsSignOutPopUp(true);
    }
    setShowDropdown(false);
  }
  const navigate = useNavigate();
  const handleSignOutPopUpOkButtonClick = async () => {
    localStorage.removeItem('operationTeamToken');
    navigate('/operation-team-login', { replace: true }); 
  };

  const handleSignOutPopUpCancelButtonClick = () => {
    setIsSignOutPopUp(false);

  };

  const profilePicRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      profilePicRef.current &&
      !profilePicRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);


  const fetchOperationTeamDetails = async () => {
    const operationTeamToken = localStorage.getItem("operationTeamToken")
    try {
      const response = await axios.post(`${API_URL}/select_operation_team_details_with_token`,
    { operationTeamToken: operationTeamToken, });
      console.log(response.data.result.operation_team_member)
      setOperationTeamName(response.data.result.operation_team_member);      
    } catch (error) {
      console.error('Error fetching service categories:', error);
    }
  };

  useEffect(() => {
    fetchOperationTeamDetails();

  }, []);
  

    return (
      <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px', }}> 

       <AppBar position="static" sx={{
      height: { xs: '48px', md: '86px' }, justifyContent: 'center',
      boxShadow: 'none', background:  'white' , borderBottom:'1px solid', borderColor: Colors.grey_30
    }}>
      <Container maxWidth="94%" sx={{ marginLeft: '0px', height: '86px',   }}>
        <Toolbar disableGutters sx={{ height: '100%', width: '100%' ,gap:'54px'}}>
        <Box sx={{  width: '70%', display: 'flex' , height: '100%', justifyContent: 'center',
                        alignItems: 'left',flexDirection:'column', gap:'8px', 
                    }}>
          <Box sx={{ width:  '220px' , height:  '38px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={logo} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
          </Box>   
          </Box>       
          <Box sx={{
                        width: '25%', display: 'flex' , height: '100%', justifyContent: 'center',
                        alignItems: 'center',flexDirection:'column', gap:'8px', 
                    }}>
                        

             </Box>
          <Box sx={{
            display: "flex", width:  '5%'  , flexDirection: 'row', 
            justifyContent: 'left', alignItems: 'center', gap: '12px',
          }}>
            <TypographyNormal  onClick={() => setShowDropdown(!showDropdown)} sx={{ fontSize: '14px', color: Colors.black_80,  }}>                                        
                                        {operationTeamName}
                                    </TypographyNormal>
           
            {showDropdown ? (
                                        < KeyboardArrowUpIcon
                                            sx={{ display:  'flex', width: '24px', color: Colors.black_80 }}
                                            onClick={() => setShowDropdown(!showDropdown)}

                                        />
                                    ) : (
                                        <KeyboardArrowDownIcon
                                            sx={{ display: 'flex', width: '24px', color: Colors.black_80 }}
                                            onClick={() => setShowDropdown(!showDropdown)}
                                        />
                                    )}

          
          </Box>

          {showDropdown && (

            <Box ref={dropdownRef} className="dropdown__menu" sx={{
              position: 'absolute', right: '0px', top: '65px', fontFamily: 'Roboto', fontSize: '16px', fontWeight: '400',
              lineHeight: '20px', width: '170px', background: Colors.white, borderRadius: '8px', boxShadow: '0px 4px 4px 0px #00000040', zIndex: 1,
              color: 'white', padding: '8px', border: '1px solid', borderColor: Colors.grey_30
            }}>


              <style>
                {`
     .dropdown__menu .MuiMenuItem-root:hover {
       background-color: #415FE2;
       color: #FFFFFF !important;
     }
     .dropdown-menu__triangle {
      position: absolute;
      top: -10px;
      right: 10px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
      border-top: 10px solid ${Colors.grey_30};
      box-shadow: 0px 4px 4px 0px #00000040;
    }
   
   `}
              </style>

              <MenuItem onClick={() => handleSignOutMenuItemClick("signout")} value="option2" style={{
                fontFamily: 'Roboto', fontSize: '16px', color: Colors.black_80,
                fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
              }}>
                Sign out
              </MenuItem>            
            </Box>

          )}
          {isSignOutPopUp &&
            <Box sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '2',

            }}
            >
              <Box sx={{
                width: '100%', height: '100%', gap: '5px',
                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                display: 'flex', flexDirection: 'column',
              }}>
                <Box sx={{
                  width: { md: '375px', sm: '380px', xs: '85%' }, height: '36px',
                  justifyContent: 'right', alignItems: 'right', display: 'flex',
                }}>
                  <IconButton onClick={handleSignOutPopUpCancelButtonClick} sx={{
                    width: '36px', height: '36px',

                  }} >
                    <CancelIcon sx={{
                      width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                        color: Colors.close,
                      },
                    }} ></CancelIcon>
                  </IconButton>
                </Box>
                <Box
                  sx={{

                    width: { md: '375px', sm: '380px', xs: '85%' },
                    height: 'fit-content',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #FFFFFF',
                    borderRadius: '28px ',
                    boxShadow: 'none',
                    boxSizing: 'border-box',
                    zIndex: 999,
                    padding: '35px',
                    alignItems: 'center',

                  }}
                >

                  <Box sx={{
                    display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', alignItems: 'center',
                    justifyContent: 'center', alignContent: 'center', height: '100%'
                  }}>
                    <Box sx={{
                      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                      alignContent: 'center', width: '100%', gap: '16px'
                    }}>
                      <TypographyNormal sx={{ fontSize: '18px', color: '#000000', fontWeight: 600 }}>
                        Sign out</TypographyNormal>

                      <TypographyNormal sx={{ fontSize: '16px', color: Colors.black_70 }}>
                        Are you sure you would like to sign out your account?</TypographyNormal>

                    </Box>


                    <Box sx={{
                      display: 'flex', flexDirection: 'row', gap: '16px', marginTop: '16px', justifyContent: 'center',
                      alignContent: 'center', alignItems: 'center', width: '100%'
                    }}>
                      <ButtonWhite onClick={handleSignOutPopUpCancelButtonClick}>  Cancel </ButtonWhite>
                      <ButtonBlue onClick={handleSignOutPopUpOkButtonClick}> Sign out </ButtonBlue>
                     

                    </Box>


                  </Box>
                </Box>
              </Box>
            </Box>}
         
        </Toolbar>
      </Container>
       </AppBar>

       </Grid>
    )
} export default OperationTeamHeader