
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button, IconButton , InputLabel, Breadcrumbs, Select, Checkbox, Switch, Divider, MenuItem} from "@mui/material";
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading  from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Colors from '../Components/Colors';
import TextfieldNormal from '../Components/TextfieldNormal';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonBlue from '../Components/ButtonBlue';
import TypographyError from '../Components/TypographyError';
import axios from "axios";
import { API_URL } from '../Components/Constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import ButtonWhite from '../Components/ButtonWhite';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { IMG_URL } from '../Components/Constants';
import SpSidebar from '../Components/SpSidebar';
import { useLocation } from 'react-router-dom';
import AssociateHeader from '../Components/AssociateHeader';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FormControl from '@mui/material/FormControl';
import CloseOutlined from '@mui/icons-material/CloseOutlined';

const colors = ['#503259', '#4C50AE', '#FF7D6A', '#B07CC6', '#20C925'];

  function getAvatarColor(index) {
  return colors[index % colors.length];
  }

  function stringAvatar(name = '', index) {
  const initials = name[0].toUpperCase() || '';
  const bgColor = getAvatarColor(index);
  return {
      sx: {
      bgcolor: bgColor,
      width: '64px',
      height: '64px',
      fontSize: '56px',
      color: '#fff',
      },
      children: initials,
  };
  }



function AssignSupportTeam() {
  const [supportTeams, setSupportTeams] = useState([]); // Teams and their assigned SPs
  const [unassignedSPs, setUnassignedSPs] = useState([]); // Unassigned service providers
  const [selectedTeam, setSelectedTeam] = useState(''); // Selected team member
  const [selectedSP, setSelectedSP] = useState(null); // Selected service provider
  const [assignedSPs, setAssignedSPs] = useState({}); // Store assigned SPs by team member ID
  const [allSPs, setallSPs] = useState([]);
 
    const fetchSupportTeamDetails = async () => {
      try {
        const response = await axios.get(`${API_URL}/select_support_team_details`);
        console.log(response.data.result)
        setSupportTeams(response.data.result);
        initializeAssignedSPs(response.data.result);
      } catch (error) {
        console.error('Error fetching support team data:', error);
      }
    };

    const fetchServiceProviders = async () => {
      try {
        const response = await axios.post(`${API_URL}/associate_sp_details_with_tab`, { status: 'New Onboarding' });
       console.log(response.data.data)
        setallSPs(response.data.data || [])
        

    // Extract assigned service provider IDs from supportTeams
    
       
      } catch (error) {
        console.error('Error fetching service providers:', error);
      }
    };

  useEffect(() => {
    fetchSupportTeamDetails();
    fetchServiceProviders();
  }, []);

  // const initializeAssignedSPs = (teams) => {
  //   const spMap = {};
  //   console.log(teams)
  //   teams.forEach(team => {
  //     spMap[team.operation_team_id] = team.service_provider_info || [];
  //   });
  //   setAssignedSPs(spMap);
  // };

  const initializeAssignedSPs = (teams) => {
    const spMap = {};
  
    teams.forEach(team => {
      // Filter service_provider_info items where service_provider_id is not null
      const assignedProviders = team.service_provider_info.filter(sp => sp.service_provider_id !== null);
  
      // If assignedProviders is empty, it won't add unnecessary entries in the map
      spMap[team.operation_team_id] = assignedProviders.length > 0 ? assignedProviders : [];
    });
  
    setAssignedSPs(spMap);
  };
  

  const handleAssignButtonClick = async() => {
    console.log("Selected Service Provider ID:", selectedSP);
    console.log("Selected Team ID:", selectedTeam); 
  
    try {
      const response = await axios.post(`${API_URL}/assign_sp_to_operation_team`, {
        tocken:  localStorage.getItem("associateToken"),
        assignedSpIds: selectedSP,
        teamMemberId: selectedTeam,
      });
      if (response.data.success) {
        fetchSupportTeamDetails()
        fetchServiceProviders()
      }

    } catch (error) {

      console.error('Error:', error.message);
    }


      setSelectedSP(null);
      setSelectedTeam(null);
    
  };
  
  
  

  const handleUnassignButtonClick = async(serviceProviderId, teamId) => {
    console.log(serviceProviderId, teamId)
    try {
      const response = await axios.post(`${API_URL}/unassign_sp_to_operation_team`, {
        tocken:  localStorage.getItem("associateToken"),
        assignedSpIds: serviceProviderId,
        teamMemberId: teamId,
      });
      if (response.data.success) {
        fetchSupportTeamDetails()
        fetchServiceProviders()
      }

    } catch (error) {

      console.error('Error:', error.message);
    }
  };
  
  useEffect(() => {
    if ( supportTeams.length > 0) {
      const assignedServiceProviderIds = new Set(
        supportTeams.flatMap(team => 
          team.service_provider_info.map(spInfo => spInfo.service_provider_id)
        )
      );
  
      // Filter unassigned service providers by checking if their ID is not in the assignedServiceProviderIds set
      const unassigned = (allSPs || []).filter(sp => 
        !assignedServiceProviderIds.has(sp.serviceProvider.service_provider_id)
      );
  
      console.log(unassigned);
      setUnassignedSPs(unassigned);
}
  }, [supportTeams, allSPs]);
  
  useEffect(() => {
    console.log("Unassigned Service Providers:", unassignedSPs);
  }, [unassignedSPs]);
  

  return (



<Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: '24px',
      justifyContent: 'flex-start',
      height: '100%',
      alignItems: 'center',
      minHeight: '500px',
    }}
  >
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {/* Header for Unassigned Service Providers */}
            <TableCell>Unassigned Service Providers</TableCell>
            {/* Header for Action Column */}
            <TableCell>Action</TableCell>
            {/* Headers for Team Members */}
            {supportTeams.map((team) => (
              <TableCell key={team.operation_team_id}>
                {team.operation_team_member}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {Array.from(
            { length: Math.max(unassignedSPs.length, ...supportTeams.map(team => assignedSPs[team.operation_team_id]?.length || 0)) }
          ).map((_, rowIndex) => (
            <TableRow key={rowIndex}>
              {/* Unassigned Service Providers */}
              <TableCell>
                {unassignedSPs[rowIndex] && (
                  <Box
                    onClick={() => {
                      setSelectedSP(unassignedSPs[rowIndex].serviceProvider.service_provider_id);
                    }}
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      padding: '8px',
                      borderRadius: '4px',
                      backgroundColor: selectedSP === unassignedSPs[rowIndex].serviceProvider.service_provider_id ? '#e0e0e0' : 'transparent',
                    }}
                  >
                    {console.log("Unassigned Service Providers:", unassignedSPs[rowIndex])}
                    {unassignedSPs[rowIndex]?.serviceProvider?.profile_image ? (
                      <Avatar
                        alt="Profile"
                        src={IMG_URL + unassignedSPs[rowIndex].serviceProvider.profile_image}
                        sx={{
                          width: { md: '40px', sm: '40px', xs: '40px' },
                          minWidth: '40px',
                          height: { md: '40px', sm: '40px', xs: '40px' },
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: { md: '40px', sm: '40px', xs: '40px' },
                          height: { md: '40px', sm: '40px', xs: '40px' },
                          minWidth: '40px',
                          borderRadius: '50%',
                          backgroundColor: colors[rowIndex % colors.length],
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: { xs: '24px', md: '26px' },
                          fontWeight: 'bold',
                          color: Colors.white,
                          textAlign: 'center',
                          lineHeight: '40px',
                        }}
                      >
                        {unassignedSPs[rowIndex]?.serviceProvider?.business_name
                          ? unassignedSPs[rowIndex].serviceProvider.business_name[0].toUpperCase()
                          : ''}
                      </Box>
                    )}
                    <Box
                      sx={{
                        width: 'calc(100% - 40px)',
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: 'calc(100% - 48px)',
                        alignItems: 'left',
                        justifyContent: 'center',
                      }}
                    >
                      <TypographyNormal
                        sx={{
                          fontSize: '16px',
                          color: Colors.black_80,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {unassignedSPs[rowIndex]?.serviceProvider?.business_name}
                      </TypographyNormal>
                      <TypographyNormal
                        sx={{
                          fontSize: '14px',
                          color: Colors.grey_60,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          textTransform: 'none',
                        }}
                      >
                        {`${(unassignedSPs[rowIndex]?.serviceProvider?.service_details || [])
                          .map((detail) => detail.professional_name)
                          .join(', ')}/${unassignedSPs[rowIndex]?.serviceProvider?.city || ''}`}
                      </TypographyNormal>
                      <TypographyNormal
                        sx={{
                          fontSize: '14px',
                          color: Colors.grey_60,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {unassignedSPs[rowIndex]?.serviceProvider?.country_code &&
                        unassignedSPs[rowIndex]?.serviceProvider?.phone_number
                          ? `+${unassignedSPs[rowIndex].serviceProvider.country_code} ${unassignedSPs[rowIndex].serviceProvider.phone_number}`
                          : 'Phone number unavailable'}
                      </TypographyNormal>
                    </Box>
                  </Box>
                )}
              </TableCell>

              {/* Action Column */}
              <TableCell>
                {rowIndex === 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                         
                      <Select sx={{height:'38px', width:'240px'}}
                       placeholder = "choose your team"
                        value={selectedTeam}
                        onChange={(e) => {
                          setSelectedTeam(e.target.value);
                        }} >
                        {supportTeams.map((team) => (
                          <MenuItem key={team.operation_team_id} value={team.operation_team_id}>
                            {team.operation_team_member}
                          </MenuItem>
                        ))}
                      </Select> 
                    
                    <ButtonBlue
                      
                      onClick={handleAssignButtonClick} // <-- Correctly passing the function as a callback
                      sx={{ marginLeft: '8px' , width:'fit-content'}}
                    >
                      Assign
                    </ButtonBlue>
                  </Box>
                )}
              </TableCell>

              {/* Assigned Service Providers for each Team */}
              {supportTeams.map((team) => (
                <TableCell key={team.operation_team_id}>
                  {console.log(assignedSPs[team.operation_team_id])}
                  {assignedSPs[team.operation_team_id]?.[rowIndex] && (
  
  <Box
  sx={{
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #313131',
    backgroundColor: selectedSP === assignedSPs[team.operation_team_id][rowIndex]?.service_provider_id ? '#e0e0e0' : 'transparent',
    position: 'relative', // Ensure relative positioning for the close button
  }}
  onClick={() => {
    setSelectedSP(assignedSPs[team.operation_team_id][rowIndex]?.service_provider_id);
  }}
>
  {console.log("Assigned Service Providers:", assignedSPs[team.operation_team_id][rowIndex])}
  
  {/* Avatar Section */}
  {assignedSPs[team.operation_team_id][rowIndex]?.profile_image ? (
    <Avatar
      alt="Profile"
      src={IMG_URL + assignedSPs[team.operation_team_id][rowIndex]?.profile_image}
      sx={{
        width: { md: '40px', sm: '40px', xs: '40px' },
        minWidth: '40px',
        height: { md: '40px', sm: '40px', xs: '40px' },
      }}
    />
  ) : (
    <Box
      sx={{
        width: { md: '40px', sm: '40px', xs: '40px' },
        height: { md: '40px', sm: '40px', xs: '40px' },
        minWidth: '40px',
        borderRadius: '50%',
        backgroundColor: colors[rowIndex % colors.length],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: { xs: '24px', md: '26px' },
        fontWeight: 'bold',
        color: Colors.white,
        textAlign: 'center',
        lineHeight: '40px',
      }}
    >
      {assignedSPs[team.operation_team_id][rowIndex]?.business_name
        ? assignedSPs[team.operation_team_id][rowIndex]?.business_name[0].toUpperCase()
        : ''}
    </Box>
  )}

  {/* Business Information */}
  <Box
    sx={{
      width: 'calc(100% - 40px)',
      display: 'flex', gap:'4px',
      flexDirection: 'column',
      maxWidth: 'calc(100% - 48px)',
      alignItems: 'left',
      justifyContent: 'center',
    }}
  >
    <TypographyNormal
      sx={{
        fontSize: '16px',
        color: Colors.black_80,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {assignedSPs[team.operation_team_id][rowIndex]?.business_name}
    </TypographyNormal>
    {console.log(assignedSPs)}
    <TypographyNormal
      sx={{
        fontSize: '14px',
        color: Colors.black_80,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
     + {assignedSPs[team.operation_team_id][rowIndex]?.sp_country_code} {assignedSPs[team.operation_team_id][rowIndex]?.sp_phone_number}
    </TypographyNormal>
    <TypographyNormal
      sx={{
        fontSize: '14px',
        color: Colors.black_80,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
    {assignedSPs[team.operation_team_id][rowIndex]?.city}/ {assignedSPs[team.operation_team_id][rowIndex]?.province}/ {assignedSPs[team.operation_team_id][rowIndex]?.country}
    </TypographyNormal>
  </Box>

  {/* Close Button */}
  <IconButton
    onClick={(e) => {
      e.stopPropagation(); // Prevent triggering the outer box click
      handleUnassignButtonClick(
        assignedSPs[team.operation_team_id][rowIndex]?.service_provider_id,
        team.operation_team_id
      );
    }}
    sx={{
      position: 'absolute',
      top: '5px',
      right: '4px',
      
      color: Colors.black_80,
    }}
  >
    <CloseOutlined />
  </IconButton>
</Box>

)}

                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
</Grid>

  



  );
}

export default AssignSupportTeam;
