import { Grid, TextField, InputAdornment, Box, IconButton, useMediaQuery, useTheme } from "@mui/material"
import React, { useEffect, useState, useRef } from 'react';
import Colors from "./Colors"
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TypographySubHeading from "./TypographySubHeading";
import TypographyNormal from "./TypographyNormal";
import TextfieldNormal from "./TextfieldNormal";
import { GoogleMap, useLoadScript, MarkerF, Circle, Autocomplete } from '@react-google-maps/api';
import ButtonBlue from "./ButtonBlue";
import axios from "axios";
import { API_URL } from './Constants';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import { IMG_URL } from "./Constants";



const libraries = ['places'];

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const colors = ['#503259', '#4C50AE', '#FF7D6A', '#B07CC6', '#20C925'];

function getAvatarColor(index) {
  return colors[index % colors.length];
}

function stringAvatar(name = '', index) {
  const initials = name[0]?.toUpperCase() || '';
  const bgColor = getAvatarColor(index);
  return {
    sx: {
      bgcolor: bgColor,
      width: '19px',
      height: '19px',
      fontSize: '12px',
      color: '#fff',
    },
    children: initials,
  };
}



function SearchComponentHeader(props) {
  const { isHeader, searchValue } = props
  console.log(isHeader)
  const [location, setLocation] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [search, setSearch] = useState('');
  const [locationPopUp, setLocationPopUp] = useState(false);
  const [selectedLatLng, setSelectedLatLng] = useState([43.732506485371125, -79.76642535889613]);
  const [autocomplete, setAutocomplete] = useState(null);
  const [isPlaceEdit, setIsPlaceEdit] = useState(false);
  const [isSearchEdit, setIsSearchEdit] = useState(false);
  const [isSearchResult, setIsSearchResult] = useState(false);
  const [isSearchResultMob, setIsSearchResultMob] = useState(false);
  const [searchResultServices, setSearchResultServices] = useState([]);
  const [searchResultTypes, setSearchResultTypes] = useState([]);
  const [searchResultSP, setSearchResultSP] = useState([]);
  const [searchResultSPCode, setSearchResultSPCode] = useState([]);
  const [choosedLocationmap, setChoosedLocationmap] = useState(null);
  const [lastSelectedLocation, setLastSelectedLocation] = useState(null);
  const [choosedLocationpopup, setChoosedLocationpopup] = useState(null);
  const [choosedLocation, setChoosedLocation] = useState(null);

  const boxRefhdr = useRef(null);
  const boxRefhdrMob = useRef(null);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    function handleClickOutside(event) {
      if (boxRefhdr.current && !boxRefhdr.current.contains(event.target)) {
        setIsSearchResult(false); // Hide the box when clicking outside
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [boxRefhdr, setIsSearchResult]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (boxRefhdrMob.current && !boxRefhdrMob.current.contains(event.target)) {
        setIsSearchResultMob(false); // Hide the box when clicking outside
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [boxRefhdrMob, setIsSearchResultMob]);

  useEffect(() => {
    if (searchValue !== undefined || searchValue !== '') {
      setSearch(searchValue); // Update state inside useEffect
    }
  }, [searchValue]);

  const aggregateServices = (data) => {
    return data.reduce((acc, curr) => {
      const { service_provider_id, business_name, service_provider_code, profile_image } = curr;

      if (!acc[service_provider_id]) {
        acc[service_provider_id] = {
          business_name,
          service_provider_code,
          profile_image,
          service_provider_id,
          services: curr.services,
          services_id: curr.services_id
        };
      } else {
        acc[service_provider_id].services += `, ${curr.services}`;
        acc[service_provider_id].services_id += `, ${curr.services_id}`;
      }

      return acc;
    }, {});
  };

  const handleSearchChange = async (event) => {
    setSearch(event.target.value);
    //  const value = event.target.value.trim(); // Trim whitespace from the input
    //  setSearch(value);       
    try {
      const response = await axios.post(`${API_URL}/select_search`,
        {
          searchValue: event.target.value,
        });
      setSearchResultServices(response.data.serviceResult)
      setSearchResultTypes(response.data.typesResult)
      setSearchResultSP(response.data.SpResult)
      console.log(response.data.SpCodeResult)
      const aggregatedSpCodeResult = Object.values(aggregateServices(response.data.SpCodeResult));
      setSearchResultSPCode(aggregatedSpCodeResult)
      console.log(response.data.SpResult)
      if (response.data.serviceResult.length > 0 || response.data.typesResult.length > 0 || response.data.SpResult.length > 0 || response.data.SpCodeResult.length > 0) {
        setIsSearchResult(true);
        setIsSearchResultMob(true)
      } else {
        setIsSearchResult(false);
        setIsSearchResultMob(false)
      }
      if (response.data.success === true && Array.isArray(response.data.serviceResult) && response.data.serviceResult.length === 0 &&
        Array.isArray(response.data.typesResult) && response.data.typesResult.length === 0 &&
        Array.isArray(response.data.SpResult) && response.data.SpResult.length === 0 &&
        Array.isArray(response.data.SpCodeResult) && response.data.SpCodeResult.length === 0) {
        setIsSearchEdit(true)
        setIsSearchResult(true);
        setIsSearchResultMob(true)
      }
      else {
        setIsSearchEdit(false)
      }
    } catch (error) {
      console.error('Error fetching service categories:', error);
    }

  };

  useEffect(() => {         
    console.log(location) 
}, [location]);

  const handleServiceClick = (name, id) => {
    console.log(name, id)
    setIsSearchResult(false);
    setIsSearchResultMob(false)
    setSearch(name)
    localStorage.setItem("service", name)
    localStorage.setItem("serviceId", id)
    localStorage.removeItem("Type")
    localStorage.removeItem("TypeId")
    localStorage.removeItem("SPName")
    localStorage.removeItem("SPCode")
    localStorage.removeItem("SPId")
    window.location.href = '/search-result-splist'
  };

  const handleTypeClick = (name, id, sid) => {
    setSearch(name)
    setIsSearchResult(false);
    setIsSearchResultMob(false)
    localStorage.setItem("Type", name)
    localStorage.setItem("TypeId", id)
    localStorage.setItem("serviceId", sid)
    localStorage.removeItem("service")
    localStorage.removeItem("SPName")
    localStorage.removeItem("SPCode")
    localStorage.removeItem("SPId")
    window.location.href = '/search-result-splist'
  };

  const handleSPClick = (name, id, sid) => {
    setSearch(name)
    setIsSearchResult(false);
    setIsSearchResultMob(false)
    localStorage.setItem("SPName", name)
    localStorage.setItem("SPId", id)
    localStorage.setItem("serviceId", sid)
    localStorage.removeItem("SPCode")
    localStorage.removeItem("service")
    localStorage.removeItem("Type")
    window.location.href = '/search-result-splist';
  };

  const handleSPCodeClick = (name, code, id, sid) => {
    setSearch(code)
    setIsSearchResult(false);
    setIsSearchResultMob(false)
    let sidString = sid;
    if (typeof sid !== 'string') {
      sidString = String(sid);
    }
    const firstServiceId = sidString.split(',')[0].trim();
    localStorage.setItem("SPCode", code)
    localStorage.setItem("SPId", id)
    localStorage.setItem("serviceId", firstServiceId)
    localStorage.removeItem("SPName", name)
    localStorage.removeItem("service")
    localStorage.removeItem("Type")
    window.location.href = '/search-result-splist';
  };



  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs',
    libraries,
  });

  const handleLocationClick = () => {
    setLocationPopUp(true);
    const storedLat = parseFloat(localStorage.getItem("SelectedLat"));
    const storedLng = parseFloat(localStorage.getItem("SelectedLng"));
    setSelectedLatLng([storedLat, storedLng]);
    localStorage.setItem("SelectedLatSave", storedLat)
    localStorage.setItem("SelectedLngSave", storedLng)
  };

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const AutocompleteOptions = {
    componentRestrictions: { country: 'CA' },
  };

  const getCityNameFromLatLng = async (lat, lng) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === 'OK' && data.results.length > 0) {
        for (const result of data.results) {
          for (const component of result.address_components) {
            if (component.types.includes('locality')) {
              return component.long_name;
            }
          }
        }

        for (const result of data.results) {
          for (const component of result.address_components) {
            if (component.types.includes('administrative_area_level_1')) {
              return component.long_name;
            }
          }
        }
      } else {
        console.error('Error fetching city name:', data.status, data.error_message || '');
        return null;
      }
    } catch (error) {
      console.error('Error fetching city name:', error);
      return null;
    }
  };

  const onPlaceChanged = async () => {
    if (autocomplete !== null) {
      let city = 'Location not found';
      const place = autocomplete.getPlace();

      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const newLatLng = [lat, lng];

        // console.log(place.geometry.location);
        // console.log(lat);
        // console.log(lng);

        setSelectedLatLng(newLatLng);
        setIsPlaceEdit(true);
        localStorage.setItem("SelectedLat", lat);
        localStorage.setItem("SelectedLng", lng);

        let cityFound = false;
        place.address_components.forEach(component => {
          if (component.types.includes('locality')) {
            city = component.long_name;
            cityFound = true;
          }
        });

        if (!cityFound) {
          city = await getCityNameFromLatLng(lat, lng);
        }
        setChoosedLocationpopup(city)
        setSelectedLocation(city);
        setLastSelectedLocation(city)
        // setSelectedLocation(city);
        setChoosedLocationmap(city)
        setLocation(city)
        setChoosedLocation(choosedLocationmap)
      } else {
        console.log('No geometry found for the selected place.');
      }
    } else {
      console.log('Autocomplete is null');
    }
  };

  useEffect(() => {
    console.log("location fetch header:",localStorage.getItem("CurrentLocation"))
      const currentLocation = localStorage.getItem("CurrentLocation");
      if (currentLocation) {
     console.log (localStorage.getItem("CurrentLocation"))
      setLocation(localStorage.getItem("Location"));
      setSelectedLocation(localStorage.getItem("Location"));
      setLastSelectedLocation(localStorage.getItem("Location"))
    } else {
      const fetchLocation = async () => {
        try {
          const response = await fetch('https://ipapi.co/json/');
          const data = await response.json();
          const city = data.city;
          console.log (data.city)
          setLocation(city);
          const latitude = data.latitude;
          const longitude = data.longitude;
          setSelectedLatLng([latitude, longitude]);
          localStorage.setItem("SelectedLat", latitude);
          localStorage.setItem("SelectedLng", longitude);
        } catch (error) {
          console.error('Error fetching location:', error);
        }
      };
      fetchLocation();
    }
  }, []);

  const findNearestCity = async (location) => {
    const url = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${location.lat},${location.lng}&radius=5000&type=locality&key=AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === 'OK' && data.results.length > 0) {
        return data.results[0].name;
      } else {
        console.error('Error fetching nearest city:', data.status);
        return null;
      }
    } catch (error) {
      console.error('Error fetching nearest city:', error);
      return null;
    }
  };

  const handleMapClick = async (event) => {
    try {
      const newLatLng = [event.latLng.lat(), event.latLng.lng()];
      setSelectedLatLng(newLatLng);
      setChoosedLocationmap(event.latLng);
      setIsPlaceEdit(true);
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${event.latLng.lat()},${event.latLng.lng()}&key=AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs`;

      const response = await fetch(url);
      const data = await response.json();
      if (data.status === 'OK' && data.results.length > 0) {
        let city = 'Location not found';
        const addressComponents = data.results[0].address_components;
        const locality = addressComponents.find(
          (component) => component.types.includes('locality')
        );
        if (locality) {
          city = locality.long_name;
        } else {
          const nearestCity = await findNearestCity(data.results[0].geometry.location);
          if (nearestCity) {
            city = nearestCity;
          }
        }

        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setLastSelectedLocation(city);
        setChoosedLocationpopup(city);
        //setsearhCharLocationpopup(city)
        setSelectedLocation(city)
        setLocation(city)
      } else {
        console.error('Error fetching location details:', data.error_message || 'No results found');
      }
    } catch (error) {
      console.error('Error in handleMapClick:', error.message);
    }
  };

  const handleChangeLocationButtonClick = () => {
    setLocationPopUp(false)
    localStorage.setItem("selectedLocationMap", lastSelectedLocation)
    localStorage.setItem("SelectedLat", selectedLatLng[0])
    localStorage.setItem("SelectedLng", selectedLatLng[1])
    localStorage.setItem("SelectedLatLng", selectedLatLng)
    setLocation(choosedLocationpopup);
    localStorage.setItem("Location", choosedLocationpopup)
    localStorage.setItem("selectedLocationMap", choosedLocationpopup)
    localStorage.setItem("CurrentLocation", choosedLocationpopup)
    setChoosedLocation(choosedLocationmap)

  }

  const handleCloseLocationPopUp = () => {
    setLocationPopUp(false)
    setSelectedLatLng('')
    if (isPlaceEdit) {
      const storedLat = parseFloat(localStorage.getItem("SelectedLatSave"));
      const storedLng = parseFloat(localStorage.getItem("SelectedLngSave"));
      localStorage.setItem("SelectedLat", storedLat)
      localStorage.setItem("SelectedLng", storedLng)
      setSelectedLocation(localStorage.getItem("selectedLocationMap"));
      setLocation(localStorage.getItem("selectedLocationMap"))
      setSelectedLatLng([storedLat, storedLng]);
      setIsPlaceEdit(false)
    }
  };

  useEffect(() => {
    const storedLat = parseFloat(localStorage.getItem("SelectedLat"));
    const storedLng = parseFloat(localStorage.getItem("SelectedLng"));

    if (!isNaN(storedLat) && !isNaN(storedLng)) {
      setSelectedLatLng([storedLat, storedLng]);
    }
  }, []);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  

  return (
    <Grid sx={{ width: '100%', height: 'fit-content', padding: '0px', margin: '0px' }}>
      <Box sx={{
        width: '100%', height: '100%', justifyContent: 'left', alignItems: 'center', display: 'flex',
        flexDirection: 'row',
      }}>
        <Box
          sx={{
            position: 'relative',
            width: '90%',
            height: isHeader ? '36px' : '48px',
            display: 'flex',
            alignItems: 'center',
            maxWidth: { xs: '100%', md: '100%' },
            borderRadius: isSearchResult ? '4px 4px 0px 4px' : '4px',
            backgroundColor: isHeader ? Colors.white : Colors.white_80,
            border: '1px solid',
            borderColor: isHeader ? Colors.black_70 : Colors.blue,
            padding: '0px',
            margin: '0px',
            justifyContent: 'center',
            flexDirection: 'row',
            boxShadow: isHeader
              ? 'none'
              : '0px 1px 3px 0px #4C2E841A, 0px 5px 5px 0px #4C2E8417, 0px 10px 6px 0px #4C2E840D, 0px 19px 7px 0px #4C2E8403, 0px 29px 8px 0px #4C2E8400',
          }}
        >
          <Box
            sx={{
              width: '40%',
              height: isHeader ? '36px' : '48px',
              display: 'flex',
              alignItems: 'center',
              borderRight: '2px solid',
              borderColor: Colors.black_60,
              maxHeight: '80%',
              justifyContent: 'center',
              padding: '0px',
            }}
          >
            <TextField
              value={location}
              onClick={handleLocationClick}
              placeholder="Add Location"
              variant="outlined"
              fullWidth
              InputProps={{
                  startAdornment: isDesktop ? (
                    <InputAdornment position="start">
                      <PlaceOutlinedIcon sx={{ color: 'black', fontSize: { md: '18px' } }} />
                    </InputAdornment>
                  ) : null, 
              }}
              sx={{
                height: isHeader ? '36px' : '48px',
                '& .MuiOutlinedInput-root': {
                  border: 'none',
                  '& fieldset': {
                    border: 'none',
                  },
                  '& .MuiInputBase-input::placeholder': {
                    fontFamily: 'Roboto',
                    fontSize: { xs: '14px', md: '14px' },
                    fontWeight: 400,
                    color: Colors.black_80,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: { xs: '10px !important', md: '80%' },
                  },
                  '& .MuiInputBase-input': {
                    padding: isHeader ? { xs: '8px 0px', md: '7.5px' } : '13.5px',
                    display: 'flex', whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                    alignItems: 'center',
                  },
                },
              }}
            />
          </Box>

          <Box
            sx={{
              width: {xs:'50%', md:'60%'},
              height: isHeader ? '36px' : '48px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '0px',
            }}
          >
            <TextField
              value={search}
              onChange={(event) => handleSearchChange(event)}
              placeholder="Search here"
              variant="outlined"
              fullWidth
              autoComplete="off"
              InputProps={{
                startAdornment: isDesktop ? (  <InputAdornment position="start">
                        <SearchOutlinedIcon sx={{ color: Colors.black_80, fontSize: { xs: '16px', md: '18px' } }} />
                    </InputAdornment>
                ) : null,
                endAdornment: (
                  search && (
                    <InputAdornment position="end">
                      <CloseOutlinedIcon
                        sx={{
                          color: Colors.black_70,
                          cursor: 'pointer',
                          fontSize: { xs: '16px', md: '18px' },
                        }}
                        onClick={() => {
                          setSearch('');
                        }}
                      />
                    </InputAdornment>
                  )
                ),
              }}
              sx={{
                height: isHeader ? '36px' : '48px',
                '& .MuiOutlinedInput-root': {
                  border: 'none',
                  '& fieldset': {
                    border: 'none',
                  },
                  '& .MuiInputBase-input::placeholder': {
                    fontFamily: 'Roboto',
                    fontSize: { xs: '14px', md: '14px' },
                    fontWeight: 400,
                    color: Colors.black_80,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                    width: '100%',
                  },
                  '& .MuiInputBase-input': {
                    padding: isHeader ? { xs: '8px', md: '7.5px' } : '13.5px',
                    display: 'flex', whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                    alignItems: 'center',
                  },
                  '&.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root': { paddingRight: '0px' }
                },
              }}
            />
            {isSearchResult && !isSearchEdit && (
              <Box ref={boxRefhdr}
                sx={{
                  width: '59.7%', position: 'absolute', marginTop: isHeader ? '36.8px' : '48px', borderRadius: '0px 0px 4px 4px', paddingBottom: '8px',
                  maxHeight: '170px', overflow: 'auto', boxShadow: '0px 4px 4px 0px #00000040', display: 'flex', flexDirection: 'column',
                  border: '1px solid', borderColor: Colors.grey_30, background: Colors.white, paddingTop: '8px', zIndex: 9, gap: '4px',
                  '&::-webkit-scrollbar': {
                    width: '11px', height: '8px', borderRadius: '16px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: Colors.grey_30, borderRadius: '16px', border: '2px solid white',
                  },
                  '&::-webkit-scrollbar-track': {
                    borderRadius: '16px', backgroundColor: Colors.white,
                  },
                }}>

                {searchResultServices.map((result, index) => (
                  <Box key={index} sx={{
                    width: 'calc(100% - 8px) ', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', padding: '4px',
                    cursor: 'pointer', justifyContent: 'center', '&:hover': { backgroundColor: Colors.hovering_grey },
                  }}
                    onClick={() => { handleServiceClick(result.search_tag_type_value, result.search_tag_type_id) }}>
                    <Box sx={{
                      width: '100% ', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px',
                      maxWidth: '92%'
                    }}>

                      <TypographyNormal sx={{ minWidth: 'fit-content', color: Colors.black_80 }}> {result.search_tag_type_value} </TypographyNormal>
                      {/* <TypographyNormal sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                                    maxWidth: `calc( 92% - ${result.search_tag_type_value.length})`, color: Colors.black_60
                                  }}>
                                  </TypographyNormal> */}
                    </Box>
                  </Box>
                ))}

                {searchResultTypes.map((result, index) => (
                  <Box key={index} sx={{
                    width: 'calc(100% - 8px) ', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', padding: '4px',
                    cursor: 'pointer', justifyContent: 'center', '&:hover': { backgroundColor: Colors.hovering_grey },
                  }}
                    onClick={() => handleTypeClick(result.search_tag_type_value, result.search_tag_type_id, result.parent_id)}>
                    <Box sx={{
                      width: '100% ', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px',
                      maxWidth: '92%'
                    }}>

                      {result.search_tag_type_value && <TypographyNormal sx={{
                        color: Colors.black_80, minWidth: (result.search_tag_type_value.length > 48) ? '40ch' : 'fit-content',
                        whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                      }}> {result.search_tag_type_value} </TypographyNormal>}
                      {result.search_tag_type_value && result.search_tag_type_value.length < 48 && <TypographyNormal sx={{
                        color: Colors.black_60, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                        maxWidth: (result.parent_value.length + result.search_tag_type_value.length) > 40 ? '12ch' : 'fit-content',
                      }}> ({result.parent_value})
                      </TypographyNormal>}

                    </Box>
                  </Box>
                ))}

                {searchResultSP.map((result, index) => (
                  <Box key={index} sx={{
                    width: 'calc(100% - 8px) ', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', padding: '4px',
                    cursor: 'pointer', justifyContent: 'center', '&:hover': { backgroundColor: Colors.hovering_grey },
                  }}
                    onClick={() => handleSPClick(result.search_tag_type_value, result.search_tag_type_id, result.parent_id)}>
                    <Box sx={{
                      width: '100% ', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px',
                      maxWidth: '92%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                    }}>
                      <TypographyNormal sx={{
                        color: Colors.black_80, minWidth: result.search_tag_type_value.length > '20ch' ? '92%' : 'fit-content',
                        whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                      }}> {result.search_tag_type_value} </TypographyNormal>
                      <TypographyNormal sx={{
                        color: Colors.black_60, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                        maxWidth: (result.parent_value.length + result.search_tag_type_value.length) > '20ch' ? 'calc(92% - search_tag_type_value.length)' : 'fit-content',
                      }}> ({result.parent_value})
                      </TypographyNormal>
                    </Box>
                  </Box>
                ))}

                {searchResultSPCode.map((result, index) => (
                  <Box key={index} sx={{
                    width: 'calc(100% - 8px) ', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', padding: '4px',
                    cursor: 'pointer', justifyContent: 'center', '&:hover': { backgroundColor: Colors.hovering_grey },
                  }}
                    onClick={() => handleSPCodeClick(result.business_name, result.service_provider_code, result.service_provider_id, result.services_id)}>
                    <Box sx={{
                      width: '100% ', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px',
                      maxWidth: '92%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                    }}>
                      <TypographyNormal sx={{
                        color: Colors.black_80, minWidth: result.business_name.length > 20 ? '92%' : 'fit-content',
                        whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                      }}> {result.service_provider_code} </TypographyNormal>
                      <TypographyNormal sx={{
                        color: Colors.black_60, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                        minWidth: (result.services.length + result.business_name.length) > 20 ? 'calc(92%-business_name.length)' : 'fit-content',
                      }}> ({result.services})
                      </TypographyNormal>
                    </Box>
                  </Box>
                ))}



              </Box>
            )}



          </Box>

        </Box>
      </Box>



      {isSearchEdit && <Box sx={{
        width: '100% ', display: 'flex', flexDirection: { md: 'row', xs: 'column' },
        paddingLeft: { md: '0px', xs: '5%' }, alignItems: 'left', cursor: 'pointer', justifyContent: 'left',
        marginTop: { md: '0px', xs: '-4%' },
      }}>
        <TypographyNormal sx={{
          color: Colors.white_80, minWidth: 'fit-content', lineHeight: { md: '20px', xs: '16px' },
          whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '92%'
        }}>
          We couldn't find any data based on your search.
        </TypographyNormal>
        <TypographyNormal sx={{
          color: Colors.white_80, minWidth: 'fit-content', lineHeight: { md: '20px', xs: '16px' },
          whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '92%'
        }}>
          Please choose one from the list.
        </TypographyNormal>

      </Box>}


      {locationPopUp && (
        <Box sx={{
          position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '2',
        }}>
          <Box sx={{
            position: 'absolute', width: { md: '60%', sm: '60%', xs: '85%' }, height: { md: '70%', sm: '70%', xs: '80%' },
            backgroundColor: Colors.white, border: '1px solid ', borderColor: Colors.white, boxShadow: 'none', boxSizing: 'border-box',
            zIndex: '3', maxWidth: '90%', alignItems: 'center',
          }}>
            <IconButton onClick={handleCloseLocationPopUp} sx={{
              position: 'fixed', width: '36px', height: '36px',
              left: { md: '78%', sm: '78%', xs: '85%' }, top: { md: '9.5%', sm: '9%', xs: '4%' }
            }} >
              <CloseOutlinedIcon sx={{
                width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                  width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                  background: 'none'
                },
              }} ></CloseOutlinedIcon>
            </IconButton>

            <Box sx={{
              display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
              justifyContent: 'center', alignItems: 'center', height: '100%',
            }}>

              <Box sx={{
                display: 'flex', flexDirection: 'column', width: '100%', gap: '24px', paddingTop: '2.5%',
                justifyContent: 'center', alignItems: 'center', height: '95%', maxWidth: '90%', paddingBottom: '2.5%'
              }}>

                <Box sx={{
                  display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%', gap: '16px',
                  justifyContent: 'space-between', alignItems: 'center', height: 'fit-content',
                }}>

                  <Box sx={{
                    display: 'flex', flexDirection: 'row', width: { xs: '100%', md: '45%' }, gap: '16px',
                    justifyContent: 'left', alignItems: 'center', height: '100%',
                  }}>
                    <TypographySubHeading sx={{ fontSize: '16px' }}>Your location :</TypographySubHeading>
                    <TypographyNormal>{selectedLocation}</TypographyNormal>
                  </Box>

                  <Box sx={{
                    display: 'flex', flexDirection: 'column', width: { xs: '100% ', md: '55%' }, gap: '16px',
                    height: '100%',
                  }}>
                    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}
                      options={AutocompleteOptions} style={{ width: '100% !important' }}  >
                      <TextField placeholder="Search here"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchOutlinedIcon />
                            </InputAdornment>),
                        }}
                        sx={{
                          height: '38px', borderRadius: '4px', border: '1px solid', borderColor: Colors.black_60,
                          '& .MuiOutlinedInput-root': {
                            borderColor: Colors.black_80, height: '100%', width: { xs: '76vw', md: '29vw' },
                            '&:hover fieldset': {
                              borderColor: Colors.black_80,
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: Colors.black_80,
                            },
                          },
                        }}
                      />

                    </Autocomplete>
                  </Box>



                </Box>
                <GoogleMap
                  mapContainerStyle={{ ...mapContainerStyle, cursor: 'pointer !important' }}
                  zoom={12}
                  center={{ lat: selectedLatLng[0], lng: selectedLatLng[1] }}
                  onClick={handleMapClick}>
                  {selectedLatLng && (
                    <MarkerF position={{ lat: selectedLatLng[0], lng: selectedLatLng[1] }} />
                  )}
                </GoogleMap>
                <Box sx={{
                  display: 'flex', flexDirection: 'row', width: '100%', gap: '16px',
                  justifyContent: 'right', alignItems: 'center'
                }}>
                  <ButtonBlue disabled={selectedLocation === 'Location not found'} sx={{ width: 'fit-cintent' }}
                    onClick={handleChangeLocationButtonClick}>Confirm</ButtonBlue>
                </Box>
              </Box>
            </Box>



          </Box>
        </Box>
      )}

    </Grid>
  )
} export default SearchComponentHeader