import { Box, Grid, Typography, Avatar, Rating, TextField, IconButton, Tab, Tabs, InputBase, LinearProgress } from "@mui/material"
import React, { useEffect, useState, useCallback, useRef } from 'react';
import Header from "../Components/Header";
import Colors from "../Components/Colors";
import TypographyNormal from "../Components/TypographyNormal";
import TypographyHeading from "../Components/TypographyHeading";
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyError from "../Components/TypographyError";
import Footer from "../Components/Footer";
import ButtonBlue from "../Components/ButtonBlue";
import axios from "axios";
import { API_URL } from '../Components/Constants';
import TextfieldNormal from "../Components/TextfieldNormal";
import businesscard from "../Images/businesscard.svg"
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IMG_URL } from "../Components/Constants";
import ButtonWhite from "../Components/ButtonWhite";
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { FRONTEND_URL } from '../Components/Constants';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SpMessage from "../Components/SpMessages";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import logo from '../Images/logo.png'
import EditIcon from '@mui/icons-material/Edit';
import share from '../Images/share.svg'
import edit from '../Images/edit.svg'
import editfill from '../Images/editfill.svg'


function SpLanding() {

    const [serviceProviderDetails, setServiceProviderDetails] = useState([]);
    const [isCompleteProfile, setIsCompleteProfile] = useState(false);
    const [qrCodeUrl, setQrCodeUrl] = useState(null);
    const [serviceDetails, setServiceDetails] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [serviceTabName, setServiceTabName] = useState([]);
    const [selectedServiceData, setSelectedServiceData] = useState([]);
    const [messageDetails, setMessageDetails] = useState([]);
    const [ratingDetails, setRatingDetails] = useState([]);
    const [isShare, setIsShare] = useState(false);
    const [isViewCard, setIsViewCard] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isImagePopUp, setIsImagePopUp] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [selectedMessageDetails, setSelectedMessageDetails] = useState([]);
    const [isHoveredService, setIsHoveredService] = useState(false);
    const [isHoveredLocation, setIsHoveredLocation] = useState(false);



    const colors = ['#503259', '#4C50AE', '#FF7D6A', '#B07CC6', '#20C925'];

    function getAvatarColor(index) {
    return colors[index % colors.length];
    }

    function stringAvatar(name = '', index) {
    const initials = name[0]?.toUpperCase() || '';
    const bgColor = getAvatarColor(index);
    return {
        sx: {
        bgcolor: bgColor,
        width: '64px',
        height: '64px',
        fontSize: '56px',
        color: '#fff',
        },
        children: initials,
    };
    }

    const formatDateTime = (dateTimeString) => {

        const [datePart, timePart] = dateTimeString.split(' ');
        const [year, month, day] = datePart.split('-');   
        const dateUTC = new Date(Date.UTC(year, month - 1, day));
        const timeOptions = {
          timeZone: 'America/Toronto',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true, 
        };
        const timeFormatter = new Intl.DateTimeFormat('en-CA', timeOptions);
        const formattedTime = timeFormatter.format(dateUTC);  
       
        const dateInToronto = new Date(dateUTC.toLocaleString('en-US', { timeZone: 'America/Toronto' }));
        const dayInToronto = String(dateInToronto.getDate()).padStart(2, '0');
        const monthInToronto = String(dateInToronto.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const yearInToronto = dateInToronto.getFullYear();
        return `${dayInToronto}/${monthInToronto}/${yearInToronto}`;
      };
      
      let totalRatingSum
     

    const calculateAverageRating = (ratingsData) => {
        if (!ratingsData || ratingsData.length === 0 || !ratingsData[0] || !ratingsData[0][0]) {           
            return 4;
        }
        const ratingCounts = ratingsData[0][0]; 
        totalRatingSum = 0;
        let totalRatingCount = 0;
    
        for (const [rating, count] of Object.entries(ratingCounts)) {
            totalRatingSum += rating * count;
            totalRatingCount += count;
        }
    
        const averageRating = totalRatingSum / totalRatingCount;
        const adjustedRating = averageRating < 4 ? 4 : averageRating;
        return adjustedRating % 1 === 0 ? adjustedRating.toFixed(0) : adjustedRating.toFixed(1);
    };
    

   
    // const ratingCounts = ratingDetails[0] || {};
    // const totalRatings = Object.values(ratingCounts).reduce((sum, count) => sum + count, 0);  
    
    // const calculateRatingPercentage = (count) => (totalRatings > 0 ? (count / totalRatings) * 100 : 0);

    const totalRatings = Object.keys(ratingDetails)
    .filter((key) => key !== '_id') // Ignore the '_id' key
    .reduce((sum, key) => sum + ratingDetails[key], 0); // Sum the counts for ratings

    const calculateRatingPercentage = (count) => 
    totalRatings > 0 ? (count / totalRatings) * 100 : 0;


    const fetchSpData = async () => {            
        try { 
            const response = await axios.post(`${API_URL}/select_sp_details_with_tocken`, {                    
                spToken: localStorage.getItem("spToken")
            }); 
             console.log(response.data.result)
             setServiceProviderDetails(response.data.result)
             setQrCodeUrl(response.data.result.qrCode)  

            const serviceId = response.data.result.service_details[0].services_id ? response.data.result.service_details[0].services_id : null
            const serviceDetails = response.data.result.service_details ? response.data.result.service_details : [];
            const messages = response.data.result.message_details ? response.data.result.message_details : []; 
            const sentMessages = messages.filter(message => message.sent_recieve === 'sent');              
            setServiceDetails(serviceDetails);  
                       

            const tabNames = serviceDetails.map(item => item.professional_name);
            setServiceTabName(tabNames ? tabNames : []);

            const filteredServices = serviceDetails.filter(service => service.services_id === serviceId);
            setSelectedServiceData(filteredServices ? filteredServices : []); 
            console.log(filteredServices)

            setMessageDetails(sentMessages)

            console.log(response.data.result.rating_details[0])
            setRatingDetails(response.data.result.rating_details[0] ? response.data.result.rating_details[0] : [])
            
            const tabIndex = filteredServices ? tabNames.findIndex(service => service === filteredServices[0].professional_name) : null;
            setSelectedTab(tabIndex); 
           console.log(tabIndex)
        } catch (error) {
            console.error('Error fetching data:', error);
            
        }
    };


    const fetchSpMessageData = async () => {            
        try { 
            const response = await axios.post(`${API_URL}/select_sp_details_with_tocken`, {                    
                spToken: localStorage.getItem("spToken")
            }); 
            
            const messages = response.data.result.message_details ? response.data.result.message_details : []; 
            const sentMessages = messages.filter(message => message.sent_recieve === 'sent'); 
           
            setMessageDetails(sentMessages)
        } catch (error) {
            console.error('Error fetching data:', error);
            
        }
    };

    useEffect(() => {        
        fetchSpMessageData();
        const intervalId = setInterval(fetchSpMessageData, 5000); 
        return () => clearInterval(intervalId);
    }, []);

    const fetchSpDataProfile = async () => {            
        try { 
            const response = await axios.post(`${API_URL}/select_sp_details_with_tocken`, {                    
                spToken: localStorage.getItem("spToken")
            }); 
            console.log()
            if(response.data.result.active === 'No' && (serviceProviderDetails.about_us === null || serviceProviderDetails.about_us === undefined)){
                console.log("hii")
                setIsCompleteProfile(true)  
                document.body.style.overflow = 'hidden';                    
            } 
             else {
                console.log("hii")
                setIsCompleteProfile(false)  
                document.body.style.overflow = 'auto'; 
            }
                
            
        } catch (error) {
            console.error('Error fetching data:', error);
            
        }
    };  

    useEffect(() => {
        const spToken = localStorage.getItem("spToken");
      
        if (spToken) {
         
            fetchSpData();
            fetchSpDataProfile()
        }
      }, []);
      


   

    
    const CompleteProfileButtonClick = () => {
        setIsCompleteProfile(false)
         if (serviceProviderDetails.service_details.length === 0) {
            const isEdit = false;
            window.location.href = `/service-provider-service?edit=${isEdit}`;                   
          }
          else if (serviceProviderDetails.city === null || serviceProviderDetails.city === undefined) {           
            const isEdit = false;
            window.location.href = `/service-provider-location?edit=${isEdit}`;           
          }
          else if (serviceProviderDetails.about_us === null || serviceProviderDetails.about_us === undefined) {
            const isEdit = false;
            window.location.href = `/service-provider-profile?edit=${isEdit}`;
          }  
    }

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        const selectedService = serviceDetails[newValue];
        setSelectedServiceData([selectedService]);
    };

    const handleShareClick = () => {
        setIsShare(true)
    }

    const handleCloseSharePopUp = () => {
        setIsShare(false)
    }

    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        
        // Avoid scrolling to the bottom of the page
        textArea.style.position = 'fixed';
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = '0';
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
    
        document.body.appendChild(textArea);
        textArea.select();
    
        try {
            const successful = document.execCommand('copy');
            console.log('Fallback: Copying text command was ' + (successful ? 'successful' : 'unsuccessful'));
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }
    
        document.body.removeChild(textArea);
    };

    const handleCopyButtonClick = () => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(`${FRONTEND_URL}/sp-profile/${serviceProviderDetails.service_provider_code}`)
                .then(() => {
                    console.log('Link copied to clipboard');
                    setIsShare(false);
                })
                .catch((error) => {
                    console.error('Failed to copy:', error);                  
                    fallbackCopyTextToClipboard(`${FRONTEND_URL}/sp-profile/${serviceProviderDetails.service_provider_code}`);
                });
        } else {
            console.warn('Clipboard API not supported, unable to copy text');           
            fallbackCopyTextToClipboard(`${FRONTEND_URL}/sp-profile/${serviceProviderDetails.service_provider_code}`);
        }
        setIsShare(false)
    };
    
    const handleEditProfileClick = () => {
        const isEdit = true;
        window.location.href = `/service-provider-profile?edit=${isEdit}`;
    }

    const handleEditServiceClick = () => {
        const isEdit = true;
        window.location.href = `/service-provider-service?edit=${isEdit}`;
    }

    const handleEditLocationClick = () => {
        const isEdit = true;
        window.location.href = `/service-provider-location?edit=${isEdit}`;
    }
    
    const handleViewProfileClick = () => {
       setIsViewCard(true)
    }

    const handleCloseViewCardPopUp = () => {
        setIsViewCard(false)
     }

     const handleImageClick = (image, index) => {
        setSelectedImage(IMG_URL + image);
        setCurrentImageIndex(index)
        setIsImagePopUp(true);
    };

    const handleImagePopUpClick = (image, index) => {
        setSelectedImage(IMG_URL + image);
        setCurrentImageIndex(index)       
    };

    const handleCloseImagePopUp = () => {       
        setIsImagePopUp(false);
    };

    const handlePreviousImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? selectedServiceData[0].image_details.length - 1 : prevIndex - 1
        );
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === selectedServiceData[0].image_details.length - 1 ? 0 : prevIndex + 1
        );
    };

    function formatPostalCode(postalCode) {
        if (postalCode && typeof postalCode === 'string' && postalCode.length === 6) {
            return postalCode.slice(0, 3) + ' ' + postalCode.slice(3);
        }       
        return postalCode || ''; 
    }
    
    
    

    return (
        <>
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>
            <Header currentPage={"Home"}></Header>

            <Box sx={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' ,
                flexDirection: 'column',gap:'16px', paddingTop:'2%',paddingBottom:'2%' }}>
                      <Box sx={{width: '100%', height:{ xs: '100% ', md: '70%' } , justifyContent: 'center', alignItems:'stretch',
                       display: 'flex' , flexDirection:{ xs: 'column', md: 'row' } ,gap:'16px', maxWidth:{ xs: '90% ', md: '94%' }}}>
                            <Box sx={{width:{ xs: '100% ', md: '70%' }, justifyContent: 'center', alignItems: 'center',
                             display: 'flex' ,  flexDirection: 'column', borderRadius:'4px', order: { xs: 2, md: 1 },
                             border:{ xs: '0px solid #E6E6E6', md: '1px solid  #E6E6E6' },  }}>
                                <Box sx={{width: '100%', height: '100%',justifyContent: 'center', alignItems: 'center', 
                                display:  'flex', borderRadius:'4px' ,flexDirection: 'column', gap:'16px' }}>
                                <Box sx={{width: '100%', height: '100%',justifyContent: 'center', alignItems: 'center',
                                 display:  'flex', borderRadius:'4px' ,flexDirection: 'column',gap:'16px',
                                 background:{ xs:'linear-gradient(to bottom, #E6E6E6 80px, white 7.5%)', md:'linear-gradient(to bottom, #E6E6E6 150px, white 10%)' } ,
                                    }}>
                                <Box sx={{width: '100%', height: '94%',justifyContent: 'center', alignItems: 'center',
                                 display:  'flex',  flexDirection: 'column',gap:{ xs: '8px', md: '24px'},paddingBottom:'3%',}}>
                                      <Box sx={{width: '100%', height: 'fit-content',justifyContent: 'center', paddingTop:'3%' ,
                                      alignItems: 'center', display:  'flex', flexDirection: 'column', borderRadius:'4px',
                                      paddingBottom:{ xs: '16px', md: '0px' }, border:{ xs: '1px solid #E6E6E6', md: '0px solid' },  }}>
                                                
                                                <Box sx={{width: '100%', height: '100%',justifyContent: 'right', alignItems: 'left', display:{ xs:'none', md: 'flex' } , 
                                                         flexDirection: 'row',gap:{ xs: '4px', md: '16px' } , maxWidth: { xs:'90%', md:'95%' }}}>
                                                               
                                                                    <ButtonWhite sx={{minWidth:'fit-content', gap:{ xs: '2px', md: '4px' }, height:'32px', width:'116px'
                                                                    ,padding:{ xs: '0px 4px', md: '0px 16px' }, backgroundColor:'transparent', 
                                                                    '&:hover':{backgroundColor: 'white', boxShadow:'none'}}}
                                                                     onClick={handleViewProfileClick}><img 
                                                                     src={businesscard} 
                                                                     alt="Business Card" 
                                                                     onClick={handleViewProfileClick} 
                                                                     style={{ 
                                                                         width: '16px', // Adjust the size as needed
                                                                         height: 'auto', // Maintain aspect ratio
                                                                         cursor: 'pointer', // Change cursor to pointer for click indication
                                                                         fontSize: { xs: '16px', md: '20px' } // You can adjust this for responsive sizes if needed
                                                                     }} 
                                                                 />Preview Card</ButtonWhite>                                                                   
                                                                    <ButtonWhite sx={{minWidth:'fit-content',  gap: { xs: '2px', md: '4px' },  height:'32px', width:'106px',
                                                                    padding:{ xs: '0px 4px', md: '0px 16px' },backgroundColor:'transparent', 
                                                                    '&:hover':{backgroundColor: 'white', boxShadow:'none'}}}
                                                                    onClick={handleEditProfileClick}><img src={edit} alt="Edit Icon" style={{width:'16px'}}/>Edit</ButtonWhite>
                                                                    <ButtonBlue sx={{minWidth:'fit-content',  gap: { xs: '2px', md: '4px' },  height:'32px',width:'106px',
                                                                    padding:{ xs: '0px 4px', md: '0px 16px' },}} 
                                                                    onClick={handleShareClick}><img src={share} alt="Share Icon" style={{filter: 'brightness(0) invert(1)'}}/>Share</ButtonBlue>
                                                                   
                                                </Box> 
                                                <Box sx={{width: '100%', height: '100%',justifyContent: 'right', alignItems: 'left', display:{ xs:'flex', md: 'none' } , 
                                                                flexDirection: 'row',gap:{ xs: '16px', md: '16px' } , maxWidth: { xs:'90%', md:'95%' }}}>
                                                               
                                                                    {/* <BadgeOutlinedIcon  onClick={handleViewProfileClick} sx={{fontSize:{ xs: '16px', md: '20px' },}}/>                                                                     */}
                                                                    <img 
                                                                    src={businesscard} 
                                                                    alt="Business Card" 
                                                                    onClick={handleViewProfileClick} 
                                                                    style={{ 
                                                                        width: '16px', // Adjust the size as needed
                                                                        height: 'auto', // Maintain aspect ratio
                                                                        cursor: 'pointer', // Change cursor to pointer for click indication
                                                                        fontSize: { xs: '16px', md: '20px' } // You can adjust this for responsive sizes if needed
                                                                    }} 
                                                                />
                                                                    <EditOutlinedIcon  onClick={handleEditProfileClick} sx={{fontSize:{ xs: '16px', md: '24px' },paddingBottom:'2px'}}/>
                                                                    <ShareOutlinedIcon onClick={handleShareClick} sx={{fontSize:{ xs: '16px', md: '20px' }, color: Colors.primary_blue}}/>
                                                                   
                                                </Box> 

                                                <Box sx={{width: '100%', height: '100%',justifyContent: 'flex-start', alignItems: 'left', display:  'flex', 
                                                flexDirection: 'row',gap:{ xs: '16px', md: '32px' }, maxWidth: { xs:'90%', md:'95%' } }}>  
                                                        
                                                        
                                                        <Box sx={{ height: '100%', width:{ xs: '70px', md: '140px' } , justifyContent: 'center',marginTop:{md:'-1%', xs:'-2%'},
                                                         alignItems: 'flex-start',  display:  'flex', flexDirection: 'row',}}>
                                                            <Box sx={{position: 'relative', display: 'inline-block',}}> 
                                                            {console.log(serviceProviderDetails)}                                   
                                                                {serviceProviderDetails.profile_image ? (                                                                    
                                                                    <Avatar alt="profile"                                                                     
                                                                        src={IMG_URL + serviceProviderDetails.profile_image}
                                                                        sx={{ width: { xs: '70px', md: '120px' }, height: { xs: '70px', md: '120px' } }}/>
                                                                        ) : (
                                                                            <Avatar  {...stringAvatar(serviceProviderDetails.business_name)} 
                                                                            sx={{width: { xs: '70px', md: '120px' }, height:{ xs: '70px', md: '120px' }, fontSize: { xs: '32px', md: '58px'}, bgcolor:'#4C50AE' }} />
                                                                        
                                                                       )}  
                                                                       {console.log(serviceProviderDetails.rating_details)}                                  
                                                                {serviceProviderDetails.rating_details && 
                                                                <Avatar sx={{ position: 'absolute', bottom:{ xs:4, md:10} ,  right:{ xs: 1, md: -8},  width:{ xs: '20px', md: '36px'} ,  height: { xs: '20px', md: '36px'}, 
                                                                    backgroundColor: Colors.primary_blue, color: 'white', }} >
                                                                    <TypographyNormal sx={{color: Colors.white , fontSize:{ xs:'8px', md:'14px'}}}>
                                                                    {calculateAverageRating(serviceProviderDetails.rating_details)}
                                                                    </TypographyNormal>
                                                                </Avatar>}
                                                            </Box>

                                                        </Box>
                                                        <Box sx={{height: '100%', width: { xs:'calc(100% - 70px)', md:'calc(100% - 140px)'}, justifyContent: 'center', 
                                                        alignItems: 'left',display:  'flex', flexDirection: 'column',gap:'8px',
                                                        marginTop:{ xs:'0px', md:'16px'},  }}>                                                                                               
                                                          
                                                            <Box sx={{width: '100%', height: '100%',justifyContent: 'flex-start', alignItems: 'left', display:  'flex', 
                                                            flexDirection: 'column', gap:'4px', }}>                                                           
                                                                <TypographySubHeading sx={{fontSize:{ xs:'16px', md:'24px'}, lineHeight:{ xs:'16px', md:'28px'}}}>{serviceProviderDetails.business_name}</TypographySubHeading>                                                               
                                                                {isCompleteProfile ? (
                                                                <Box sx={{width: '100%', height: '12px',justifyContent: 'flex-start', alignItems: 'left', display:  'flex', 
                                                                    flexDirection: 'column', gap:'4px',background: Colors.black_60 }}>                                                           
                                                                        
                                                                    </Box> ) : (
                                                                <TypographyNormal sx={{fontSize:{ xs:'14px', md:'16px'},
                                                                color: Colors.black_80,whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',}}> {serviceProviderDetails.city}, {serviceProviderDetails.province}, {serviceProviderDetails.country}</TypographyNormal>
                                                                    )}
                                                                </Box>
                                                    </Box> 
                                                </Box>

                                                <Box sx={{width: '100%', height: '100%',justifyContent: 'left', alignItems: 'flex-start', display:  'flex', 
                                                flexDirection: 'row',gap:{ xs: '16px', md: '32px' }, maxWidth: { xs:'90%', md:'95%' } , }}>  
                                                        <Box sx={{ height: '100%', width:{ xs: '70px', md: '140px' } , 
                                                        justifyContent: 'flex-end', alignItems: 'center',
                                                            display:  'flex', flexDirection: 'column',paddingTop:'4px'}}>
                                                            <img src={qrCodeUrl} alt="qrcode" style={{ width: '70%', height: '100%' }} />                                                   
                                                            <TypographyNormal sx={{ marginTop: 'auto' }}>{serviceProviderDetails.service_provider_code}</TypographyNormal>
                                                        
                                                        </Box>
                                                        <Box sx={{height: '100%', width:{md: 'calc(100% - 140px)', xs:'calc(100% - 90px)'}, justifyContent: 'center', 
                                                        alignItems: 'left',display:  'flex', flexDirection: 'column',gap:'8px',
                                                    }}> 
                                                            <Box sx={{
                                                                        width: '100%', height: 'fit-content', justifyContent: 'space-between',
                                                                        alignItems: 'flex-start', display:  'flex' ,
                                                                        flexDirection: 'column', gap: '8px', 
                                                                        marginTop:{ xs:'0px', md:'-36px' } }}>
                                                                  {isCompleteProfile ? (
                                                                    <Box
                                                                        sx={{ width: '100%', height: 'fit-content', justifyContent: 'flex-start',
                                                                        alignItems: 'flex-start', display: 'flex', flexDirection:{ xs: 'column', md: 'row'} ,
                                                                        flexWrap: 'wrap',gap:{ xs:'4px', md:'8px' } }}>
                                                                        {[
                                                                        { label: 'Contact', value: serviceProviderDetails.contact_person },
                                                                        { label: 'Phone number', value: `+${serviceProviderDetails.country_code} ${serviceProviderDetails.phone_number}` },
                                                                        { label: 'Language', value: serviceProviderDetails.languages },
                                                                        { label: 'Email id', value: serviceProviderDetails.email },
                                                                        { label: 'Website', value: serviceProviderDetails.website }
                                                                        ]
                                                                        .map((item, index, arr) => (
                                                                            <Box
                                                                            key={index}
                                                                            sx={{
                                                                                display: 'flex', alignItems: 'center', gap: '12px', flexDirection: 'row',
                                                                                width: arr.length === 1 ? '100%' : { xs: '100%', md: '48%' },
                                                                                justifyContent: 'flex-start'}}>
                                                                            <TypographyNormal sx={{ minWidth: '12ch', fontWeight: 500 }}>{item.label}</TypographyNormal>
                                                                            <TypographyNormal>:</TypographyNormal>
                                                                            {isCompleteProfile ? (
                                                                        <Box sx={{width: '100%', height: '12px',justifyContent: 'flex-start', alignItems: 'left', display:  'flex', 
                                                                            flexDirection: 'column', gap:'4px',background: Colors.black_60 }}>                                                           
                                                                                
                                                                            </Box> ) : (
                                                                            <TypographyNormal
                                                                                sx={{ maxWidth: '98%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                                {item.value}
                                                                            </TypographyNormal>)}
                                                                            </Box>
                                                                        ))}
                                                                    </Box>) : (
                                                                    <Box
                                                                    sx={{ width: '100%', height: 'fit-content', justifyContent: 'flex-start',
                                                                    alignItems: 'flex-start', display: 'flex', flexDirection:{ xs: 'column', md: 'row'} ,
                                                                    flexWrap: 'wrap',gap:{ xs:'4px', md:'8px' } }}>
                                                                    {[
                                                                    { label: 'Contact', value: serviceProviderDetails.contact_person },
                                                                    { label: 'Phone number', value: `+${serviceProviderDetails.country_code} ${serviceProviderDetails.phone_number}` },
                                                                    { label: 'Language', value: serviceProviderDetails.languages },
                                                                    { label: 'Email id', value: serviceProviderDetails.email },
                                                                    { label: 'Website', value: serviceProviderDetails.website },
                                                                    { label: 'Postal code', value: formatPostalCode(serviceProviderDetails.postal_code)}
                                                                    ]
                                                                    .filter(item => item.value)
                                                                    .map((item, index, arr) => (
                                                                        <Box
                                                                        key={index}
                                                                        sx={{
                                                                            display: 'flex', alignItems: 'center', gap: '12px', flexDirection: 'row',
                                                                            width: arr.length === 1 ? '100%' : { xs: '100%', md: '48%' },
                                                                            justifyContent: 'flex-start'}}>
                                                                        <TypographyNormal sx={{ minWidth: '12ch', fontWeight: 500 }}>{item.label}</TypographyNormal>
                                                                        <TypographyNormal>:</TypographyNormal>
                                                                        {isCompleteProfile ? (
                                                                    <Box sx={{width: '100%', height: '12px',justifyContent: 'flex-start', alignItems: 'left', display:  'flex', 
                                                                        flexDirection: 'column', gap:'4px',background: Colors.black_60 }}>                                                           
                                                                            
                                                                        </Box> ) : (
                                                                        <TypographyNormal
                                                                            sx={{ maxWidth: '98%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                            {item.value}
                                                                        </TypographyNormal>)}
                                                                        </Box>
                                                                    ))}
                                                                    </Box>
                                                                    )}
                                                            </Box>

                                                            
                                                        </Box> 
                                                        
                                                </Box> 
                                         </Box>

                                         <Box sx={{width: '100%', height: '66%',justifyContent: 'center', alignItems: 'center', display:  'flex', 
                                           flexDirection: 'column',gap:'16px' , }}>
                                                <Box sx={{width: '100%', height: 'fit-content',justifyContent: 'center', alignItems: 'center', display:  'flex', 
                                                flexDirection: 'column', border:'1px solid', borderColor: Colors.grey_30, paddingTop:'2.5%',
                                                borderLeft:{ xs: '1px solid #E6E6E6', md: '0px solid' }, borderRight:{ xs: '1px solid #E6E6E6', md: '0px solid' },
                                                paddingBottom:'2.5%', borderRadius:{ xs: '4px', md: '0px' },maxWidth: { xs: '100%', md: '100%' }}}>
                                                        <Box sx={{width: '100%', height: 'fit-content',justifyContent: 'center', alignItems: 'left', display:  'flex', 
                                                            flexDirection: 'column', maxWidth: { xs:'90%', md:'95%' }, gap: '4px' }}>
                                                                <TypographyNormal sx={{fontSize:{ xs: '14px', md: '18px' }, fontWeight:500}}>About</TypographyNormal>
                                                                {isCompleteProfile ? (
                                                                    <Box sx={{width: '100%', height: '12px',justifyContent: 'flex-start', alignItems: 'left', display:  'flex', 
                                                                        flexDirection: 'column', gap:'4px',background: Colors.black_60 }}>                                                           
                                                                            
                                                                        </Box> ) : (
                                                                <TypographyNormal>{serviceProviderDetails.about_us}</TypographyNormal>
                                                                        )}
                                                        </Box>
                                               </Box>
                                          </Box>

                                          <Box sx={{width: '100%', height: '95%',justifyContent:'flex-start' , alignItems: 'center', display:  'flex', 
                                           flexDirection: 'column',gap:'16px' ,borderRadius:'4px', paddingBottom:'2.5%',paddingTop:{md:0, xs:'2.5%'},
                                           border:{ xs: '1px solid #E6E6E6', md: '0px solid  #E6E6E6' },}}>                                            
                                            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', 
                                                maxWidth: { xs:'90%', md:'95%' } , overflowX: 'auto', justifyContent: 'space-between', }} >
                                                {isCompleteProfile ? (
                                            <Box sx={{width: '240px', height: '28px',justifyContent: 'flex-start', alignItems: 'left', display:  'flex', 
                                                flexDirection: 'column', gap:'4px',background: Colors.black_60,  }}>                                                           
                                                    
                                                </Box> ) : (
                                                <Tabs value={selectedTab}
                                                    onChange={handleTabChange}  
                                                    scrollButtons="auto"
                                                    variant="scrollable"                                     
                                                    TabIndicatorProps={{ style: { display: 'none' } }}
                                                    sx={{gap: '0px', '& .MuiTab-root': {  
                                                            padding: '0px',
                                                            minWidth: 'unset !important',  
                                                        }}}>                                       
                                                    {serviceTabName.map((service, index) => (
                                                        <Tab
                                                            key={index}
                                                            label={
                                                                <TypographyNormal sx={{
                                                                        fontFamily: 'Roboto',minWidth:'80px', color:selectedTab === index ? Colors.white : Colors.black_80,
                                                                        cursor: 'pointer', width: 'fit-content', overflow: 'hidden',textOverflow: 'ellipsis', textAlign: 'center',
                                                                        whiteSpace: 'nowrap', border: '1px solid', borderColor:  selectedTab === index ? Colors.secondary_blue : Colors.grey_30,
                                                                        padding: selectedTab === index ? '12px 12px 8px 12px' : '8px 12px', borderRadius: selectedTab === index ? '4px 4px 0px 0px' : '0px',
                                                                        textTransform: 'none', backgroundColor: selectedTab === index ? Colors.secondary_blue : 'transparent', marginBottom: selectedTab === index ? '4px' : '0px',
                                                                    }}>
                                                                {service}
                                                                </TypographyNormal>
                                                            }
                                                        />
                                                    ))}
                                                </Tabs> )}
                                                <div
                                                onMouseEnter={() => setIsHoveredService(true)}
                                                onMouseLeave={() => setIsHoveredService(false)}
                                                >
                                                {isHoveredService ? (
                                                  <img onClick={handleEditServiceClick} src={editfill} alt="Edit Icon"  style={{cursor:'pointer'}}/>
                                                ) : (
                                                   <img onClick={handleEditServiceClick} src={edit} alt="Edit Icon" style={{cursor:'pointer'}}/>
                                                )}
                                                </div>


                                            </Box> 
                                              

                                            <Box sx={{ width: '100%', height: 'fit-content', justifyContent: 'center', alignItems: 'left', display: 'flex', flexDirection: 'column', gap: '16px', maxWidth: { xs:'90%', md:'95%' } }}>
                                            {isCompleteProfile ? (
                                                                   
                                                    <Box sx={{ width: '100%', height: 'fit-content', justifyContent: 'center', alignItems: 'left', display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                    <TypographyNormal sx={{ fontSize: { xs: '14px', md: '16px' }, fontWeight: 500 }}>Type of services</TypographyNormal>
                                                    <Box sx={{width: '100%', height: '12px',justifyContent: 'flex-start', alignItems: 'left', display:  'flex', 
                                                flexDirection: 'column', gap:'4px',background: Colors.black_60 }}>                                                           
                                                    
                                                </Box>
                                                
                                                </Box>) : (
                                               selectedServiceData[0] && 
                                                    <>
                                                        <Box sx={{ width: '100%', height: 'fit-content', justifyContent: 'center', alignItems: 'left', display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                            <TypographyNormal sx={{ fontSize: { xs: '14px', md: '16px' }, fontWeight: 500 }}>Type of services</TypographyNormal>
                                                            
                                                            {selectedServiceData[0].type_details && (
                                                                <TypographyNormal>
                                                                    {selectedServiceData[0].type_details.map(item => item.types).join(', ')}
                                                                </TypographyNormal>
                                                            )}
                                                        </Box>

                                                        <Box sx={{ width: '100%', height: 'fit-content', justifyContent: 'center', alignItems: 'left', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                                            {[
                                                                { label: 'License No', value: selectedServiceData[0].license },
                                                                { label: 'Office address', value: selectedServiceData[0].office_address },
                                                                { label: 'Certifications', value: selectedServiceData[0].certifications },
                                                                { label: 'Franchise', value: selectedServiceData[0].franchise }
                                                            ]
                                                                .filter(item => item.value) 
                                                                .reduce((rows, item, index) => {
                                                                    if (index % 2 === 0) rows.push([]);
                                                                    rows[rows.length - 1].push(item);
                                                                    return rows;
                                                                }, [])
                                                                .map((row, rowIndex) => (
                                                                    <Box
                                                                        key={rowIndex}
                                                                        sx={{
                                                                            width: '100%',
                                                                            height: 'fit-content',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'left',
                                                                            display: 'flex',
                                                                            flexDirection: { xs: 'column', md: 'row' },
                                                                            gap: '8px'
                                                                        }}>
                                                                        {row.map((item, itemIndex) => (
                                                                            <Box
                                                                                key={itemIndex}
                                                                                sx={{
                                                                                    width: row.length === 1 ? '100%' : '50%',
                                                                                    height: 'fit-content',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'left',
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                    gap: '4px'
                                                                                }}>
                                                                                <TypographyNormal sx={{ fontSize: { xs: '14px', md: '16px' }, fontWeight: 500 }}>{item.label}</TypographyNormal>
                                                                                <TypographyNormal>{item.value}</TypographyNormal>
                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                ))}
                                                        </Box>

                                                        {selectedServiceData[0].image_details && selectedServiceData[0].image_details.length > 0 && (
                                                            <Box 
                                                                sx={{ 
                                                                    height: 'fit-content' ,
                                                                    width: '100%' , 
                                                                    justifyContent: 'center', 
                                                                    alignItems: 'left', 
                                                                    display: 'flex', 
                                                                    flexDirection: 'column', 
                                                                    gap: '8px' }}>
                                                                <TypographyNormal 
                                                                    sx={{ fontSize: { xs: '14px', md: '16px' }, fontWeight: 600 }}>
                                                                    Projects
                                                                </TypographyNormal>
                                                               
                                                                  <Box sx={{
                                        display: 'flex', alignItems: 'center', overflowX: 'auto', whiteSpace: 'nowrap',
                                         gap: '12px', padding: '8px 0px', width: 'calc(100% )','&::-webkit-scrollbar': {
                                            width: '12px', height: '8px', borderRadius: '16px',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: Colors.black_60, borderRadius: '16px', border: '2px solid white',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            borderRadius: '16px', backgroundColor: 'none',marginLeft:'8px', marginRight:'4px'
                                        },
                                    }}>
                                      {selectedServiceData[0].image_details.slice(0, 10).map((image, index) => (  // Show max 10 images
                                                                        <Box 
                                                                            key={index} 
                                                                            sx={{ 
                                                                                width:'80px',
                                                                                height: '80px',
                                                                                flexShrink: 0  // Prevent shrinking when scrolling
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={IMG_URL + image.image_name}
                                                                                alt="projects"
                                                                                style={{
                                                                                    width: '100%', height: '100%', objectFit: 'cover',
                                                                                    borderRadius: '4px', 
                                                                                    border: '1px solid',
                                                                                    borderColor: Colors.black_60, 
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                                onClick={() => handleImageClick(image.image_name, index)}
                                                                            />
                                                                        </Box>
                                                                    ))}

                                        
                                    </Box>
                                                            </Box>
                                                        )}

                                                    </>
                                               )}
                                            </Box>

                                           
                                          </Box>
                                </Box>  
                         </Box>
                       
                                  </Box>   
                            </Box> 
                            <Box sx={{width:{ xs: '100% ', md: '30%' },justifyContent: 'flex-start', alignItems: 'center', display: 'flex' ,
                                flexDirection: 'column',border:{ xs: '1px solid #E6E6E6', md: '1px solid  #E6E6E6' },order: { xs: 1, md: 2 }, 
                                borderColor: Colors.grey_30, paddingTop:'2%', paddingBottom:'2%',borderRadius:'4px',
                                position:'relative', }}>
                                  <Box sx={{width: '100%',justifyContent: 'center', alignItems: 'center', display: 'flex' ,
                                   flexDirection: 'column',maxWidth:'90%',gap:'12px', }}>
                                    
                                    <SpMessage messageDetails={messageDetails} setMessageDetails={setMessageDetails}
                                    setSelectedMessageDetails={setSelectedMessageDetails} isView = {false}></SpMessage>
                                       

                            </Box>  

                            </Box>  
                     </Box> 
                     <Box sx={{width: '100%', height:{ xs: '100% ', md: '30%' }, justifyContent: 'center', alignItems:'stretch', display: 'flex' ,
                        flexDirection:{ xs: 'column', md:'row' } ,gap:'16px', maxWidth:{ xs: '90% ', md: '94%' },}}>
                             <Box sx={{width:{ xs: '100% ', md: '70%' } , height: '100%', justifyContent: 'center', alignItems:'stretch', display: 'flex' ,
                              flexDirection: { xs: 'column', md:'row' },gap:'16px', }}>
                                    <Box sx={{width:{ xs: '92% ', md: 'calc(100% / 2)' } ,  justifyContent: 'center', alignItems:'center', display: 'flex' ,
                                    flexDirection: 'column',border:'1px solid  #E6E6E6', padding:'16px', borderRadius:'4px', gap:'8px'}}>
                                        <Box sx={{width:'100%',height:'fit-content', justifyContent:'space-between', display:'flex'}}>                               
                                        <TypographyNormal sx={{fontSize:{ xs: '14px', md: '16px' }, fontWeight:500}} ><PlaceOutlinedIcon sx={{fontSize:'14px'}}/> Location : <span style={{fontWeight:400 }}>{serviceProviderDetails.city}</span>
                                        </TypographyNormal>
                                        <div
                                                onMouseEnter={() => setIsHoveredLocation(true)}
                                                onMouseLeave={() => setIsHoveredLocation(false)}
                                                >
                                                {isHoveredLocation ? (
                                                    <img onClick={handleEditLocationClick} src={editfill} alt="Edit Icon" style={{cursor:'pointer'}}/>
                                                ) : (
                                                    <img onClick={handleEditLocationClick} src={edit} alt="Edit Icon" style={{cursor:'pointer'}}/>
                                                )}
                                                </div>
                                        
                                    
                                        </Box>
                                        <Box sx={{width:'100%',height:'100%', justifyContent:'center', display:'flex', border:'1px solid  #E6E6E6',}}>
                                        <iframe id="map-iframe"
                                            width="100%"
                                            height="100%"
                                            frameborder="0"
                                            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs&q=${serviceProviderDetails.latitude},${serviceProviderDetails.longitude}&zoom=12&maptype=roadmap`}

                                        ></iframe> 
                                        </Box>
                                    </Box>
                                    <Box sx={{width: { xs: '92% ', md: 'calc(100% / 2)' },  justifyContent: 'left', alignItems:'left', display: 'flex' ,
                                    flexDirection: 'column',border:'1px solid  #E6E6E6',padding:'16px', borderRadius:'4px', gap:'12px'}}>
                                        <TypographyNormal sx={{fontSize:'20px', fontWeight:600}}>Your ratings</TypographyNormal>
                                        <TypographyNormal>Rating from people who use the service</TypographyNormal>
                                        <Box sx={{width:'100%',height:'100%', justifyContent:'center', display:'flex',gap:'8px',
                                            flexDirection:'column', alignItems:'center'}}>
                                        <TypographyNormal>{serviceProviderDetails.rating_count} Ratings</TypographyNormal>                                 
                                        <TypographyNormal sx={{fontSize:'34px', fontWeight:500}}>{calculateAverageRating(serviceProviderDetails.rating_details) }
                                        </TypographyNormal>
                                        <Rating
                                            name="readOnly"
                                            readOnly
                                            size="small"
                                            value={parseInt(calculateAverageRating(serviceProviderDetails.rating_details))}
                                            sx={{ color: '#FFC107' }}
                                            emptyIcon={<StarBorderIcon style={{ color: '#FFC107' }} fontSize="inherit" />}
                                            /> 
                                        </Box>                                    
                                       
                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                   
                                        {[5, 4, 3, 2, 1].map(rating => (
                                            <Box key={rating} sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap:'8px' }}>
                                                <Typography sx={{  textAlign: 'right' }}>
                                                    {rating}
                                                </Typography> 
                                                {console.log(ratingDetails)}                          
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={calculateRatingPercentage(ratingDetails[rating] || 0)}
                                                    sx={{
                                                        flex: 1, // Ensure it takes up available space
                                                        height: '12px',
                                                        borderRadius: '12px',
                                                        backgroundColor: Colors.grey_30,  
                                                        '& .MuiLinearProgress-bar': {
                                                            backgroundColor: Colors.primary_blue, 
                                                            borderRadius: '12px',
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        ))}
                                    </Box>

                                    </Box>
                             </Box>
                            <Box sx={{width:{ xs: '100% ', md:  '30%' },justifyContent: 'center', alignItems:'center', display: 'flex' , gap:'12px',
                            flexDirection: 'column',borderRadius:'4px',}}>
                            <Box sx={{width:{ xs: '100% ', md:   'calc(100% - 32px)'},height:'calc(100% / 3 )',justifyContent: 'center', alignItems:'center', display: 'flex' ,
                            flexDirection: 'column',border:'1px solid  #E6E6E6',padding:{ xs: '16px 0px ', md:'16px' }, borderRadius:'4px'}}>
                                <Box sx={{width: '100%',height:'100%',justifyContent: 'center', alignItems:'center', display: 'flex' ,
                                flexDirection: 'row',gap:'12px'}}>
                                    <TypographyNormal sx={{fontSize:{ xs: '14px', md: '20px' }, fontWeight:700, display: 'flex',
                                      alignItems: 'center',}} ><RemoveRedEyeOutlinedIcon sx={{color: '#FF7D6A', marginRight:'8px'}}/> {serviceProviderDetails.view_count}  Viewers
                                    </TypographyNormal>                                    
                                </Box>
                            <TypographyNormal>{serviceProviderDetails.view_count} people are visited your profiles</TypographyNormal>                           
 
                            </Box>
                            <Box sx={{width: { xs: '100% ', md:   'calc(100% - 32px)'},height:'calc(100% / 3 )',justifyContent: 'center', alignItems:'center', display: 'flex' ,
                            flexDirection: 'column',border:'1px solid  #E6E6E6',padding:{ xs: '16px 0px ', md:'16px' }, borderRadius:'4px'}}>
                                <Box sx={{width: '100%',height:'100%',justifyContent: 'center', alignItems:'center', display: 'flex' ,
                                flexDirection: 'row',gap:'12px'}}>
                                    <TypographyNormal sx={{fontSize:{ xs: '14px', md: '20px' }, fontWeight:700, display: 'flex',
                                      alignItems: 'center',}} ><ShareOutlinedIcon sx={{color: '#FF7D6A', marginRight:'8px',}}/> {serviceProviderDetails.share_count}  Shares
                                    </TypographyNormal>                                    
                                </Box>
                            <TypographyNormal>{serviceProviderDetails.share_count} users shared your profiles </TypographyNormal>                           
 
                            </Box>
                            <Box sx={{width: { xs: '100% ', md:   'calc(100% - 32px)'},height:'calc(100% / 3 )',justifyContent: 'center', alignItems:'center', display: 'flex' ,
                            flexDirection: 'column',border:'1px solid  #E6E6E6',padding:{ xs: '16px 0px ', md:'16px' }, borderRadius:'4px',
                            marginBottom:{ xs: '16px', md:'0px' }}}>
                                <Box sx={{width: '100%',height:'100%',justifyContent: 'center', alignItems:'center', display: 'flex' ,
                                flexDirection: 'row',gap:'12px'}}>
                                    <TypographyNormal sx={{fontSize:{ xs: '14px', md: '20px' }, fontWeight:700, display: 'flex',
                                      alignItems: 'center',}} ><MessageOutlinedIcon sx={{color: '#FF7D6A', marginRight:'8px',}}/> {serviceProviderDetails.message_count}  Quotes
                                    </TypographyNormal>                                    
                                </Box>
                            <TypographyNormal>{serviceProviderDetails.message_count} people requested quote with you </TypographyNormal>                           
 
                            </Box>
 
                            </Box>
                     </Box>  
                 
                

            </Box>
            
            <Footer currentPageFooter={"Home"}></Footer>
            </Grid>
            {isCompleteProfile && <Box sx={{
                            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            display: 'flex',alignItems: 'center',justifyContent: 'center',zIndex: '2',
                        }}>
                            
                            <Box sx={{
                                width: '100%', height: '100%', gap: '5px',
                                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                                display: 'flex', flexDirection: 'column',
                            }}>
                                
                                <Box
                                    sx={{width: { md: '35%', sm: '35%', xs: '85%' },height: 'fit-content',
                                        backgroundColor: Colors.white, border: '1px solid',
                                        borderColor: Colors.white, borderRadius: '4px ',boxShadow: 'none',
                                        boxSizing: 'border-box',zIndex: '3', alignItems: 'center', 
                                    }}>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                                        justifyContent: 'center', alignItems: 'center', height: '100%',
                                    }}>
                                          <Box sx={{
                                                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',paddingTop:'7%',
                                                justifyContent: 'center', height: '86%',maxWidth:'80%',
                                                paddingBottom:'7%'
                                             }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column', width: '100%', gap: '8px',
                                            justifyContent: 'center', height: '100%', alignItems: 'center',
                                        }}>
                                            <TypographySubHeading sx={{ fontSize:'16px', lineHeight:'18px' }}>
                                            Welcome!                                            
                                            </TypographySubHeading>
                                            <TypographySubHeading sx={{ fontSize:'14px', fontWeight:500, lineHeight:'18px' }}>                                            
                                            Finish setting up your profile
                                            </TypographySubHeading>

                                            <TypographyNormal sx={{ paddingTop:'8px' }}>
                                            Complete your profile by adding skills and location to ensure visibility and local matching. Quick, essential step for better client connections.
                                            </TypographyNormal>

                                           <ButtonBlue sx={{marginTop:'16px', width:'50%'}} onClick={CompleteProfileButtonClick}>Complete profile</ButtonBlue>
                                        </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
            </Box>} 

            {isShare && <Box sx={{
                            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            display: 'flex',alignItems: 'center',justifyContent: 'center',zIndex: '2',

                        }}>
                            <Box sx={{
                                width: '100%', height: '100%', gap: '5px',
                                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                                display: 'flex', flexDirection: 'column',
                            }}>
                                <Box sx={{
                                    width: { md: '35%', sm: '35%', xs: '85%' }, height: '36px',
                                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                                }}>
                                     <IconButton onClick={handleCloseSharePopUp} sx={{
                                    position: 'fixed', width: '36px', height: '36px',}} >
                                        <CloseOutlinedIcon sx={{ width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                        width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                        background:'none'},}} ></CloseOutlinedIcon>
                                    </IconButton>
                                </Box>
                                <Box
                                    sx={{width: { md: '35%', sm: '35%', xs: '85%' },height: 'fit-content',
                                        backgroundColor: Colors.white, border: '1px solid',
                                        borderColor: Colors.white, borderRadius: '4px ',boxShadow: 'none',
                                        boxSizing: 'border-box',zIndex: '3', alignItems: 'center', 
                                    }}>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                                        justifyContent: 'center', alignItems: 'center', height: '100%',
                                    }}>
                                          <Box sx={{
                                                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',paddingTop:'7%',
                                                justifyContent: 'center', alignContent: 'center', height: '86%',maxWidth:'86%',
                                                paddingBottom:'7%'
                                             }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column', width: '100%', gap: '8px',
                                            justifyContent: 'center', alignContent: 'center', height: '100%', alignItems: 'stretch',
                                        }}>
                                            <TypographySubHeading sx={{textAlign: 'left' }}>
                                            Social Share
                                            </TypographySubHeading>

                                            <TypographyNormal sx={{textAlign: 'left', paddingTop:'8px' }}>
                                            Copy below link
                                            </TypographyNormal>

                                            <Box sx={{
                                                width: 'calc(100%-32px)', height: '28px', border: '1px solid', padding: '10px 16px 10px 16px',
                                                borderColor: Colors.black_60, borderRadius: '4px', display: 'flex', flexDirection: 'row',
                                            }}>
                                                <InputBase
                                                    value={`${FRONTEND_URL}/sp-profile/${serviceProviderDetails.service_provider_code}`}
                                                    style={{ flex: 1, border: 'none', outline: 'none', background: 'transparent' }}
                                                    inputProps={{
                                                        style: {
                                                            fontFamily: 'Roboto', fontSize: '16px',
                                                            '::placeholder': {
                                                                fontFamily: 'Roboto', fontSize: '16px',
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box sx={{
                                                width: 'calc(100%)', height: '100%', paddingTop:'8px',
                                                display: 'flex', flexDirection: 'row', justifyContent: 'right',
                                            }}
                                            >
                                                <ButtonBlue sx={{ width: 'fit-content',gap:'4px' }} onClick={handleCopyButtonClick}><ContentCopyIcon sx={{fontSize:{ xs: '12px', md: '18px' }}}/>Copy link</ButtonBlue>
                                            </Box>
                                        </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                </Box>}

             
              {isViewCard && <Box sx={{
                position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '12',

            }}>
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box sx={{
                        width: { md: '40%', sm: '40%', xs: '85%' }, height: '36px',
                        justifyContent: 'right', alignItems: 'right', display: 'flex',
                    }}>
                        <IconButton onClick={handleCloseViewCardPopUp} sx={{
                            position: 'fixed', width: '36px', height: '36px',
                        }} >
                            <CloseOutlinedIcon sx={{
                                width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                    width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                    background: 'none'
                                },
                            }} ></CloseOutlinedIcon>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            width: { md: '40%', sm: '40%', xs: '85%' }, height: 'fit-content',
                            backgroundColor: Colors.white, border: '1px solid',
                            borderColor: Colors.white, borderRadius: '4px ', boxShadow: 'none',
                            zIndex: '3', alignItems: 'center',
                        }}>
                        <Box sx={{
                            width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px ',
                            flexDirection: 'column', border: { xs: '0px solid #E6E6E6', md: '1px solid  #E6E6E6' }, borderColor: Colors.grey_30, gap: '16px'
                        }}>
                            <Box sx={{
                                width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px ', 
                                flexDirection: 'column', background: { xs: 'linear-gradient(to bottom, #E6E6E6 110px, white 8%)', md: 'linear-gradient(to bottom, #E6E6E6 140px, white 10%)' }, gap: '16px',
                            }}>
                                <Box sx={{
                                    width: '100%', height: '94%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                                    flexDirection: 'column', gap: { xs: '8px', md: '24px' }, paddingBottom: '5%',
                                }}>
                                    <Box sx={{
                                        width: '100%', height: 'fit-content', justifyContent: 'center', 
                                        alignItems: 'center', display: 'flex', flexDirection: 'column', paddingBottom: { xs: '16px', md: '0px' },
                                        borderRadius: '4px',
                                    }}>
                                <Box sx={{
                                width: '100%', height: 'fit-content', justifyContent: 'flex-start', alignItems: 'center', display: 'flex', borderRadius: '4px ',
                               paddingTop:'5%',  flexDirection: 'column', background: { xs: 'linear-gradient(to bottom, #E6E6E6 70%, transparent 70%)', md: 'linear-gradient(to bottom, #E6E6E6 84%, transparent 84%)' }, 
                               gap: '16px',
                            }}>

                                        <Box sx={{
                                            width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex',
                                            flexDirection: 'column',  maxWidth: { xs: '90%', md: '92%' },
                                        }}>
                                            <Box sx={{
                                                    display: 'flex', flexDirection: 'row', width: '100%', height: '24px', justifyContent: 'right',
                                                    alignItems: 'center', 
                                                }}>
                                                    <img src={logo} alt="Logo"
                                                     style={{ width:  'fit-content' , height: '24px',}} />
                                                </Box>
                                                <Box sx={{
                                            width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex',
                                            flexDirection: 'row', gap:  { xs: '12px', md: '32px'}, 
                                        }}>
                                            <Box sx={{
                                                height: '100%', width: { xs: '90px', md: '140px' }, justifyContent: 'center', alignItems: 'flex-start',
                                                display: 'flex', flexDirection: 'row', marginTop: { xs: '0%', md: '-5%' }
                                            }}>
                                                <Box sx={{ position: 'relative', display: 'inline-block', width:'100%'}}>                                                   
                                                    {serviceProviderDetails.profile_image ? (
                                                        <Avatar alt="Profile"
                                                            src={IMG_URL + serviceProviderDetails.profile_image}
                                                            sx={{ width: { xs: '80px', md: '100%' }, height: { xs: '80px', md: '140px' } }} />
                                                    ) : (
                                                        <Avatar  {...stringAvatar(serviceProviderDetails.business_name)}
                                                            sx={{ width: { xs: '80px', md: '100%' }, height: { xs: '80px', md: '140px' }, fontSize: { xs: '32px', md: '58px' }, bgcolor: '#4C50AE' }} />

                                                    )}
                                                    {serviceProviderDetails.rating_details.length > 0 &&
                                                        <Avatar sx={{
                                                            position: 'absolute', bottom: { xs: 0, md: 12 }, right: { xs: 10, md: 1 }, width: { xs: '24px', md: '36px' }, height: { xs: '24px', md: '36px' },
                                                            backgroundColor: Colors.primary_blue, color: 'white',
                                                        }} >
                                                            <TypographyNormal sx={{ color: Colors.white, fontSize: { xs: '12px', md: '14px' } }}>
                                                                {calculateAverageRating(serviceProviderDetails.rating_details)}
                                                            </TypographyNormal>
                                                        </Avatar>}
                                                </Box>

                                            </Box>
                                            <Box sx={{
                                                height: '100%', width: { xs: 'calc(100% - 90px)', md: 'calc(100% - 140px)' }, justifyContent: 'flex-start',
                                                alignItems: 'flex-start', display: 'flex', flexDirection: 'column', gap: '8px',
                                            }}>
                                               

                                                <Box sx={{
                                                    width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'left', display: 'flex',
                                                    flexDirection: 'column', gap: { xs: '4px', md: '0px' },paddingTop:'2%',  maxWidth: '97%'
                                                }}>

                                                    <TypographySubHeading sx={{
                                                        fontSize: { xs: '14px', md:  '24px' }, fontWeight: 500, whiteSpace: 'nowrap', overflow: 'hidden',
                                                        textOverflow: 'ellipsis', maxWidth: { xs: '98%', md:  '95%' } 
                                                    }}>{serviceProviderDetails.business_name}</TypographySubHeading>
                                                    <TypographyNormal sx={{
                                                        fontSize: { xs: '12px', md: '16px' }, lineHeight: { xs: '14px', md: '24px' },
                                                        color: Colors.black_80, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth:{ xs: '98%', md:  '95%' } 
                                                    }}> {serviceTabName.map(detail => detail).join(', ')}</TypographyNormal>
                                                    <TypographyNormal sx={{
                                                        fontSize: { xs: '12px', md: '16px' }, lineHeight: { xs: '14px', md: '24px' },
                                                        color: Colors.black_80, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: { xs: '98%', md:  '95%' }  
                                                    }}> {serviceProviderDetails.city}, {serviceProviderDetails.province}, {serviceProviderDetails.country}</TypographyNormal>
                                                </Box>


                                            </Box>
                                            </Box>
                                        </Box>
                                        </Box>
                                        <Box sx={{
                                            width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center',  display: 'flex',
                                            flexDirection: 'row', gap: { xs: '12px', md: '32px'}, maxWidth: { xs: '90%', md: '90%' },
                                        }}>
                                            <Box sx={{ height: '100%', width: { xs: '90px', md: '140px' }, justifyContent:'center',
                                            display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                                <Box
                                                    component="img"
                                                    src={qrCodeUrl}
                                                    alt="qrcode"
                                                    sx={{ width: '60%', hight:'60%'}} // Ensure image scales with the width
                                                />
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <TypographyNormal
                                                        sx={{
                                                            width: '100%', // Ensure text has the same width as the image
                                                            textAlign: 'center', // Center the text
                                                            overflow: 'hidden', // Hide overflowing text
                                                            textOverflow: 'ellipsis', // Add ellipsis if text overflows
                                                            whiteSpace: 'nowrap', // Prevent text from wrapping
                                                            letterSpacing: '0.1em' // Add space between each letter
                                                        }}
                                                    >
                                                        {serviceProviderDetails.service_provider_code}
                                                    </TypographyNormal>
                                                </Box>
                                            </Box>


                                            <Box sx={{
                                                height: '100%', width:{ xs: 'calc(100% - 90px)', md: 'calc(100% - 140px)' }, justifyContent: 'center',
                                                alignItems: 'left', display: 'flex', flexDirection: 'column', gap: '8px',
                                            }}>
                                                <Box sx={{
                                                    width: '100%', height: 'fit-content', justifyContent: 'space-between',
                                                    alignItems: 'flex-start', display: 'flex',
                                                    flexDirection: 'column', gap: '8px',
                                                    marginTop: { xs: '-0%', md: '-0%' }
                                                }}>
                                                    <Box
                                                        sx={{
                                                            width: '100%', height: 'fit-content', justifyContent: 'flex-start',
                                                            alignItems: 'flex-start', display: 'flex', flexDirection: { xs: 'column', md: 'column' },
                                                            flexWrap: 'wrap', gap: '6px'
                                                        }}>
                                                        {[
                                                            { label: 'Contact person', value: serviceProviderDetails.contact_person },
                                                            { label: 'Phone number', value: `+${serviceProviderDetails.country_code} ${serviceProviderDetails.phone_number}` },
                                                            { label: 'Email id', value: serviceProviderDetails.email },
                                                            { label: 'Website', value: serviceProviderDetails.website },
                                                            { label: 'Language', value: serviceProviderDetails.languages },

                                                        ]
                                                            .filter(item => item.value)
                                                            .map((item, index, arr) => (
                                                                <Box
                                                                    key={index}
                                                                    sx={{
                                                                        display: 'flex', alignItems: 'center', gap: { xs: '4px', md: '12px' }, flexDirection: 'row',
                                                                        width: arr.length === 1 ? '100%' : { xs: '100%', md: '100%' },
                                                                        justifyContent: 'flex-start'
                                                                    }}>
                                                                    <TypographyNormal sx={{ minWidth: '12ch', fontWeight: 600, fontSize: { xs: '12px', md: '14px' } }}>{item.label}</TypographyNormal>
                                                                    <TypographyNormal>:</TypographyNormal>
                                                                    <TypographyNormal
                                                                        sx={{ maxWidth: { xs: '98%', md: '95%' }, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: { xs: '12px', md: '14px' } }}>
                                                                        {item.value}
                                                                    </TypographyNormal>
                                                                </Box>
                                                            ))}
                                                    </Box>
                                                </Box>


                                            </Box>

                                        </Box>
                                    </Box>




                                </Box>
                            </Box>

                        </Box>


                    </Box>
                </Box>
            </Box>}

            {isImagePopUp && <Box sx={{
                            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            display: 'flex',alignItems: 'center',justifyContent: 'center',zIndex: '2',

                        }}>
                            <Box sx={{
                                width: '100%', height: '100%', gap: '5px',
                                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                                display: 'flex', flexDirection: 'column',
                            }}>
                                <Box sx={{
                                    width: { md: '35%', sm: '35%', xs: '85%' }, height: '36px',
                                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                                }}>
                                     <IconButton onClick={handleCloseImagePopUp} sx={{
                                    position: 'fixed', width: '36px', height: '36px',zIndex:50}} >
                                        <CloseOutlinedIcon sx={{ width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                        width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                        background:'none'},}} ></CloseOutlinedIcon>
                                    </IconButton>
                                </Box>
                                <Box
                                    sx={{width: { md: '35%', sm: '35%', xs: '85%' },height: { md: '50%', sm: '50%', xs: '65%' },
                                        backgroundColor: Colors.white, border: '1px solid',
                                        borderColor: Colors.white, borderRadius: '4px ',boxShadow: 'none',
                                        boxSizing: 'border-box',zIndex: '3', alignItems: 'center', 
                                    }}>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                                        justifyContent: 'center', alignItems: 'center', height: '100%',
                                    }}>
                                         <Box sx={{
                                display: 'flex', flexDirection: 'row', width: '100%', paddingTop: '14%',
                                justifyContent: 'center', alignContent: 'center', height: '37%', maxWidth: '98%',
                                paddingBottom: '14%',
                            }}>

                                <IconButton
                                    onClick={handlePreviousImage}
                                    sx={{ color: Colors.primary_blue, width: 'fit-content', background: 'none', '&: hover': { background: 'none', } }}>
                                    <ArrowBackIosIcon />
                                </IconButton>


                                {selectedServiceData[0].image_details[currentImageIndex] && (
                                    <img
                                        src={IMG_URL + selectedServiceData[0].image_details[currentImageIndex].image_name}
                                        alt="Selected_image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',                                          
                                            
                                        }} 
                                    />
                                )}



                                <IconButton
                                    onClick={handleNextImage}
                                    sx={{ color: Colors.primary_blue, width: 'fit-content', background: 'none', '&: hover': { background: 'none', } }}>
                                    <ArrowForwardIosIcon />
                                </IconButton>

                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', flexWrap: 'wrap',maxWidth:'82%', 
                                justifyContent:'left' ,alignItems:'center', width:'100%',height:'13%', }}>
                                {selectedServiceData[0].image_details.map((image, index) => (
                                    <Box key={index} sx={{ width: { xs: '10%', md: '10%' }, height: '100%',}}>
                                        {console.log(IMG_URL + image.image_name)}
                                        {console.log(index)}
                                        <img
                                            src={IMG_URL + image.image_name}
                                            alt="Projects"
                                            style={{
                                                width: '100%', height: '100%', objectFit: 'cover',
                                                borderRadius: '4px', border: '1px solid',
                                                borderColor:currentImageIndex === index ? Colors.primary_blue : Colors.black_60, cursor: 'pointer'
                                            }} onClick={() => handleImagePopUpClick(image.image_name, index)}
                                        />
                                    </Box>
                                ))}
                            </Box>
                                    </Box>
                                </Box>
                            </Box>
                </Box>}
        </> 
    )
} export default SpLanding;