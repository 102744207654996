const Colors = {

    /*** Primary colors ***/
    dark_purple: '#503259',
    blue: '#4C50AE',
    orange: '#FF7D6A',
    dark_lavender: '#B07CC6',
    lavender: '#DFC6EA',

    /*** Hovering colors ***/
    hovering_blue: '#383B83',
    hovering_grey: '#F0F0F0',
    
    

    /*** System colors ***/
    red: '#FF4105',
    green: '#20C925',
    blue_6: '#2D96D1',
    blue_10: '#758BFD',
    primary_blue: '#5D5FEF',
    secondary_blue: '#2A2483',
    /*** black shades ***/
    black_90: '#313131',
    black_80: '#313131',
    black_70: '#616161',
    black_60: '#C5C5C5',

    /*** light shades ***/
    grey_30: '#E6E6E6',
    grey_20: '#F9F9F9',
    white_80: '#F5F5F5',
    white: '#FFFFFF',
  };
  
  export default Colors;