import React from 'react';
import { Navigate } from 'react-router-dom';

const OperationTeamProtectedRoute = ({ element, ...rest }) => {
  const operationTeamToken = localStorage.getItem('operationTeamToken');

  return operationTeamToken ? (
    element
  ) : (
    <Navigate to="/operation-team-login" replace />
  );
};

export default OperationTeamProtectedRoute;
