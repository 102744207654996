import React from "react"
import Typography from '@mui/material/Typography';
import Colors from "./Colors";


 function TypographyHeading({ onClick, sx, ...props }){
    
    return(
        <Typography component="h1"   
            sx={{fontFamily:'Roboto', fontWeight:500, fontSize: { xs: '20px', md:  '32px' }, color: Colors.black_90 , ...sx,}}
                 {...props} onClick={onClick}>            
        </Typography>
    )
}

export default TypographyHeading;

