import React from "react"
import Typography from '@mui/material/Typography';
import Colors from "./Colors";


 function TypographySubHeading({ onClick, sx, ...props }){
    
    return(
        <Typography  component="h1"  
            sx={{fontFamily:'Roboto', fontWeight:700, fontSize:{ xs: '18px', md: '24px' }, color: Colors.black_90,
             lineHeight:{ xs: '18px', md: '28px' }, ...sx, }}
                 {...props} onClick={onClick}>            
         </Typography>
    )
}

export default TypographySubHeading;

