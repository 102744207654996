import React from 'react';
import { Navigate } from 'react-router-dom';

const UserProtectedRoute = ({ element, ...rest }) => {
  const userToken = localStorage.getItem('userToken');

  return userToken ? (
    element
  ) : (
    <Navigate to="/" replace />
  );
};

export default UserProtectedRoute;
