
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button, IconButton , InputAdornment} from "@mui/material";
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading  from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Colors from '../Components/Colors';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import popupbg from '../Images/popup.png'
import whitelogo from '../Images/whitelogo.svg'
import ButtonBlue from '../Components/ButtonBlue';
import ButtonWhite from '../Components/ButtonWhite';
import OTPInput from '../Components/OTPInput';
import TypographyError from '../Components/TypographyError';
import axios from "axios";
import { API_URL } from '../Components/Constants';




function ForgotOtpPopUp(props){

    const {setPopupType, popupType} = props
    
    const [isOtpSuccess, setIsOtpSuccess] = useState(false);

    
    const handleCloseForgotPasswordOtpPopUp = () => {
        setPopupType(null)     
    }

    const savedData = JSON.parse(localStorage.getItem('forgotpassword'));
  

    useEffect(() => {
        if (isOtpSuccess) {  
            setPopupType('changePassword') 
        }
    }, [isOtpSuccess]);
    

  
    


return(
<Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>

        {popupType === 'forgototp' && <Box sx={{
            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',alignItems: 'center',justifyContent: 'center',zIndex: '1500',

        }}>
            <Box sx={{
                width: '100%', height: '100%', gap: '5px',
                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                display: 'flex', flexDirection: 'column',
            }}>
                <Box sx={{
                    width: { md: '75%', sm: '75%', xs: '85%' }, height: '36px',
                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                }}>
                        <IconButton onClick={handleCloseForgotPasswordOtpPopUp} sx={{
                    position: 'fixed', width: '36px', height: '36px',}} >
                        <CloseOutlinedIcon sx={{ width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                        width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                        background:'none'},}} ></CloseOutlinedIcon>
                    </IconButton>
                </Box>
                <Box sx={{width: { md: '75%', sm: '75%', xs: '85%' },height: 'fit-content',
                        backgroundColor: Colors.white, border: '1px solid',
                        borderColor: Colors.white, borderRadius: '4px ',boxShadow: 'none',
                        zIndex: '3', alignItems: 'center', 
                    }}>

                 
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', width: '100%', 
                                justifyContent: 'center', alignContent: 'center', height: '100%'}}>
                                    
                                    <Box sx={{
                                        display: {xs: 'none', md:'flex' }, flexDirection: 'column', width: '45%', gap: '8px',
                                        justifyContent: 'center',  height: '100%', alignItems: 'center', borderRadius: '4px 0px 0px 4px',
                                        backgroundImage: `url(${popupbg})`, backgroundRepeat: 'no-repeat', border:'1px solid', 
                                        backgroundSize: '100% 100%',backgroundPosition: 'center',borderColor: Colors.primary_blue,}}>
                                              <Box sx={{
                                                display: 'flex', flexDirection: 'column', width: '100%', gap: '12px',
                                                justifyContent: 'center', maxWidth:'75%',alignItems: 'left', }}>
                                                <TypographyNormal sx={{fontSize:'16px', color: Colors.white_80}}>Welcome</TypographyNormal>
                                                <Box sx={{
                                                display: 'flex', flexDirection: 'row',  width: '200px', height: '32px',alignContent:'flex-start',
                                                justifyContent: 'flex-start',alignItems: 'flex-start', }}>
                                                <img src={whitelogo} alt="logo" style={{width: '100%', height: '100%', }}/>
                                                </Box>
                                                <TypographyNormal sx={{ color: Colors.white_80}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco</TypographyNormal>
                                             </Box>
                                    </Box> 
                                  


                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: {xs: '100%', md:'55%' }, gap: '8px',
                                        justifyContent: 'center',  height: '100%', alignItems: 'center', backgroundColor: Colors.white, border: '1px solid',
                                        borderColor: Colors.white,}}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '12px',
                                            paddingTop:'25%',paddingBottom:'25%',
                                            height:'100%', justifyContent: 'center', maxWidth:'75%',alignItems: 'left',}}>

                                            <OTPInput phoneNumber={savedData.phoneNumber} countryCode={savedData.countryCode} setIsOtpSuccess={setIsOtpSuccess}></OTPInput>


                                            </Box>
                                    </Box>
                            </Box>
                    
                </Box>
            </Box>
        </Box>}

</Grid>
)
}
export default ForgotOtpPopUp;