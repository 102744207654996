
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button } from "@mui/material";
import Header from '../Components/Header';
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading  from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Footer from "../Components/Footer";
import Colors from '../Components/Colors';
import MailIcon from '@mui/icons-material/Mail';
import SettingsSidebar from '../Components/SettingsSidebar';
import ButtonBlue from '../Components/ButtonBlue';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ButtonWhite from '../Components/ButtonWhite';
import IconButton from '@mui/material/IconButton';
import axios from "axios";
import { API_URL, IMG_URL } from '../Components/Constants';

function SpDeleteAccount(){

    const [isDelete, setIsDelete] = useState(false);

    
    const handleDeleteClick = () => {
        setIsDelete(true);     
    }

    const handleCloseIsDeletePopUp = () => {
        setIsDelete(false);     
    }

    const handleIsDeleteButtonClick = async() => {
        try { 
            const response = await axios.post(`${API_URL}/delete_sp_account`, {                    
                spToken: localStorage.getItem("spToken")
            }); 
            console.log(response)
            if(response.data.success === true){
                setIsDelete(false)
                localStorage.removeItem("spToken") 
                window.location.href = "/"  
            }
            
        } catch (error) {
            console.error('Error fetching data:', error);
            
        }      
    }
    
    
    
    return(
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px',}}>

        <Header></Header>
    
           <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', 
           justifyContent: 'center', height: '100%', alignItems:'center',}}>
    
                <Box sx={{display: 'flex', flexDirection:{ xs: 'column ', md: 'row' } ,
                 width: '100%', paddingBottom:'0%',gap:{ xs: '0px', md: '32px' },  
                    justifyContent: 'center', height: '100%', alignItems:'flex-start',  maxWidth:{ xs: '90% ', md: '94%' }, paddingTop:'2%',}}>
    
                        <Box sx={{display: 'flex', flexDirection: 'row', width:{ xs: '100% ', md: '25%' } , 
                        justifyContent: 'flex-start', height: '100%', alignItems:'center'}}>
                                <SettingsSidebar currentPage={"Delete account"}></SettingsSidebar>
                        </Box>
    
                        <Box sx={{display: 'flex', flexDirection: 'column', width:{ xs: '100% ', md: 'calc(75% - 32px)' } ,
                         borderLeft:{ xs: '0px solid #E6E6E6', md:'1px solid #E6E6E6' }, minHeight:'60vh' ,marginTop:{md:'3.5%', xs:0},
                        justifyContent: 'flex-start', height: '100%', alignItems:'center', paddingLeft: { xs: '0px ', md:'32px' }}}>
                                            
                                <Box sx={{display: 'flex', flexDirection: 'column', width:'100%', gap: '8px',
                                justifyContent: 'center',  height: '100%', alignItems: 'center', }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '8px',
                                         height:'96%', justifyContent: 'flex-start', alignItems: 'flex-start',paddingBottom:'2%',}}>
                                           
                                            <TypographyNormal>Are you sure you want to delete your account? This action is permanent.</TypographyNormal>
                                           
                                            <TypographySubHeading sx={{fontSize: '18px', fontWeight:600, }}>Please note:</TypographySubHeading>
                                            <TypographyNormal>By clicking "Delete Account," your account will be disabled for 30 days. During this period, you will no longer have access to your account, but don’t worry—your data is still safe. If you change your mind, you can re-enable your account at any time within these 30 days, and everything will be restored as you left it.</TypographyNormal>
                                             <TypographyNormal>However, if you do not reactivate your account within this 30-day period, it will be permanently deleted, and all your data, settings, and personal information will be lost. This action is irreversible after the 30th day, so please make sure you’re ready to proceed.</TypographyNormal>       
                                        <Box sx={{
                                        width: '100%', flexDirection: 'row', justifyContent: 'right', display: 'flex',
                                        alignItems: 'center', gap: { xs: '8px', md: '16px' }, paddingTop: { xs: '16px', md: '32px' }
                                         }}>     
                                             <ButtonBlue onClick={handleDeleteClick} sx={{width:{md:'25%', xs:'40%'}}}>Delete account</ButtonBlue> 
                                         </Box>
                                        </Box>
                                </Box>
    
                        </Box>
                </Box>
          </Box>
                        
         <Footer ></Footer>

          {isDelete && <Box sx={{
                            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            display: 'flex',alignItems: 'center',justifyContent: 'center',zIndex: '2',

                        }}>
                            <Box sx={{
                                width: '100%', height: '100%', gap: '5px',
                                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                                display: 'flex', flexDirection: 'column',
                            }}>
                                <Box sx={{
                                    width: { md: '25%', sm: '25%', xs: '85%' }, height: '36px',
                                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                                }}>
                                     <IconButton onClick={handleCloseIsDeletePopUp} sx={{
                                    position: 'fixed', width: '36px', height: '36px',}} >
                                        <CloseOutlinedIcon sx={{ width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                        width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                        background:'none'},}} ></CloseOutlinedIcon>
                                    </IconButton>
                                </Box>
                                <Box
                                    sx={{width: { md: '25%', sm: '25%', xs: '85%' },height: 'fit-content',
                                        backgroundColor: Colors.white, border: '1px solid',
                                        borderColor: Colors.white, borderRadius: '4px ',boxShadow: 'none',
                                        boxSizing: 'border-box',zIndex: '3', alignItems: 'center', 
                                    }}>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                                        justifyContent: 'center', alignItems: 'center', height: '100%',
                                    }}>
                                          <Box sx={{
                                                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',paddingTop:'13%',
                                                justifyContent: 'center', alignContent: 'center', height: '86%',maxWidth:'75%',
                                                paddingBottom:'13%'
                                             }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column', width: '100%', gap: '20px',
                                            justifyContent: 'center', alignContent: 'center', height: '100%', alignItems: 'center',
                                        }}>
                                            <TypographySubHeading sx={{fontSize:'18px' }}>
                                            Delete account
                                            </TypographySubHeading>

                                            <TypographyNormal sx={{textAlign: 'center',  }}>
                                            Are you sure do you want to permenently delete this Account?
                                            </TypographyNormal>
                                            <Box sx={{ width:'100%',flexDirection: 'row',justifyContent:'center', display:'flex',
                                                    alignItems:'center',  gap:{xs:  '8px', md: '16px' } }}>                                            
                                                    <ButtonWhite onClick={handleCloseIsDeletePopUp}>Cancel</ButtonWhite>
                                                    <ButtonBlue onClick={handleIsDeleteButtonClick} >Delete</ButtonBlue>
                                                </Box>
                                           
                                        </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
            </Box>}  
       
    </Grid>
    )
}
export default SpDeleteAccount;