import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button } from "@mui/material";
import Header from '../Components/Header';
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading  from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Footer from "../Components/Footer";
import Colors from '../Components/Colors';
import MailIcon from '@mui/icons-material/Mail';
import axios from "axios";
import { API_URL } from '../Components/Constants';

function PrivacyPolicy(){

    const [termsDetails, setTermsDetails] = useState([]);

    useEffect(() => {
        const fetchTermseData = async () => {
            try {
                const response = await axios.post(`${API_URL}/select_terms`, {
                    title: 'privacy policy'
                });
                console.log(response.data.result)
                setTermsDetails(response.data.result);
            } catch (error) {
                console.error('Error fetching data:', error);
                setTermsDetails('Failed to load Terms and condition. Please try again later.');
            }
        };
        fetchTermseData();

    }, []);
    
    return(
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>

        <Header></Header>

        <Box sx={{
            width: '100%', height: { xs: '95%', md: '97.5%' },justifyContent: 'center', alignItems: 'center', display:  'flex', 
            flexDirection: 'column',gap:'24px', paddingTop:{ xs: '5%', md: '2.5%' }}}>
                 <Box sx={{width: '100%', height: '95%', justifyContent: 'center', alignItems: 'left', display:  'flex', 
                      flexDirection: 'column',maxWidth:'90%',gap:'32px', paddingBottom:'5%', minHeight:'650px'}}>
                   
                  { termsDetails.length > 0 && <div dangerouslySetInnerHTML={{ __html: termsDetails[0].content }} />} 

                    
        </Box>
       
        </Box>



        <Footer currentPageFooter= {"Privacy policy"}></Footer>
        </Grid>
    )
}
export default PrivacyPolicy;