import { Badge, Box, Button } from "@mui/material";
import TypographyNormal from './TypographyNormal';
import TextfieldNormal from "./TextfieldNormal";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useEffect, useState, useRef } from "react";
import Colors from "./Colors";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import ButtonWhite from './ButtonWhite';
import ButtonBlue from './ButtonBlue';
import axios from "axios";
import { API_URL } from './Constants';
import TypographyHeading from "./TypographyHeading";
import TypographySubHeading from "./TypographySubHeading";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';


function SpMessage(props){ 
  const { messageDetails, setMessageDetails, setSelectedMessageDetails, isView } = props;
  console.log(messageDetails)
  const [isMsg, setIsMsg] = useState(false);   
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
  const [clickedMessageIndex, setClickedMessageIndex] = useState(null);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [selectedPhone, setSelectedPhone] = useState(null);
  const [search, setSearch] = useState('');
  const [unreadCount, setUnreadCount] = useState(null);

  useEffect(() => {
    //console.log(messageDetails)
    if(Array.isArray(messageDetails) && messageDetails.length > 0) {
      setIsMsg(true)   
    }
    else{
      setIsMsg(false)  
    }
     
   }, [messageDetails]);

   const [isReadDelete, setIsReadDelete] = useState(false); 

   const handleIconClick = (index, id, phno) => {
   // console.log(id)
    setIsReadDelete(!isReadDelete)
    setSelectedMessageIndex(index )
    setSelectedMessageId(id)
    setSelectedPhone(phno)
   };

   const [isDeletePopUp, setIsDeletePopUp] = useState(false); 

   const handleMenuItemClick = async(value, countryCode, phoneNo) => {  

    if (value === "read") {
      try {      
        const response = await axios.post(`${API_URL}/update_read_status`, {           
           spToken: localStorage.getItem("spToken"),
           countryCode: countryCode,
           phoneNo: phoneNo
          // msgId: selectedMessageId, 
             });
           // console.log ("response",response); 
            if(response.data.success === true)  {
              const updatedMessageDetails = messageDetails.map((message) => {
                if (message.user_phone_number === phoneNo && message.country_code === countryCode) {
                  return { ...message, read: 'read' };
                }
                return message;
              });
              
              // Update messageDetails state
              setMessageDetails(updatedMessageDetails);
            }
            } catch (error) {
            console.error('Error:', error.message);
            }       
        
     
    }
    if (value === "Unread") {
      try {      
        const response = await axios.post(`${API_URL}/update_unread_status`, {           
           spToken: localStorage.getItem("spToken"),
           countryCode: countryCode,
           phoneNo: phoneNo
             });
           // console.log ("response",response); 
            if(response.data.success === true)  {
              const updatedMessageDetails = messageDetails.map((message) => {
                if (message.user_phone_number === phoneNo && message.country_code === countryCode) {
                  return { ...message, read: 'Unread' };
                }
                return message;
              });
              
              // Update messageDetails state
              setMessageDetails(updatedMessageDetails);
            }
            } catch (error) {
            console.error('Error:', error.message);
            }       
        
     
    }
    if (value === "delete") {
      setIsDeletePopUp(true)
    }
    setIsReadDelete(false);
  }

  
  const handleDeletePopUpCancelButtonClick = () => {
    setIsDeletePopUp(false)
   };

   const handleDeletePopUpOkButtonClick = async() => {
    try {      
      const response = await axios.post(`${API_URL}/delete_message`, {           
        spToken: localStorage.getItem("spToken"),
         msgId: selectedMessageId,
         phno: selectedPhone 
           });         
          if(response.data.success === true)  {                 
            const updatedMessageDetails = messageDetails.filter((message, index) => index !== selectedMessageIndex); 
            if(Array.isArray(updatedMessageDetails) && updatedMessageDetails.length > 0) {   
            setMessageDetails(updatedMessageDetails);
            setIsDeletePopUp(false)
          }
          else{
            setIsDeletePopUp(false)
            setIsMsg(false)
          }
        }
          } catch (error) {
          console.error('Error:', error.message);
          }       
   };
   
   const handleAllRead = async() => {
    try {      
      const response = await axios.post(`${API_URL}/mark_all_as_read`, {           
        spToken: localStorage.getItem("spToken"),        
           });
         // console.log ("response",response); 
          if(response.data.success === true)  {
            const updatedMessageDetails = messageDetails.map((message, index) => {
              if (index === selectedMessageIndex) {
                return { ...message, read: 'read' };
              }
              return message;
            });
            // Update messageDetails state
            setMessageDetails(updatedMessageDetails);
          }
          } catch (error) {
          console.error('Error:', error.message);
          }     
   };


   const dropdownRef = useRef(null);
   const iconRef = useRef(null);

   const handleClickOutside = (event) => {
       if (
           dropdownRef.current &&
           !dropdownRef.current.contains(event.target) &&
           iconRef.current &&
           !iconRef.current.contains(event.target)
       ) {
           setIsReadDelete(false);
       }
   };

   useEffect(() => {
       if (isReadDelete) {
           document.addEventListener('mousedown', handleClickOutside);
       } else {
           document.removeEventListener('mousedown', handleClickOutside);
       }

       return () => {
           document.removeEventListener('mousedown', handleClickOutside);
       };
   }, [isReadDelete]);





const formatDateTime = (dateTimeString) => {
  const dateUTC = new Date(dateTimeString);

  // Check if dateUTC is valid
  if (isNaN(dateUTC.getTime())) {
    return 'Invalid Date'; // Return a default message for invalid dates
  }

  const timeOptions = {
    timeZone: 'America/Toronto',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const timeFormatter = new Intl.DateTimeFormat('en-CA', timeOptions);
  const formattedTime = timeFormatter.format(dateUTC).replace('.', '').toUpperCase();

  const dateInToronto = new Date(dateUTC.toLocaleString('en-US', { timeZone: 'America/Toronto' }));
  const day = String(dateInToronto.getDate()).padStart(2, '0');
  //const month = String(dateInToronto.getMonth() + 1).padStart(2, '0');
  const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  const month = monthNames[dateInToronto.getMonth()];
  const year = dateInToronto.getFullYear();

  return `${year}-${month}-${day} ${formattedTime}`;
};

const handleSearchChange = (event) => {
  setSearch(event.target.value);        
};


const filterMessages = () => {
  const lowercasedSearch = search.toLowerCase();

  // If search is empty, return all messages
  if (!lowercasedSearch) {
    return getRecentMessages(messageDetails);
  }

  // Filter messages based on the search value in specific fields
  const filteredMessages = messageDetails.filter(message => {
    const { type_of_service, location, country_code, user_phone_number } = message;

    return (
      (type_of_service && type_of_service.toLowerCase().includes(lowercasedSearch)) ||
      (location && location.toLowerCase().includes(lowercasedSearch)) ||
      (country_code && country_code.toLowerCase().includes(lowercasedSearch)) ||
      (user_phone_number && user_phone_number.toLowerCase().includes(lowercasedSearch))
    );
  });

  return getRecentMessages(filteredMessages);
};

// Helper function to get the most recent message per phone number
const getRecentMessages = (messages) => {
  const messageMap = new Map();

  messages.forEach(message => {
    const existingMessage = messageMap.get(message.user_phone_number);

    // If no message for this phone number or if the current message is more recent, update the map
    if (!existingMessage || new Date(message.send_time) > new Date(existingMessage.send_time)) {
      messageMap.set(message.user_phone_number, message);
    }
  });

  // Return the values (most recent messages) as an array
  return Array.from(messageMap.values());
};




const navigate = useNavigate(); // Initialize useNavigate

const handleViewMessageClick = async(index, phone) => {
  console.log(index) 
 window.location.href = '/view-message'
 localStorage.setItem("msgIndex", index) 
 localStorage.setItem("isMsgClick", false)
 localStorage.setItem("msgPhone", phone) 
 try {
  // Assuming the API accepts a list of message IDs to mark as read
  const response = await axios.post(`${API_URL}/update_read_status_by_phone`, {
    spToken: localStorage.getItem("spToken"),
    phoneNumber: phone,
  }); 
  
} catch (error) {
  console.error('Error updating read status:', error);
}
};

const filteredMessages = filterMessages();
const isMobile = useMediaQuery('(max-width:600px)');
const sortedMessages = filteredMessages.sort((a, b) => new Date(b.send_time) - new Date(a.send_time));
const messagesToShow = (isMobile && !isView) ? sortedMessages.slice(0, 1) : sortedMessages;

const handleViewAllClick = async() => {    
 localStorage.setItem("msgIndex", 0)
 window.location.href = '/view-message'
localStorage.setItem("isMsgClick", true)
const selectedPhoneNumber = sortedMessages[0].user_phone_number
localStorage.setItem("msgPhone", selectedPhoneNumber)
try {
  // Assuming the API accepts a list of message IDs to mark as read
  const response = await axios.post(`${API_URL}/update_read_status_by_phone`, {
    spToken: localStorage.getItem("spToken"),
    phoneNumber: selectedPhoneNumber,
  });
  
  
} catch (error) {
  console.error('Error updating read status:', error);
}
};

const getUnreadCountByPhoneNumber = (phoneNumber) => {
  console.log(phoneNumber)
  return messageDetails.reduce((acc, message) => {
    if (message.read === 'Unread' && message.user_phone_number === phoneNumber) {
      return acc + 1;
    }
    return acc;
  }, 0);
 
};





    return(
     
    <Box sx={{width:'100%', height:'100%', display: 'flex' , flexDirection:'column',
      justifyContent:'center',alignItems:'center', 
      background:Colors.white,borderRadius:'4px', }}>
       <Box sx={{width:'100%', height:'100%',
        display: 'flex' , flexDirection:'column', justifyContent:'left',alignItems:'center',
        gap:'16px', overflowY: isView ?'auto' : 'none', '&::-webkit-scrollbar': {
          width: '12px', height: '8px', borderRadius: '16px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: Colors.black_60, borderRadius: '16px', border: '2px solid white',
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: '16px', backgroundColor: 'none', 
        },}}>    
             <TypographySubHeading sx={{textAlign:'left', width:'100%', fontSize:{md:'18px', xs:'14px'},fontWeight:500}}>
               Messages</TypographySubHeading>
                  <Box sx={{width:'100%', display:'flex', flexDirection:'column',gap:'4px'}}>
                      <TextfieldNormal  value={search}  placeholder="Search"                      
                      onChange={(event) => handleSearchChange(event)} ></TextfieldNormal>
                      <TypographyNormal  component="span"  sx={{textAlign:'right', width:'100%',
                        color: Colors.blue_6, fontSize:{xs:  '10px', md: '14px' }, cursor: 'pointer',}}
                        onClick={handleAllRead}>
                        Mark all as read                            
                      </TypographyNormal>
                  </Box>
                  {filteredMessages.length === 0 && <Box sx={{width:'100%', height:{xs:'30vh' , md:'100vh'}, display:'flex', flexDirection:'column', justifyContent:'center',
                  alignItems:'center',gap:'4px'}}>
                  <TypographyNormal sx={{ color: Colors.black_60, fontSize: '16px'}}>No messages</TypographyNormal>
                  <QuestionAnswerIcon sx={{color:Colors.black_60,width:'24px', height:'24px'}}></QuestionAnswerIcon>
                  </Box>}

                  {filteredMessages.length > 0 && 
                  messagesToShow.map((message, index) => (                    
                      <Box key={index} onClick={() => handleViewMessageClick(index, message.user_phone_number)} sx={{width:'99%', height:'100%', display:'flex', flexDirection:'column',justifyContent:'center',alignItems:'center',
                      background: message.read === 'read' ? Colors.white : '#ECF4FB', borderRadius:'4px', position:'relative', cursor:'pointer', border:( parseInt(localStorage.getItem("msgIndex"), 10) === index) && isView ? '1px solid #415FE2' : '1px solid white'}}>
                     {console.log(messagesToShow)}
                      <Box sx={{ height:'100%', display:'flex', flexDirection:'row', justifyContent:'space-between',maxWidth:'94%',paddingTop:'3%', paddingBottom:'3%'}}>
                      <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'4px'}}>
                      <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', 
                            justifyContent:'left', alignItems:'center', gap:'24px'}}>
                      <TypographyNormal sx={{ color: Colors.black_80, fontSize: {md:'16px', xs:'14px'}, fontWeight: 600 }}>+ {message.country_code} {message.user_phone_number}
                      </TypographyNormal>
                      <Badge 
                            badgeContent={getUnreadCountByPhoneNumber( message.user_phone_number)}                             
                            sx={{
                              '& .MuiBadge-badge': {
                                right: 0, 
                                background: Colors.hovering_blue,                             
                                border: `2px solid ${Colors.hovering_blue}`,
                                padding: '0 4px',
                                borderRadius: '50%',
                                minWidth: { xs:'16px', md: '20px' }, // Ensure it's a circle for larger numbers
                                height:{ xs:'16px', md: '20px' },
                                fontSize: { xs:'8px', md: '12px' },
                                fontWeight: 'bold',
                                color: '#fff'
                              }
                            }}
                          /> 
                          </Box>  
                          <TypographyNormal sx={{ color: Colors.black_80, fontSize: '12px', fontWeight: 500 }}>
                          Seeking {message.type_of_service} service within the {message.location} area. If you're available, reach out to me at this number + {message.country_code} {message.user_phone_number} 
                          </TypographyNormal>
                         
                          <TypographyNormal sx={{ color: Colors.black_60, fontSize: '12px', fontWeight: 400, marginRight:{xs:0, md:'-10px'},textAlign:'right' }}>
                          {formatDateTime(message.send_time)} 
                          </TypographyNormal> 
                                                                     
                          </Box>
                          <MoreVertIcon ref={iconRef}  style={{cursor:'pointer', fontSize:'20px'}}
                           onClick={(e) => {
                            e.stopPropagation();  // Prevents the parent click event
                            handleIconClick(index, message.message_id, message.user_phone_number);
                            }}></MoreVertIcon>
                          </Box>
                          
                          {isReadDelete && selectedMessageIndex === index && (

                          <Box  ref={dropdownRef} className="dropdown__menu" sx={{
                          position: 'absolute', right: '30px', top: '10px', fontFamily: 'Roboto', fontSize: '16px', fontWeight: '400',
                          lineHeight: '20px', width: '170px', background: Colors.white, borderRadius: '4px', boxShadow: '0px 4px 4px 0px #00000025', zIndex: 1,
                          color: 'white', padding: '8px', border:'1px solid #E6E6E6'
                          }}>
                          <style>
                              {`
                          .dropdown__menu .MuiMenuItem-root:hover {
                          background-color: #415FE2;
                          color: #FFFFFF !important;
                          }
                          `}
                          </style>

                          <MenuItem
    onClick={(e) => {  
        e.stopPropagation(); 
        handleMenuItemClick(message.read === 'read' ? "Unread" : "read", message.country_code, message.user_phone_number); // Toggle action based on status
    }}
    value="option2"
    style={{
        fontFamily: 'Roboto', 
        fontSize: '16px', 
        color: Colors.black_80,
        fontWeight: '400', 
        lineHeight: '20px', 
        minHeight: '20px', 
        borderRadius: '4px'
    }}
>
    {message.read === 'read' ? "Mark as unread" : "Mark as read"}
</MenuItem>
                      {/* <MenuItem onClick={(e) => {  e.stopPropagation(); handleMenuItemClick("delete");}}  value="option2" style={{
                      fontFamily: 'Roboto', fontSize: '16px', color: Colors.black_80,
                      fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
                      }}>
                      Delete
                      </MenuItem> */}
                  </Box>

                  )}   
                      </Box>                
                  ))
                  }
              </Box>
           

                  {isDeletePopUp &&
                  <Box sx={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '2',

                  }}>
                      <Box sx={{width:'100%', height:'100%',gap:'5px',
                              justifyContent:'center',alignItems:'center', position: 'fixed',
                              display:'flex',flexDirection:'column', }}>
                              <Box sx={{width:{ md: '375px', sm: '380px', xs: '85%' }, height:'36px',
                              justifyContent:'right',alignItems:'right', display:'flex',}}>
                              <IconButton onClick={handleDeletePopUpCancelButtonClick} sx={{
                              width: '36px', height: '36px',}} >
                                   <CloseOutlinedIcon sx={{ width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                        width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                        background:'none'},}} ></CloseOutlinedIcon>
                      </IconButton>
                      </Box>
                  <Box
                      sx={{
                      
                      width: { md: '375px', sm: '380px', xs: '85%' },
                      height: 'fit-content',
                      backgroundColor: '#FFFFFF',
                      border: '1px solid #FFFFFF',
                      borderRadius: '4px ',
                      boxShadow: 'none',
                      boxSizing: 'border-box',
                      zIndex: '3',
                      padding: '35px',
                      alignItems: 'center',

                      }}
                  >
                  
                      <Box sx={{
                      display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', alignItems: 'center',
                      justifyContent: 'center', alignContent: 'center', height: '100%'
                      }}>
                      <Box sx={{
                          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                          alignContent: 'center', width: '100%', gap: '16px'
                      }}>
                          <TypographyNormal sx={{ fontSize: '16px', color: Colors.black_70 }}>
                          Are you sure you want to delete this message from user {selectedMessageIndex + 1} ?</TypographyNormal>

                      </Box>


                      <Box sx={{
                          display: 'flex', flexDirection: 'row', gap: '16px', marginTop: '16px', justifyContent: 'center',
                          alignContent: 'center', alignItems: 'center', width: '100%'
                      }}>
                          <ButtonWhite onClick={handleDeletePopUpCancelButtonClick}>  Cancel </ButtonWhite>
                          <ButtonBlue onClick={handleDeletePopUpOkButtonClick}> Delete </ButtonBlue>
                      

                      </Box>


                      </Box>
                  </Box>
                  </Box>
                  </Box>}


         { !isView && filteredMessages.length > 0 && <Box sx={{ textAlign: 'center',padding: '8px', background:'white',  position: 'absolute', 
         bottom: 0,width:'90%',zIndex:2 }}>
          <TypographyNormal
            sx={{ color: Colors.blue_6,fontSize: '14px',fontWeight: '400', cursor: 'pointer', }}
            onClick={(e) => {
              e.stopPropagation();
               handleViewAllClick()}} >
            View All
          </TypographyNormal>
        </Box>}
    </Box>
 
    )
}
export default SpMessage;