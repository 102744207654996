
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button, IconButton, InputAdornment, Breadcrumbs, TextField, Checkbox, Switch, Divider } from "@mui/material";
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Colors from '../Components/Colors';
import TextfieldNormal from '../Components/TextfieldNormal';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonBlue from '../Components/ButtonBlue';
import TypographyError from '../Components/TypographyError';
import axios from "axios";
import { API_URL } from '../Components/Constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import ButtonWhite from '../Components/ButtonWhite';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { IMG_URL } from '../Components/Constants';
import SpSidebar from '../Components/SpSidebar';
import { useLocation } from 'react-router-dom';
import AssociateHeader from '../Components/AssociateHeader';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';




function AssociateSupportTeam() {

  const [supportTeams, setSupportTeams] = useState([]);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [isSpShow, setIsSpShow] = useState(false);
  const [assignedSpName, setAssignedSpName] = useState([]);
  const [assignedSpIds, setAssignedSpIds] = useState([]);
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [status, setStatus] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [viewOperationId, setViewOperationId] = useState('');
  const [selectedSupportTeams, setSelectedSupportTeams] = useState([]);
  const [workLogData, setWorkLogData] = useState([]);
  const [isViewLog, setIsViewLog] = useState(false)

  const StyledTableCell = styled(TableCell)({
    fontSize: 14,
  });

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


  useEffect(() => {

    const fetchSupportTemDetails = async () => {
      try {
        const response = await axios.get(`${API_URL}/select_support_team_details`);
        console.log(response.data.result)
        setSupportTeams(response.data.result);

        const newAssignedSpNames = [];
        const newAssignedSpIds = [];
        const spNameSet = new Set(); // To track unique business names
        const spIdSet = new Set(); // To track unique service provider IDs

        response.data.result.forEach(teamMember => {
          const { operation_team_id, service_provider_info } = teamMember;

          if (service_provider_info && service_provider_info.length > 0) {
            service_provider_info.forEach(sp => {
              const { service_provider_id, business_name } = sp;

              // Handling business names
              if (business_name && !spNameSet.has(business_name)) {
                spNameSet.add(business_name); // Add to the set to avoid duplicates
                const existingTeamMemberData = newAssignedSpNames.find(item => item.teamMemberId === operation_team_id);
                if (existingTeamMemberData) {
                  existingTeamMemberData.spName.push(business_name);
                } else {
                  newAssignedSpNames.push({ teamMemberId: operation_team_id, spName: [business_name] });
                }
              }

              // Handling service provider IDs
              if (service_provider_id && !spIdSet.has(service_provider_id)) {
                spIdSet.add(service_provider_id); // Add to the set to avoid duplicates
                const existingIdData = newAssignedSpIds.find(item => item.teamMemberId === operation_team_id);
                if (existingIdData) {
                  existingIdData.spId.push(service_provider_id);
                } else {
                  newAssignedSpIds.push({ teamMemberId: operation_team_id, spId: [service_provider_id] });
                }
              }
            });
          }
        });

        setAssignedSpName(newAssignedSpNames);
        setAssignedSpIds(newAssignedSpIds);


      } catch (error) {
        console.error('Error fetching support team data:', error);

      }
    };
    fetchSupportTemDetails();
  }, []);

  const fetchServiceProviders = async () => {

    try {
      const response = await axios.post(`${API_URL}/associate_sp_details_with_tab`, { status: 'New Onboarding' });
      console.log(response);
      setServiceProviders(response.data.data || []);

    } catch (error) {
      console.error('Error fetching service providers:', error);
    }
  };

  useEffect(() => {
    fetchServiceProviders();
  }, []);

  //   const handleCheckboxChange = (event, spId, teamId) => {        
  //     const spName = event.target.value;
  //     setAssignedSpName((prevSelectedNames) => {
  //         if (event.target.checked) {
  //             return [...prevSelectedNames, event.target.value];
  //         } else {
  //             return prevSelectedNames.filter((name) => name !== spName);
  //         }
  //     });

  //     setAssignedSpIds((prevSelectedIds) => {
  //       if (event.target.checked) {
  //           return [...prevSelectedIds, spId];
  //       } else {
  //           return prevSelectedIds.filter((id) => id !== spId);
  //       }
  //   });

  // };


  //   const handleCheckboxChange = (event, spName, spId, teamMemberId) => {

  // console.log(spName)
  //     // Store the value based on the team member's ID
  //     setAssignedSpName((prevState) => {
  //         // Check if this team member is already assigned
  //         const existingIndex = prevState.findIndex(item => item.teamMemberId === teamMemberId);

  //         if (event.target.checked) {
  //             if (existingIndex === -1) {
  //                 return [...prevState, { spName, teamMemberId }];
  //             } else {
  //                 const newState = [...prevState];
  //                 newState[existingIndex] = { spName, teamMemberId};
  //                 return newState;
  //             }
  //         } else {
  //             return prevState.filter(item => item.teamMemberId !== teamMemberId);
  //         }
  //     });


  //     setAssignedSpIds((prevState) => {
  //       // Check if this team member is already assigned
  //       const existingIndex = prevState.findIndex(item => item.teamMemberId === teamMemberId);

  //       if (event.target.checked) {
  //           if (existingIndex === -1) {
  //               // Add new assignment for this team member
  //               return [...prevState, { spId, teamMemberId }];
  //           } else {
  //               // If already assigned, replace the value
  //               const newState = [...prevState];
  //               newState[existingIndex] = { spId, teamMemberId };
  //               return newState;
  //           }
  //       } else {
  //           // Remove assignment if unchecked
  //           return prevState.filter(item => item.teamMemberId !== teamMemberId);
  //       }
  //   });


  // };


  const handleCheckboxChange = (event, spName, spId, teamMemberId) => {
    const isChecked = event.target.checked;

    // Update the spName array
    setAssignedSpName((prevState) => {
      const existingTeamMemberData = prevState.find(item => item.teamMemberId === teamMemberId);

      if (isChecked) {
        if (existingTeamMemberData) {
          // Append the new spName to the existing list (if it exists, else create an array)
          return prevState.map(item =>
            item.teamMemberId === teamMemberId
              ? { ...item, spName: [...(item.spName || []), spName] }
              : item
          );
        } else {
          // Create a new entry for the team member with the spName
          return [...prevState, { teamMemberId, spName: [spName] }];
        }
      } else {
        // If unchecked, remove the spName from the specific team member's list
        if (existingTeamMemberData) {
          const updatedSpNames = existingTeamMemberData.spName.filter(name => name !== spName);
          return updatedSpNames.length > 0
            ? prevState.map(item =>
              item.teamMemberId === teamMemberId
                ? { ...item, spName: updatedSpNames }
                : item
            )
            : prevState.filter(item => item.teamMemberId !== teamMemberId);
        }
        return prevState;
      }
    });

    // Update the spIds array
    setAssignedSpIds((prevState) => {
      const existingTeamMemberData = prevState.find(item => item.teamMemberId === teamMemberId);

      if (isChecked) {
        if (existingTeamMemberData) {
          // Append the new spId to the existing list (if it exists, else create an array)
          return prevState.map(item =>
            item.teamMemberId === teamMemberId
              ? { ...item, spId: [...(item.spId || []), spId] }
              : item
          );
        } else {
          // Create a new entry for the team member with the spId
          return [...prevState, { teamMemberId, spId: [spId] }];
        }
      } else {
        // If unchecked, remove the spId from the specific team member's list
        if (existingTeamMemberData) {
          const updatedSpIds = existingTeamMemberData.spId.filter(id => id !== spId);
          return updatedSpIds.length > 0
            ? prevState.map(item =>
              item.teamMemberId === teamMemberId
                ? { ...item, spId: updatedSpIds }
                : item
            )
            : prevState.filter(item => item.teamMemberId !== teamMemberId);
        }
        return prevState;
      }
    });
  };



  const searchRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSpShow(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);

  const handleShowSp = (index) => {
    if (openDropdownIndex === index) {
      // If the same dropdown is clicked, close it
      setOpenDropdownIndex(null);
    } else {
      // Open the clicked dropdown and close any other open dropdown
      setOpenDropdownIndex(index);
    }
  };

  useEffect(() => {
    // Fetch the current status from the API when the component mounts
    const fetchStatus = async () => {
      try {
        const response = await axios.get(`${API_URL}/select_support_team_details`);
        const fetchedStatus = response.data.result.status;
        setStatus(fetchedStatus);
      } catch (error) {
        console.error('Error fetching status:', error);
      }
    };

    fetchStatus();
  }, []); // Only run this effect on initial mount

  const handleChangeSwitch = async (teamId, currentStatus) => {
    const updatedStatus = currentStatus === 'Active' ? 'Inactive' : 'Active';
    setSupportTeams((prevTeams) =>
      prevTeams.map((team) =>
        team.operation_team_id === teamId ? { ...team, status: updatedStatus } : team
      )
    );
    try {
      await axios.post(`${API_URL}/update_support_team_status`, {
        operationTeamId: teamId,
        status: updatedStatus,
        tocken: localStorage.getItem("associateToken"), 
      });

    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleAssignButtonClick = async (teamMemberId) => {
    console.log(assignedSpIds, teamMemberId)
    const selectedSpIds = assignedSpIds.find(item => item.teamMemberId === teamMemberId)?.spId || [];
    console.log(selectedSpIds, teamMemberId)
    try {
      const response = await axios.post(`${API_URL}/assign_sp_to_operation_team`, {
        assignedSpIds: selectedSpIds,
        teamMemberId: teamMemberId,
        tocken: localStorage.getItem("associateToken"),
      });
    } catch (error) {

      console.error('Error:', error.message);
    }
    fetchServiceProviders()
  };

  const handleViewDetailsButtonClick = (operationTeamId) => {
    setIsViewDetails(true);
    const team = supportTeams.find(team => team.operation_team_id === operationTeamId);
    if (team) {
      const uniqueProviders = Array.from(new Set(team.service_provider_info.map(provider => provider.service_provider_id)))
        .map(id => team.service_provider_info.find(provider => provider.service_provider_id === id));
      setSelectedSupportTeams(uniqueProviders);
    } else {
      setSelectedSupportTeams([]);
    }
  };

  const handleWorkLogButtonClick = async (operationTeamId) => {
    try {
      const response = await axios.post(`${API_URL}/work_log_operation_team`, {
        teamMemberId: operationTeamId,
      });
      console.log(response.data)
      if (response.data.success) {
        setWorkLogData(response.data.result)
        setIsViewLog(true);
       
      }
    } catch (error) {

      console.error('Error:', error.message);
    }
  };


  const handleCloseViewDetailsPopUp = () => {
    setIsViewDetails(false)
    setViewOperationId('')
  };

  const handleCloseViewLogPopUp = () => {
    setIsViewLog(false)
  };



  return (
    <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>

      <AssociateHeader></AssociateHeader>

      <Breadcrumbs
        separator={<span style={{ paddingLeft: '8px', fontSize: '32px', justifyContent: 'center', alignItems: 'center', color: Colors.default }}>›</span>}
        aria-label="breadcrumb" >
        <TypographySubHeading sx={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '20px', justifyContent: 'center', padding: '16px' }}>
          <Box sx={{
            display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer',
            height: '100%'
          }} onClick={() => window.location.href = '/associate-landing'} >
            <ArrowBackIcon sx={{ color: Colors.black_90, fontSize: '18px', '&:hover': { fontSize: '22px' } }} />
          </Box>
          Home
        </TypographySubHeading>
      </Breadcrumbs>


      <Box sx={{
        display: 'flex', flexDirection: 'column', width: '100%', marginTop: '24px',
        justifyContent: 'flex-start', height: '100%', alignItems: 'center', minHeight: '500px'
      }}>

        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableBody>
              {supportTeams.map(({ operation_team_id, operation_team_member, phone_number, country_code, status }, index) => (
                <StyledTableRow key={operation_team_id} sx={{ backgroundColor: 'transparent' }}>
                  <StyledTableCell component="th" scope="row">
                    {/* Operation Team Member and Contact Info */}
                    <Box sx={{
                      width: '100% ', display: 'flex', flexDirection: 'column',
                      maxWidth: '98%', alignItems: 'left', justifyContent: 'flex-start',
                    }}>
                      <TypographyNormal sx={{
                        fontSize: '16px', color: Colors.black_80,
                        whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                      }}>
                        {operation_team_member}
                      </TypographyNormal>
                      <TypographyNormal sx={{
                        fontSize: '16px', color: Colors.black_80,
                        whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                      }}>
                        +{country_code} {phone_number}
                      </TypographyNormal>
                    </Box>
                  </StyledTableCell>

                  {/* TextField with Dropdown for Service Providers */}
                  <StyledTableCell>
                    <TypographyNormal
                      sx={{
                        fontSize: '16px',
                        color: Colors.black_80,
                        whiteSpace: 'normal', // Allow the text to wrap to the next line
                        overflow: 'hidden', // Hide overflowing text
                        textOverflow: 'clip', // Avoid ellipsis
                      }}
                    >
                      {assignedSpName
                        .filter(item => item.teamMemberId === operation_team_id)
                        .map(item => item.spName)
                        .join(',')}
                    </TypographyNormal>

                  </StyledTableCell>

                  {/* Other Table Cells */}
                  <StyledTableCell>
                    <ButtonWhite
                      sx={{ borderRadius: '4px', width: 'fit-content' }}
                      onClick={() => handleViewDetailsButtonClick(operation_team_id)}
                    >
                      View Details
                    </ButtonWhite>
                  </StyledTableCell>

                  <StyledTableCell>
                    <Switch
                      checked={status === 'Active'}
                      onChange={() => handleChangeSwitch(operation_team_id, status)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <span>{status}</span>
                  </StyledTableCell>
                  <StyledTableCell>
                    <ButtonWhite
                      sx={{ borderRadius: '4px', width: 'fit-content' }}
                      onClick={() => handleWorkLogButtonClick(operation_team_id)}
                    >
                      Work Log
                    </ButtonWhite>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>



      </Box>

      <Footer></Footer>


      {isViewLog &&
        <Box
          sx={{
            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 2,
          }} >
          <Box sx={{
            width: '100%', height: '100%', gap: '5px',
            justifyContent: 'center', alignItems: 'center', position: 'fixed',
            display: 'flex', flexDirection: 'column',
          }}>
            <Box sx={{
              width: { md: '85%', sm: '85%', xs: '90%' }, height: '36px',
              justifyContent: 'right', alignItems: 'right', display: 'flex',
            }}>
              <IconButton onClick={handleCloseViewLogPopUp} sx={{
                width: '36px', height: '36px',
              }} >
                <CloseOutlinedIcon sx={{
                  width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                    width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                    background: 'none'
                  },
                }} ></CloseOutlinedIcon>
              </IconButton>
            </Box>
            <Box
              sx={{

                width: { md: '85%', sm: '85%', xs: '90%' },
                height: 'fit-content',
                backgroundColor: Colors.grey_20,
                border: '1px solid #E6E6E6',
                borderRadius: '4px ',
                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                boxSizing: 'border-box',
                zIndex: 3,
                padding: '35px 0px',
                alignItems: 'center',maxHeight:'75%',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '11px',
                  height: '8px', // Added height for horizontal scrollbars
                  borderRadius: '16px',

                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: Colors.grey_30,
                  borderRadius: '16px',
                  border: '2px solid white', // Adds some padding around the thumb to create the appearance of rounded edges
                },
                '&::-webkit-scrollbar-track': {
                  borderRadius: '16px',
                  backgroundColor: Colors.white,

                },
              }}
            >

              <Box sx={{
                width: '100%', height: '100%', display: 'flex', flexDirection: 'column',

              }}>
                <Box sx={{
                  width: '100%', height: '94%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                  flexDirection: 'column', paddingBottom: '3%',
                }}>
                  <div style={{ overflowX: 'auto' }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr>
                         <th style={{ border: '1px solid #ddd', padding: '8px' }}>Service Provider</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px' }}>Comment</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px' }}>Operation Profile Status</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px' }}>Assigned Date</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px' }}>Status Updated Date</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px' }}>Audit Date</th>                          
                          
                         
                        </tr>
                      </thead>
                      <tbody>
                        {console.log(workLogData)}
                        {workLogData.map((log, index) => (
                          <tr key={index}>
                             <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                              <tr>{log.business_name}</tr>
                              <tr>+{log.country_code} {log.phone_number} </tr>
                              <tr>{log.city}/ {log.province}/ {log.country} </tr>
                              </td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{log.comment || '-'}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{log.profile_status || '-'}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{new Date(log.assigned_date).toLocaleString()}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{new Date(log.status_updated_date).toLocaleString()}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{log.audit_date ? new Date(log.audit_date).toLocaleString() : 'In Progress'}</td>
                           
                           
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                </Box>



              </Box>








            </Box>
          </Box>
        </Box>
      }

      {isViewDetails &&
        <Box
          sx={{
            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 2,
          }} >
          <Box sx={{
            width: '100%', height: '100%', gap: '5px',
            justifyContent: 'center', alignItems: 'center', position: 'fixed',
            display: 'flex', flexDirection: 'column',
          }}>
            <Box sx={{
              width: { md: '50%', sm: '50%', xs: '90%' }, height: '36px',
              justifyContent: 'right', alignItems: 'right', display: 'flex',
            }}>
              <IconButton onClick={handleCloseViewDetailsPopUp} sx={{
                width: '36px', height: '36px',
              }} >
                <CloseOutlinedIcon sx={{
                  width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                    width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                    background: 'none'
                  },
                }} ></CloseOutlinedIcon>
              </IconButton>
            </Box>
            <Box
              sx={{

                width: { md: '50%', sm: '50%', xs: '90%' },
                height: 'fit-content',
                backgroundColor: Colors.grey_20,
                border: '1px solid #E6E6E6',
                borderRadius: '4px ',
                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                boxSizing: 'border-box',
                zIndex: 3,
                padding: '35px 0px',
                alignItems: 'center',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '11px',
                  height: '8px', // Added height for horizontal scrollbars
                  borderRadius: '16px',

                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: Colors.grey_30,
                  borderRadius: '16px',
                  border: '2px solid white', // Adds some padding around the thumb to create the appearance of rounded edges
                },
                '&::-webkit-scrollbar-track': {
                  borderRadius: '16px',
                  backgroundColor: Colors.white,

                },
              }}
            >

              <Box sx={{
                width: '100%', height: '100%', display: 'flex', flexDirection: 'column',

              }}>
                <Box sx={{
                  width: '100%', height: '94%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                  flexDirection: 'column', paddingBottom: '3%',
                }}>

                  <Box sx={{
                    width: '100%', height: 'fit-content', justifyContent: 'center', alignItems: 'left', display: 'flex',
                    flexDirection: 'column', gap: '4px', maxWidth: { xs: '90%', md: '95%' },
                  }}>
                    <Box sx={{
                      width: '100%', height: 'fit-content',
                      justifyContent: 'center', alignItems: 'left', display: 'flex',
                      flexDirection: 'column', gap: '4px'
                    }}>
                      {console.log(selectedSupportTeams)}
                      {selectedSupportTeams && selectedSupportTeams.length > 0 && (
                        selectedSupportTeams.map((provider, providerIndex) => (
                          <Box key={providerIndex} sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                            {/* Create a structure for displaying provider details */}
                            {[
                              { label: 'Name', value: provider.business_name },
                              { label: 'Comment', value: provider.comment },
                              { label: 'Status', value: provider.profile_status },
                              { label: 'Assigned Date', value: provider.assigned_date },
                              { label: 'Updated Date', value: provider.status_updated_date }
                            ]
                              .filter(item => item.value) // Filter out items with no value
                              .map((item, itemIndex) => (
                                <Box
                                  key={itemIndex}
                                  sx={{
                                    width: '100%',
                                    height: 'fit-content',
                                    justifyContent: 'left',
                                    alignItems: 'left',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '4px'
                                  }}>
                                  <TypographyNormal sx={{ fontSize: { xs: '14px', md: '16px' }, fontWeight: 500 }}>
                                    {item.label}:
                                  </TypographyNormal>
                                  <TypographyNormal>{item.value}</TypographyNormal>
                                </Box>
                              ))}
                            <Divider sx={{ margin: '10px 0' }} /> {/* Underline between service providers */}
                          </Box>
                        ))
                      )}



                    </Box>

                  </Box>
                </Box>



              </Box>








            </Box>
          </Box>
        </Box>
      }



    </Grid>
  )
}
export default AssociateSupportTeam;