import React from "react"
import Typography from '@mui/material/Typography';
import Colors from "./Colors";


 function TypographyError({ onClick, sx, ...props }){
    
    return(
        <Typography  
            sx={{fontFamily:'Roboto !important', fontWeight: 400, fontSize:{ xs: '12px', md: '14px' }, color: Colors.red, 
            paddingTop:{md:'4px', xs:'12px'},
            lineHeight:'16px', ...sx, }}
                 {...props} onClick={onClick}>            
         </Typography>
    )
}

export default TypographyError;

