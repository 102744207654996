import React, { useEffect,useState } from 'react';
import { AppBar, Toolbar, Typography, Drawer, List, ListItemText, Box,
     ListItemButton, IconButton, Menu, MenuItem } from '@mui/material';
import adminlogo from '../Images/logo.png';
import axios from 'axios';
import { API_URL } from '../Components/Constants';
// import AdminCategory from '../Components/AdminCategory';
import AdminServices from '../Components/AdminServices';
// import AdminType from '../Components/AdminType';
// import AdminSp from '../Components/AdminSp';
// import AdminSearchTags from '../Components/AdminSearchTags';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import AdminTypes from '../Components/AdminTypes';
import AdminSp from '../Components/AdminSp';
import AdminSearchTags from '../Components/AdminSearchTags';
import AdminUsers from '../Components/AdminUsers';
import AdminUserReview from '../Components/AdminUserReview';

const options = [ "Services", "Type of services", "Service providers", "Search tags", "Users","User review"];

function AdminLanding() {
    // const token=localStorage.getItem('adminToken')
    const token = localStorage.getItem('adminToken')

    const [selectedOption, setSelectedOption] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [expanded, setExpanded] = React.useState(false);
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [serviceDetails, setServiceDetails] = useState([]);
    const [typesDetails, setTypesDetails] = useState([]);
    const [spDetails, setSpDetails] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [serviceTagDetails, setServiceTagDetails] = useState([]);
    const [typesTagDetails, setTypesTagDetails] = useState([]);
    const [spTagDetails, setSpTagDetails] = useState([]);



    const handleMenuToggle = (event) => {
        if (anchorEl) {
            setAnchorEl(null);
            setExpanded(false);
        } else {
            setAnchorEl(event.currentTarget);
            setExpanded(true);
        }
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setExpanded(false); // Ensure expanded state is set to false when menu closes
    };
    const handleSignOut = () => {
        // Implement your sign-out logic here

        handleMenuClose();
        localStorage.removeItem('adminToken');
        localStorage.removeItem('admin');
        localStorage.removeItem('country_code');
        localStorage.removeItem('phone');
        window.location.href = '/';
    };
    useEffect(() => {        
        fetchAllData();       
    }, []);
    async function fetchAllData() {
        try {
            const response = await axios.post(`${API_URL}/admin_get_all_data`, { token });
            console.log(response.data);
            if (response.data.success) {
                console.log(response.data.data)
                 setServiceDetails(response.data.data.serviceResult);
                 setTypesDetails(response.data.data.typesResult);
                 setSpDetails(response.data.data.spResult);
                 setServiceTagDetails(response.data.data.serviceSearchTagResult);
                 setTypesTagDetails(response.data.data.typesSearchTagResult);
                 setSpTagDetails(response.data.data.spSearchTagResult);
                 setUserDetails(response.data.data.userResult)
                 console.log("user result",response.data.data.userResult)
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    }
    const pagesComponents = [
        // <AdminCategory updateTrigger={updateTrigger}/>,
        <AdminServices serviceDetails={serviceDetails} setServiceDetails={setServiceDetails} updateTrigger={updateTrigger}/>,
        <AdminTypes serviceDetails={serviceDetails} setServiceDetails={setServiceDetails}
        typesDetails={typesDetails} setTypesDetails={setTypesDetails} updateTrigger={updateTrigger}/>,
        <AdminSp spDetails={spDetails} setSpDetails={setSpDetails} updateTrigger={updateTrigger}/>,
        <AdminSearchTags serviceTagDetails={serviceTagDetails} setServiceTagDetails={setServiceTagDetails}
         typesTagDetails={typesTagDetails} setTypesTagDetails={setTypesTagDetails}
         spTagDetails={spTagDetails} setSpTagDetails={setSpTagDetails} updateTrigger={updateTrigger}/>,
         <AdminUsers userDetails={userDetails} setSpDetails={setUserDetails} updateTrigger={updateTrigger}/>,
         <AdminUserReview userDetails={userDetails} setSpDetails={setUserDetails} updateTrigger={updateTrigger}/>,
    ];

    const handleListItemClick = (index) => {
        setSelectedOption(index);
        setUpdateTrigger(!updateTrigger); // Toggle the update trigger
    };

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar sx={{ minHeight: { xs: 86, md: 86 }, backgroundColor: '#5b5555', justifyContent: 'space-between' }}>
                    <Box sx={{ width: { xs: '92px', md: '220px' }, height: { xs: '36px', md: '38px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={adminlogo} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" sx={{ color: '#FFFFFF', }}>admin</Typography>
                        <IconButton
                            size="large"
                            aria-label="admin menu"
                            aria-controls="admin-menu"
                            aria-haspopup="true"
                            onClick={handleMenuToggle}
                            color="inherit"
                        >
                            {expanded ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        <Menu
                            id="admin-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: '20%',
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: '20%', boxSizing: 'border-box', backgroundColor: '#505051' },
                }}
            >
                <Toolbar sx={{ minHeight: { xs: 86, md: 86 } }} />
                <Box sx={{ overflow: 'auto' }}>
                    <List >
                        {options.map((text, index) => (
                            <ListItemButton key={text}
                                sx={{
                                    fontSize: '24px',
                                    fontWeight: 400,
                                    color: '#FFFFFF',
                                    backgroundColor: selectedOption === index ? '#0173AA' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: '#0173AA', // Change background color on hover
                                    },
                                }}
                                onClick={() => handleListItemClick(index)}>
                                <ListItemText primary={text}
                                    primaryTypographyProps={{
                                        fontSize: '20px',
                                        fontWeight: 400,
                                        color: '#FFFFFF'
                                    }} />
                            </ListItemButton>
                        ))}
                    </List>
                </Box>
            </Drawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1, width: 'calc(80% - 48px)',
                    bgcolor: 'background.default',
                    p: 3,
                }}
            >
                <Toolbar />
                {pagesComponents[selectedOption]}
            </Box>
        </Box>
    );
}

export default AdminLanding;
