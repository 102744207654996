import React from "react"
import Button from '@mui/material/Button';
import Colors from "./Colors";

 function ButtonWhite({ onClick, sx, ...props }){
    
    return(
        <Button variant="contained" 
           onClick={onClick} 
            sx={{boxShadow: 'none',width:'100%', height: { xs: '28px', md:  '38px' }, backgroundColor: Colors.white,
            borderRadius:'4px', color: Colors.black_90, textTransform: 'none',fontFamily: 'Roboto',padding:{ xs: '0px 8px', md: '0px 16px' },
            fontSize:{ xs: '10px', md: '14px' }, fontWeight:'400',border:'1px solid ',borderColor:Colors.black_90,
            '&:hover': {
                background: Colors.grey_30, textTransform: 'none', color: Colors.black_90, boxShadow: 'none',
              },
              ...sx,
            }}
                 {...props}>            
         </Button>
    )
}
export default ButtonWhite;

