import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button } from "@mui/material";
import Header from '../Components/Header';
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading  from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Footer from "../Components/Footer";
import Colors from '../Components/Colors';
import MailIcon from '@mui/icons-material/Mail';
import SettingsSidebar from '../Components/SettingsSidebar';
import ButtonBlue from '../Components/ButtonBlue';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ButtonWhite from '../Components/ButtonWhite';
import IconButton from '@mui/material/IconButton';

function SpDisableAccount(){

    const [isDisable, setIsDisable] = useState(false);

    
    const handleDisableClick = () => {
        setIsDisable(true);     
    }

    const handleCloseIsDisablePopUp = () => {
        setIsDisable(false);     
    }

    const handleIsDisableButtonClick = () => {
            
    }
    
    
    
    return(
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px',}}>

        <Header></Header>
    
           <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', 
           justifyContent: 'center', height: '100%', alignItems:'center',}}>
    
                <Box sx={{display: 'flex', flexDirection:{ xs: 'column ', md: 'row' } ,
                 width: '100%', paddingBottom:'2%',gap:'32px',  
                    justifyContent: 'center', height: '100%', alignItems:'flex-start',  maxWidth:{ xs: '90% ', md: '94%' }, paddingTop:'2%',}}>
    
                        <Box sx={{display: 'flex', flexDirection: 'row', width:{ xs: '100% ', md: '25%' } , 
                        justifyContent: 'flex-start', height: '100%', alignItems:'center'}}>
                                <SettingsSidebar currentPage={"Disable account"}></SettingsSidebar>
                        </Box>
    
                        <Box sx={{display: 'flex', flexDirection: 'column', width:{ xs: '100% ', md: 'calc(75% - 32px)' } ,
                         borderLeft:{ xs: '0px solid #E6E6E6', md:'1px solid #E6E6E6' }, minHeight:'60vh' ,
                        justifyContent: 'flex-start', height: '100%', alignItems:'center', paddingLeft: { xs: '0px ', md:'32px' }}}>
                                            
                                <Box sx={{display: 'flex', flexDirection: 'column', width:'100%', gap: '8px',
                                justifyContent: 'center',  height: '100%', alignItems: 'center', }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '8px',paddingTop:'2%',
                                         height:'96%', justifyContent: 'flex-start', alignItems: 'flex-start',paddingBottom:'2%',}}>
                                            <TypographySubHeading sx={{fontSize: '18px', fontWeight:600}}>Why Disable Your Account?</TypographySubHeading>
                                            <TypographyNormal>If you’re temporarily unavailable or need a break, you can Disable your account without losing any of your data. When your account is Disabled, your profile and services will be hidden from others, ensuring that you won’t receive any new requests or notifications.</TypographyNormal>
                                            <TypographySubHeading sx={{fontSize: '18px', fontWeight:600, paddingTop:'12px'}}>What Happens When You Disable Your Account?</TypographySubHeading>
                                            <ul style={{marginTop:'-0px'}}>
                                            <li><TypographyNormal>Your account will be temporarily inactive.</TypographyNormal></li>
                                            <li><TypographyNormal>Your profile and services will not be visible to others.</TypographyNormal></li>
                                            <li><TypographyNormal>You won’t receive any new notifications or requests.</TypographyNormal></li>
                                            <li><TypographyNormal>All your data, including settings, messages, and history, will be securely stored.</TypographyNormal></li>
                                            </ul>
                                            <TypographySubHeading sx={{fontSize: '18px', fontWeight:600, }}>How to Re-enable Your Account?</TypographySubHeading>
                                            <TypographyNormal>When you're ready to resume, simply re-enable your account with a single click. Everything will be just as you left it, and your profile will be visible again, allowing you to pick up right where you left off.</TypographyNormal>
                                            <div>
                                            <TypographyNormal sx={{ fontSize:'18px', fontWeight: 600, paddingTop:'12px'}}>Note: 
                                                <TypographyNormal sx={{  display: 'inline' }}> Disabling your account is completely reversible. You can re-enable it anytime without losing any data or settings.
                                                </TypographyNormal>
                                            </TypographyNormal>
                                        </div>          
                                        <Box sx={{
                                        width: '100%', flexDirection: 'row', justifyContent: 'right', display: 'flex',
                                        alignItems: 'center', gap: { xs: '8px', md: '16px' }, paddingTop: { xs: '16px', md: '32px' }
                                         }}>     
                                             <ButtonBlue onClick={handleDisableClick} sx={{width:'25%'}}>Disable account</ButtonBlue> 
                                         </Box>
                                        </Box>
                                </Box>
    
                        </Box>
                </Box>
          </Box>
                        
         <Footer ></Footer>

          {isDisable && <Box sx={{
                            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            display: 'flex',alignItems: 'center',justifyContent: 'center',zIndex: '2',

                        }}>
                            <Box sx={{
                                width: '100%', height: '100%', gap: '5px',
                                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                                display: 'flex', flexDirection: 'column',
                            }}>
                                <Box sx={{
                                    width: { md: '25%', sm: '25%', xs: '85%' }, height: '36px',
                                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                                }}>
                                     <IconButton onClick={handleCloseIsDisablePopUp} sx={{
                                    position: 'fixed', width: '36px', height: '36px',}} >
                                        <CloseOutlinedIcon sx={{ width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                        width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                        background:'none'},}} ></CloseOutlinedIcon>
                                    </IconButton>
                                </Box>
                                <Box
                                    sx={{width: { md: '25%', sm: '25%', xs: '85%' },height: 'fit-content',
                                        backgroundColor: Colors.white, border: '1px solid',
                                        borderColor: Colors.white, borderRadius: '4px ',boxShadow: 'none',
                                        boxSizing: 'border-box',zIndex: '3', alignItems: 'center', 
                                    }}>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                                        justifyContent: 'center', alignItems: 'center', height: '100%',
                                    }}>
                                          <Box sx={{
                                                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',paddingTop:'13%',
                                                justifyContent: 'center', alignContent: 'center', height: '86%',maxWidth:'75%',
                                                paddingBottom:'13%'
                                             }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column', width: '100%', gap: '20px',
                                            justifyContent: 'center', alignContent: 'center', height: '100%', alignItems: 'center',
                                        }}>
                                            <TypographySubHeading sx={{fontSize:'18px' }}>
                                            Disable account
                                            </TypographySubHeading>

                                            <TypographyNormal sx={{textAlign: 'center',  }}>
                                            Are you sure do you want to Disable this Account?
                                            </TypographyNormal>
                                            <Box sx={{ width:'100%',flexDirection: 'row',justifyContent:'center', display:'flex',
                                                    alignItems:'center',  gap:{xs:  '8px', md: '16px' } }}>                                            
                                                    <ButtonWhite onClick={handleCloseIsDisablePopUp}>Cancel</ButtonWhite>
                                                    <ButtonBlue onClick={handleIsDisableButtonClick} >Disable</ButtonBlue>
                                                </Box>
                                           
                                        </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
            </Box>}  
       
    </Grid>
    )
}
export default SpDisableAccount;