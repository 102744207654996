
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button, IconButton , InputAdornment, Breadcrumbs} from "@mui/material";
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading  from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Colors from '../Components/Colors';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import popupbg from '../Images/popup.png'
import whitelogo from '../Images/whitelogo.png'
import TextfieldNormal from '../Components/TextfieldNormal';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonBlue from '../Components/ButtonBlue';
import TypographyError from '../Components/TypographyError';
import UserTermPopUp from '../Pages/UserTermPopUp';
import axios from "axios";
import { API_URL } from '../Components/Constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import ButtonWhite from '../Components/ButtonWhite';
import uploadCamera from '../Images/uploadCamera.png'
import { IMG_URL } from '../Components/Constants';
import UserChangePassword from './UserChangePassword';
import passwordvisible from '../Images/passwordvisible.svg'
import passwordhide from '../Images/passwordhide.svg'



const colors = ['#503259', '#4C50AE', '#FF7D6A', '#B07CC6', '#20C925'];

function stringToColor(string) {
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  const colorIndex = Math.abs(hash) % colors.length;
  return colors[colorIndex];
}

function stringAvatar(name = '') {   
    const initial = name.charAt(0).toUpperCase();
    
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: initial || '', 
    };
  }
  


function UserEditProfile(){

    const [nickName, setNickName] = useState("");
    const [nickNameError, setNickNameError] = useState("");
    const [countryCode, setCountryCode] = useState("1");
    const [countryCodeError, setCountryCodeError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [zipCode, setZipCode] = useState("");
    const [zipCodeError, setZipCodeError] = useState("");
    const [userDetails, setUserDetails] = useState([]);
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [profileImage, setProfileImage] = useState('');
    const [isImageUpload, setIsImageUpload] = useState('');
    const [isImagePopup, setIsImagePopup] = useState(false);
    const [removeProfileImage, setRemoveProfileImage] = useState(false);

    const fileInputRef = useRef(null);
    const imagePopupRef = useRef(null);


    const handleNickNameChange = (event) => {
        setNickName(event.target.value);
        setNickNameError("");   
        setRemoveProfileImage(false)    
    };

   
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setPasswordError("");
        setRemoveProfileImage(false)
        
    };

    const handleZipCodeChange = (event) => {
        setZipCode(event.target.value);
        setZipCodeError("");
        setRemoveProfileImage(false)
        
    };

    const handleSaveButtonClick = async () => {
        const formData = new FormData();        
        formData.append('userToken', localStorage.getItem("userToken"));
        formData.append('nickName', nickName);
        formData.append('zipCode', zipCode);
        formData.append('password', password);
        formData.append('removeProfileImage', removeProfileImage);
        if (nickName.length > 60) {
            setNickNameError("Nick name is too long.");
        } 
        else{
        if (isImageUpload) {
            try {
                const response = await fetch(profileImage);    
               
                if (!response.ok) {
                    throw new Error('Image fetch failed');
                }
    
                const blob = await response.blob();
                const file = new File([blob], 'profileImg.jpg', { type: blob.type });
                formData.append('profileImg', file);
            } catch (error) {
                console.error("Failed to convert blob URL to file:", error);               
                return; 
            }
        }
        else {           
            formData.append('profileImg', '');
        }
    
        try {
            const response = await axios.post(`${API_URL}/update_user_details`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.data.success) {
                window.location.href = "/";
            } else {
                console.error("An error occurred:", response.data.message);
            }
        } catch (error) {
            console.error("Request failed:", error);
        }}
    };
    
    
    
      

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
        setRemoveProfileImage(false)
    };

       
        const fetchData = async () => {            
            try { 
                const response = await axios.post(`${API_URL}/select_user_details_with_tocken`, {                    
                    userToken: localStorage.getItem("userToken")
                }); 
                setUserDetails(response.data.result)
                setNickName(response.data.result.name)
                setCountryCode(response.data.result.country_code)
                setPhoneNumber(response.data.result.phone_number)
                setPassword(response.data.result.password)
                setZipCode(response.data.result.zip_code)
                if(response.data.result.profile_img){
                setProfileImage(IMG_URL + response.data.result.profile_img)}
                else{
                    setProfileImage("")  
                }                
                
            } catch (error) {
                console.error('Error fetching data:', error);
                
            }
        };

     useEffect(() => { 
        if(localStorage.getItem("userToken")) {     
        fetchData();
    }}, [localStorage.getItem("userToken"), isChangePassword]);
   
  
    const handleIconClick = () => {
      setIsImagePopup(!isImagePopup)
    };


    const handleChangeImageClick = () => {
        if (fileInputRef.current) {
          fileInputRef.current.click(); 
        }
        setIsImagePopup(false)
        setRemoveProfileImage(false)
      };

  
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {       
        const newProfileImage = URL.createObjectURL(file);
        setProfileImage(newProfileImage);
        setIsImageUpload(newProfileImage);
        setRemoveProfileImage(false) 
      }
    };

    
    const handleRemoveImageClick = async() => {        
        setProfileImage("") 
        setIsImagePopup(false)  
        setIsImageUpload(false)               
        setRemoveProfileImage(true)  
      };

    const handleChangePasswordClick = () => {
        setIsChangePassword(true);     
    }

    useEffect(() => {
        function handleClickOutside(event) {
          if (imagePopupRef.current && !imagePopupRef.current.contains(event.target)) {
            setIsImagePopup(false); // Hide the box when clicking outside
          }
        }
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [imagePopupRef, setIsImagePopup]);


return(
<Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>

    <Header></Header>

       <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', 
       justifyContent: 'center', height: '100%', alignItems:'center' }}>

            <Box sx={{display: 'flex', flexDirection: 'column', width: '100%',paddingBottom:'2%', 
            justifyContent: 'center', height: '100%', maxWidth:'96%', paddingTop:'2%', alignItems:'center'}}>

                    <Box sx={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'left', 
                          width:'100%',height:'10%',gap:'12px', }}>
                         <Breadcrumbs 
                            separator={<span style={{ paddingLeft: '8px', fontSize: '32px', justifyContent:'center', alignItems: 'center', color: Colors.default }}>›</span>} 
                            aria-label="breadcrumb" >
                            <TypographySubHeading sx={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '20px', justifyContent: 'center' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', 
                                    height: '100%' }}  onClick={() => window.location.href = '/'} >
                                <ArrowBackIcon  sx={{ color: Colors.black_90, fontSize: '18px', '&:hover': { fontSize: '22px' } }} />
                                </Box>
                                Profile
                            </TypographySubHeading> 
                            </Breadcrumbs>
                    </Box>
                                   
                    <Box sx={{display: 'flex', flexDirection: 'column', width:'100%', gap: '8px',
                    justifyContent: 'center',  height: '100%', alignItems: 'center',  }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '8px',paddingTop:'3%',
                            height:'100%', justifyContent: 'center', maxWidth:'70%',alignItems: 'center',paddingBottom:'3%'}}>

                                        <Box sx={{ height: '100%', width:{ xs: '60px', md: '120px' } , justifyContent: 'center', alignItems: 'flex-start',
                                        display:  'flex', flexDirection: 'row',position: 'relative'}}>
                                                <Box sx={{position: 'relative', display: 'inline-block',}}> 
                                                    {console.log(profileImage)}                                                                                  
                                                    {profileImage ? (
                                                        <Avatar alt="Profile"
                                                         src={profileImage}
                                                            sx={{ width: { xs: '60px', md: '120px' }, height: { xs: '60px', md: '120px' } }}/>
                                                            ) : (
                                                            <Avatar alt="Profile"
                                                                sx={{
                                                                ...stringAvatar(userDetails.name),
                                                                width: { xs: '60px', md: '120px' }, height:{ xs: '60px', md: '120px' }, fontSize: { xs: '32px', md: '58px'},bgcolor:'#503259'}}>
                                                                {stringAvatar(userDetails.name).children}
                                                            </Avatar>
                                                    )}                                    
                                                    
                                                    <Avatar sx={{ position: 'absolute', bottom:{ xs:4, md:4} ,  right:{ xs: 1, md: 0},  width:{ xs: '20px', md: '36px'} ,  height: { xs: '20px', md: '36px'}, 
                                                        backgroundColor: Colors.primary_blue, color: 'white', cursor:'pointer' }}  onClick={handleIconClick} >                                                      
                                                        <img src={uploadCamera} alt="Upload" style={{maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                                    </Avatar>
                                                    <input
                                                    type="file"
                                                    ref={fileInputRef}
                                                    style={{ display: 'none' }}
                                                    onChange={handleFileChange}
                                                />
                                                </Box>
                                        {isImagePopup && ( <Box ref={imagePopupRef}   sx={{position: 'absolute', top: '100%', 
                                        width:{ xs: '80px', md: '160px' } ,right:'0px', borderRadius: '4px', marginTop: '4px', 
                                        padding:{ xs: '4px', md: '10px' } , backgroundColor: Colors.white_80, zIndex: 1,
                                        boxShadow: '0px 4px 4px 0px #00000040', gap:{ xs: '0px ', md: '4px' } , display:'flex', 
                                        flexDirection:'column'}}>
                                        {!profileImage && <Box sx={{ padding: { xs: '0px 10px', md: '4px 10px' } ,
                                         cursor: 'pointer', borderRadius: '4px',color: Colors.black_90,
                                            '&:hover': { backgroundColor: Colors.primary_blue ,  color: Colors.white},
                                            }}>
                                            <TypographyNormal sx={{maxWidth: '98%', whiteSpace: 'nowrap',overflow: 'hidden',
                                                textOverflow: 'ellipsis',color: 'inherit'}} onClick={handleChangeImageClick}>
                                        Upload image
                                            </TypographyNormal>
                                        </Box> }


                                        {profileImage && <> <Box sx={{ padding:{ xs: '0px 10px', md: '4px 10px' } ,
                                         cursor: 'pointer', borderRadius: '4px', color: Colors.black_90,
                                            '&:hover': { backgroundColor: Colors.primary_blue,  color: Colors.white },
                                            }}>
                                            <TypographyNormal sx={{maxWidth: '98%', whiteSpace: 'nowrap',overflow: 'hidden',
                                                textOverflow: 'ellipsis',color: 'inherit'}} 
                                                onClick={handleChangeImageClick}
                                                >
                                        Change image
                                            </TypographyNormal>
                                        </Box>                                
                                        <Box sx={{ padding: { xs: '0px 10px', md: '4px 10px' } , cursor: 'pointer', 
                                         borderRadius: '4px',color: Colors.black_90,
                                            '&:hover': { backgroundColor: Colors.primary_blue ,  color: Colors.white},
                                            }} >
                                            <TypographyNormal sx={{maxWidth: '98%', whiteSpace: 'nowrap',overflow: 'hidden',
                                                textOverflow: 'ellipsis',color: 'inherit'}} onClick={handleRemoveImageClick}>
                                        Remove image
                                            </TypographyNormal>
                                        </Box></>}
                                        </Box>)}
                                        </Box>
                                    
                                    <Box sx={{ gap: {xs:  '8px', md: '24px' }, display: 'flex', flexDirection: 'column', paddingTop:{xs:  '8px', md: '16px' }, width:'100%'}}>
                                    
                                    <Box sx={{ gap:{xs:  '8px', md: '16px' }, display: 'flex', flexDirection:{xs:'column', md:'row' } , width:'100%'}}>
                                        <Box sx={{ gap: '8px', width:'100%',flexDirection: 'column',  }}>
                                            <TypographyNormal sx={{ fontSize: {xs:  '10px', md: '14px' }, color: Colors.black_80, }}>Name*</TypographyNormal>
                                            <TextfieldNormal placeholder="Enter your nick name"
                                                value={nickName}
                                                onChange={handleNickNameChange} error={!!nickNameError}></TextfieldNormal>
                                            {nickNameError && <TypographyError sx={{paddingTop: {xs:  '4px', md: '8px' }}}>{nickNameError}</TypographyError>}
                                        </Box>

                                        <Box sx={{ gap: '8px', width:'100%',flexDirection: 'column',  }}>
                                            <TypographyNormal sx={{ fontSize: {xs:  '10px', md: '14px' }, color: Colors.black_80, }}>Zip code*</TypographyNormal>
                                            <TextfieldNormal placeholder="Enter your zip code"
                                                value={zipCode} inputProps={{ maxLength: 6 }} 
                                                onChange={handleZipCodeChange} error={!!zipCodeError}></TextfieldNormal>
                                            {zipCodeError && <TypographyError sx={{paddingTop: {xs:  '4px', md: '8px' }}}>{zipCodeError}</TypographyError>}
                                        </Box>
                                    </Box>

                                    <Box sx={{ gap:{xs:  '8px', md: '16px' }, display: 'flex', flexDirection: {xs:'column', md:'row' }, width:'100%'}}>
                                    <Box sx={{ gap: '24px', display: 'flex', flexDirection: 'column', width:'100%'}}>
                                            <Box sx={{ gap:{xs:  '8px', md: '16px' }, display: 'flex', flexDirection: 'row', width:'100%'}}>
                                                <Box sx={{ gap: '8px', width:'30%',flexDirection: 'column',  }}>
                                                    <TypographyNormal sx={{ fontSize: {xs:  '10px', md: '14px' }, color: Colors.black_80, }}>Country code*</TypographyNormal>
                                                    <TextfieldNormal
                                                    sx={{
                                                        cursor: 'none', 
                                                        '& input': {
                                                        caretColor: 'transparent'
                                                        },                                                        
                                                        '& input:focus': {
                                                        outline: 'none',
                                                        }
                                                    }}
                                                    value={countryCode}
                                                    error={!!countryCodeError}
                                                    />


                                                </Box>
                                                <Box sx={{ gap: '8px', width:'70%',flexDirection: 'column',  }}>
                                                <TypographyNormal sx={{ fontSize: {xs:  '10px', md: '14px' }, color: Colors.black_80, }}>Phone number*</TypographyNormal>
                                                <TextfieldNormal sx={{
                                                        cursor: 'none', 
                                                        '& input': {
                                                        caretColor: 'transparent'
                                                        },                                                        
                                                        '& input:focus': {
                                                        outline: 'none',
                                                        }
                                                    }}
                                                    value={phoneNumber}                                                                    
                                                    error={!!phoneNumberError}>                                                       
                                                    </TextfieldNormal>
                                                </Box>
                                            </Box>
                                            {countryCodeError &&<TypographyError sx={{width:'100%',  marginTop:'-20px'}}>{countryCodeError}</TypographyError>}
                                            {phoneNumberError &&<TypographyError sx={{width:'100%', paddingLeft:'33%', marginTop:'-20px'}}>{phoneNumberError}</TypographyError>}
                                        </Box>

                                        <Box sx={{ gap: '8px', width:'100%',flexDirection: 'column',  }}>
                                            <TypographyNormal sx={{ fontSize: {xs:  '10px', md: '14px' }, color: Colors.black_80, }}>Password*</TypographyNormal>
                                            <TextfieldNormal placeholder="Enter your password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={password}
                                                onChange={handlePasswordChange} error={!!passwordError}
                                                sx={{
                                                    cursor: 'none', 
                                                    '& input': {
                                                    caretColor: 'transparent'
                                                    },                                                        
                                                    '& input:focus': {
                                                    outline: 'none',
                                                    }
                                                }}
                                                InputProps={{
                                                    endAdornment: password && (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={togglePasswordVisibility}
                                                                edge="end"
                                                                aria-label="toggle password visibility"
                                                                color="primary">
                                                                {showPassword ? (
                                                                  <img src={passwordvisible} alt="password Icon" />
                                                                ) : (
                                                                    <img src={passwordhide} alt="password Icon" />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}></TextfieldNormal>
                                                <Box sx={{ display: 'flex', flexDirection: 'row',paddingTop:'8px',
                                                        alignItems: 'center',justifyContent: 'space-between',}}>
                                                          {passwordError && <TypographyError sx={{paddingTop: {xs:  '4px', md: '8px' }}}>{passwordError}</TypographyError>}  
                                                        <TypographyNormal component="span"
                                                        sx={{color: Colors.blue_6,fontSize:{xs:  '10px', md: '14px' },marginLeft: 'auto',
                                                            cursor: 'pointer',}}
                                                         onClick={handleChangePasswordClick}>
                                                        Change password
                                                        </TypographyNormal>
                                               </Box>
                                            

                                        </Box>
                                    </Box>

                                      

                                     

                                        

                                        <Box sx={{ width:'100%',flexDirection: 'row',justifyContent:'right', display:'flex',
                                                    alignItems:'center',  gap:{xs:  '8px', md: '16px' }, paddingTop:{xs:'16px', md:'32px' } }}>                                            
                                            <Box sx={{ width:{xs:  '100%', md: '30%' } ,flexDirection: 'row',justifyContent:'center', display:'flex',
                                            alignItems:'center',  gap:{xs:  '8px', md: '16px' } }}>                                            
                                                    <ButtonWhite onClick={() => window.location.href = '/'}>Cancel</ButtonWhite>
                                                    <ButtonBlue onClick={handleSaveButtonClick} >Save</ButtonBlue>                                                     
                                            </Box>
                                        </Box>
                                        
                                            

                                    
                                    </Box>

                            </Box>
                    </Box>

            </Box>
      </Box>
                    
     <Footer></Footer>   
     {isChangePassword && (
               <UserChangePassword isChangePassword={isChangePassword} setIsChangePassword={setIsChangePassword} />
            )}        
          
</Grid>
)
}
export default UserEditProfile;