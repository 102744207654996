
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button, IconButton , InputAdornment, Breadcrumbs} from "@mui/material";
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading  from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Colors from '../Components/Colors';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import popupbg from '../Images/popup.png'
import whitelogo from '../Images/whitelogo.png'
import TextfieldNormal from '../Components/TextfieldNormal';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonBlue from '../Components/ButtonBlue';
import TypographyError from '../Components/TypographyError';
import UserTermPopUp from '../Pages/UserTermPopUp';
import axios from "axios";
import { API_URL } from '../Components/Constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import ButtonWhite from '../Components/ButtonWhite';
import uploadCamera from '../Images/uploadCamera.png'
import { IMG_URL } from '../Components/Constants';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';




const colors = ['#503259', '#4C50AE', '#FF7D6A', '#B07CC6', '#20C925'];

function stringToColor(string) {
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  const colorIndex = Math.abs(hash) % colors.length;
  return colors[colorIndex];
}

function stringAvatar(name = '') {   
    const initial = name.charAt(0).toUpperCase();
    
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: initial || '', 
    };
  }
  


function SettingsSidebar(props){

    const {currentPage} = props

    
    const handleDeleteAccountClick = () => {
        window.location.href = "/sp-delete-account"      
    }

    const handleDisableAccountClick = () => {
        window.location.href = "/sp-disable-account"      
    }
    
return(
    
<Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>   

       <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', 
       justifyContent: 'center', height: '100%', alignItems:'center',  }}>

            <Box sx={{display: 'flex', flexDirection: 'column', width: '100%',paddingBottom:'2%', 
            justifyContent: 'center', height: '96%', alignItems:'center', gap:{ xs: '16px', md: '24px' }}}>

                    <Box sx={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'left', 
                          width:'100%',height:'10%', }}>
                         <Breadcrumbs 
                            separator={<span style={{ paddingLeft: '8px', fontSize: '32px', justifyContent:'center', alignItems: 'center', color: Colors.default }}>›</span>} 
                            aria-label="breadcrumb" >
                            <TypographySubHeading sx={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '20px', justifyContent: 'center' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', 
                                    height: '100%' }}  onClick={() => window.location.href = '/sp-landing'} >
                                <ArrowBackIcon  sx={{ color: Colors.black_90, fontSize: '18px', '&:hover': { fontSize: '22px' } }} />
                                </Box>
                                Settings
                            </TypographySubHeading> 
                            </Breadcrumbs>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection:{ md: 'column ', xs: 'row' } , width: '100%', 
                justifyContent: 'left', height: '100%', alignItems:'left',gap:{ md: '24px', xs: '12px' }}}>
                    {/* <Box onClick={handleDisableAccountClick} sx={{display:'flex',flexDirection:'row', justifyContent:'center',alignItems:'center', padding:{ md: '8px 16px', xs: '6px 8px' },  
                          width:'calc(100% - 32px)',height:'100%',gap:'12px', border:'1px solid',background: currentPage === 'Disable account'? '#ECF4FB' : Colors.white_80,
                          borderColor: currentPage === 'Disable account'? Colors.primary_blue : Colors.grey_30, borderRadius:'4px', cursor:'pointer'}}>                           
                            <TypographyNormal sx={{fontSize: { md: '18px', xs: '12px' }, textAlign: { md: 'left', xs: 'center' }, width:'100%', 
                            color: currentPage === 'Disable account'? Colors.primary_blue : Colors.black_80 }}>
                            Disable account</TypographyNormal>
                    </Box> */}
                    {/* <Box onClick={handleDeleteAccountClick} sx={{display:'flex',flexDirection:'row', justifyContent:'center',alignItems:{ md: 'left', xs: 'center' }, padding:{ md: '8px 16px', xs: '6px 8px' },    
                          width:'calc(100% - 32px)',height:'100%',gap:'12px', border:'1px solid',background: currentPage === 'Delete account'? '#ECF4FB' : Colors.white_80,  
                          borderColor: currentPage === 'Delete account'? Colors.primary_blue : Colors.grey_30, borderRadius:'4px', cursor:'pointer'}}>                            
                            <TypographyNormal sx={{fontSize: { md: '18px', xs: '12px' },textAlign: { md: 'left', xs: 'center' }, width:'100%',
                            color: currentPage === 'Delete account'? Colors.primary_blue : Colors.black_80  }}>Delete account</TypographyNormal>
                    </Box> */}
                     <TypographySubHeading sx={{fontSize: '18px', fontWeight:600, }}>Delete account</TypographySubHeading>
                    
                    
                    </Box>               
                  

            </Box>
      </Box>
                    
          
          
</Grid>
)
}
export default SettingsSidebar;