import React, { useState, useRef } from 'react';
import { Select, MenuItem } from '@mui/material';
import Colors from './Colors';

const SelectComponent = ({ value, disabled, error, options, onChange, multiple, ...rest }) => {
  const [menuOpen, setMenuOpen] = useState(false); // State to manage menu open/close

  const handleOpen = () => {
    document.body.style.overflow = 'hidden'; // Disable body scroll
    setMenuOpen(true); // Set menu open state
  };

  const handleClose = () => {
    document.body.style.overflow = 'auto'; // Re-enable body scroll
    setMenuOpen(false); // Set menu close state
  };
  return (
    <Select
      value={value}
      error={error}
      placeholder={rest.placeholder}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      displayEmpty={!multiple}
      onOpen={handleOpen} // Attach handleOpen when menu opens
      onClose={handleClose}
      sx={{
        color: disabled ? Colors.black_70 : Colors.black_70,
        width: "100%", marginTop:'2px',
        height: {xs:  '40px', md: '40px' },
        borderRadius: "4px",
        fontFamily: "Roboto",
        fontSize: { md:'14px', sm: '12px', xs: '12px' },
        fontWeight: "400",
        borderColor: error ? '#FF4105 !important' : '#CFC9C9',
        ...rest, // Allow customization through props
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: error ? '#FF4105 !important' : "#313131",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: error ? '#FF4105 !important' : "#313131 ",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: error ? '#FF4105 !important' : '#CFC9C9',
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: error ? '#FF4105 !important' : '#CFC9C9',
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: error ? '#FF4105 !important' : '#313131',
        },
      
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            color: Colors.black_70,
            marginTop: "4px",
            maxHeight: "158px",
            borderRadius: "4px",
            paddingLeft: '8px', 
            '&::-webkit-scrollbar': {
            width: '12px', height: '8px', borderRadius: '16px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: Colors.black_60, borderRadius: '16px', border: '2px solid white',
          },
          '&::-webkit-scrollbar-track': {
            borderRadius: '16px', backgroundColor: 'none', 
          },
              },
            },
      }}
      renderValue={(selected) => {
        if (!selected || (Array.isArray(selected) && selected.length === 0)) {
          return <span style={{ color: Colors.black_60 }}>{rest.placeholder}</span>; // Placeholder style
        }

        if (multiple) {
          return selected.join(', ');
        }

        const selectedItem = options.find(option => option.value === selected);
        return selectedItem ? selectedItem.label : '';
      }}
    >
      {options.map((option) => (
        <MenuItem 
          key={option.value} 
          value={option.value} 
          sx={{
            fontFamily: "Roboto", borderRadius:'4px',width:'100%',
            fontSize: { md:'14px', sm: '14px', xs: '14px' },lineHeight:{ md:'18px', sm: '14px', xs: '14px' },
            fontWeight: "400",
            margin: { md:'0px', sm: '0px', xs: '0px' },          
            color: Colors.black_70,
            "&:hover": {
              backgroundColor: "#ECF4FB",
            },
            "&.Mui-selected": {
              backgroundColor: "#415FE2",
              color: "#ffffff",
            },            
            "&.Mui-selected:hover": {
              backgroundColor: "#415FE2",
              color: "#ffffff",
            },
            "&.MuiSelect-root": {
              backgroundColor: "#ECF4FB",
              color: "#ffffff",
            }, 
          }}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectComponent;
